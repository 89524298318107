import { CloseOutlined } from '@ant-design/icons';
import { Box, Hidden } from '@mui/material';
import { Popup } from 'antd-mobile';
import { CopyRight } from 'widgets';
import { Logo, PopupBg } from 'shared/icons';
import { menuItems } from '../../../consts';
import {
  IPopup,
} from '../../../interfaces';
import { renderMenuItems } from '../helpers';
import {
  closeIcon,
  copyRight,
  logo,
  popup,
  popupBg,
} from '../styles';

export const DesktopPopup = (props: IPopup) => {
  const {
    isOpened,
    openHandler,
  } = props;

  const closePopupHandler = () => {
    openHandler(false);
  };

  return (
    <Hidden lgUp smDown>
      <Box
        display='flex'
        flex='1 1 auto'
      >
      </Box>

      <Popup
        visible={isOpened}
        position='left'
        bodyStyle={popup}
        onMaskClick={closePopupHandler}
        onClose={closePopupHandler}
      >
        <div style={popupBg}>
          {PopupBg}
        </div>

        <CloseOutlined
          style={closeIcon}
          onClick={closePopupHandler}
        />

        <Box
          display='flex'
          flexDirection='column'
          justifyContent='space-between'
          gap={10}
        >
          <Box
            display='flex'
            flexDirection='column'
            gap={2}
          >
            {renderMenuItems({
              menuItems,
              closePopupHandler,
            })}
          </Box>

          <Box
            display='flex'
            alignItems='flex-end'
            justifyContent='space-between'
          >
            <Box
              display='flex'
              flexDirection='column'
              gap={1.2}
            >
              <div style={logo}>
                {Logo}
              </div>

              <CopyRight styles={copyRight} />
            </Box>
          </Box>
        </Box>
      </Popup>
    </Hidden>
  );
};
