import { Box } from '@mui/material';
import { Form, Modal } from 'antd';
import { BaseOptionType } from 'antd/es/select';
import dayjs from 'dayjs';
import { ExchangeAccount } from 'entities/exchange/model/types/exchange-account';
import { getIsDemoMode } from 'entities/user/model/selectors/get-is-demo-mode/get-is-demo-mode';
import { convertExchange, getExchangeType } from 'pages/trading-bots/configurator-v2/helpers';
import { Bot } from 'pages/trading-bots/marketplace/types/bots.types';
import { ActiveDeal, fetchPublicBotDeals } from 'pages/trading-bots/my-bot/helpers/fetch-all-deals';
import { getReturnPercent } from 'pages/trading-bots/my-bot/helpers/get-return-percent';
import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { TradingStatistic } from 'widgets';
import { getExchanges, getExchangesIds } from 'widgets/exchanges/api/get-exchanges';
import { ALL_EXCHANGES, ExchangeName } from 'shared/consts/available-exchanges';
import { exchangeIcons, IExchangeIcons } from 'shared/consts/exchange-icons';
import { IMarketIcons, marketIcons } from 'shared/consts/market-icon';
import { getDemoAccount, roundValue } from 'shared/helpers';
import { currencyFormatter } from 'shared/helpers/currency-formatter';
import { getCapitalizedExchangeTitle } from 'shared/helpers/get-capitalized-exchange-title';
import { getSkyrexUuid } from 'shared/helpers/storage-helper';
import { useSearch } from 'shared/hooks';
import { useMultiExchangeFormatter } from 'shared/hooks/use-multi-exchange-formatter';
import { PlusWhite, SecondaryInfo } from 'shared/icons';
import {
  LabelInput,
  Loader,
  MainButton,
  RangePicker,
  Search,
  SingleSelect,
  Table,
  Title,
  Tooltip,
} from 'shared/ui';
import { Exchange } from 'shared/ui/selects/single-select/views';
import { getBotStatistic } from '../api/bot-statistic';
import { createNewCopyBot } from '../api/create-copy-bot';
import { createCopyBotSubscription } from '../api/create-copy-bot-subscription';
import { getExchangeAccount } from '../api/get-exchange-account';
import { botNameValidator, connectedAccoutValidator, tradingBalanceValidator } from '../consts';
import { columnsCopyBot } from '../consts/columns-copy-bot';
import { action, button, buttonWrapper, formItem, inner, modal, modalChildren, title, titleInner, titleWrapper, tooltip, topWrapper, wrapper } from '../styles';

interface CopyBotProps {
  isOpen: boolean;
  closeModal: () => void;
  isSignalSource?: boolean;
  bot: Bot;
  bots: Bot[];
  fee?: string;
  connectExchange?: Dispatch<SetStateAction<boolean>>;
}

export const CopyBot = (props: CopyBotProps) => {
  const {
    isOpen,
    closeModal,
    isSignalSource,
    bot,
    bots,
    fee,
    connectExchange,
  } = props;

  const skyrexUserUuid = getSkyrexUuid();

  const isDemoMode = useSelector(getIsDemoMode);

  const [form] = Form.useForm();

  const navigate = useNavigate();

  const [botName, setBotName] = useState<string>('');
  const [botTrades, setBotTrades] = useState<ActiveDeal[]>([]);
  const [exchangeAccs, setExchangeAccs] = useState<any>([]);
  const [tradingBalance, setTradingBalance] = useState(bot.minInvestment);
  const [selectedExchange, setSelectedExchange] = useState<string>('');
  const [selectedPeriodOption, setSelectedPeriodOption] = useState<string>('summary');
  const [chartData, setChartData] = useState<[string, number][]>([]);
  const [dates, setDates] = useState<[dayjs.Dayjs | null, dayjs.Dayjs | null] | null>([null, null]);
  const [tradingHistoryDates, setTradingHistoryDates] = useState<[dayjs.Dayjs | null, dayjs.Dayjs | null] | null>([dayjs().subtract(30, 'day'), dayjs()]);
  const [isTradingHistoryLoading, setIsTradingHistoryLoading] = useState<boolean>(true);
  const [statistic, setStatistic] = useState<any>(null);
  const [exchangeCode, setExchangeCode] = useState<string>('');
  const [balance, setBalance] = useState<number>(0);

  const {
    search,
    changeSearchValue,
    filterItemsBySearch,
  } = useSearch([
    'baseSymbol',
    'quoteSymbol',
  ]);

  const handleSetBotName = (value: string) => {
    if (value.length > 20) {
      setBotName(value.slice(0, 20));
      return;
    }

    setBotName(value);
    form.setFieldValue('botName', value);
  };

  const handleSetTradingBalance = (value: string) => {
    const numericValue = value.replace(/[^\d.]/g, '').replace(/(\..*)\./g, '$1');
    if (/^\d/.test(numericValue)) {
      setTradingBalance(numericValue);
      form.setFieldValue('tradingBalance', numericValue);
    } else if (numericValue === '') {
      setTradingBalance('');
      form.setFieldValue('tradingBalance', '');
    }
  };

  const handleSelectExchange = async (selected: string, option: any) => {
    setSelectedExchange(selected);

    const exchangeCode = option.label.split('_').at(1);
    setExchangeCode(exchangeCode);
    setDates(null);

    form.setFieldsValue({
      ...form.getFieldsValue(),
      selectedExchange: selected,
    });
  };

  const getExchangeAccounts = async () => {
    const exchangeDataResponse = await getExchangesIds(skyrexUserUuid ?? '');

    const {
      accounts,
    } = exchangeDataResponse.data;

    const exchangeAccountsMap = getDemoAccount(accounts, isDemoMode).filter((account: ExchangeAccount) => bots.some((bot) => account.exchangeCode === bot.exchangeCode));
    const accountBalances = await getExchanges(exchangeAccountsMap.map((account: any) => account.exchangeAccountUuid));

    const resultAccounts = exchangeAccountsMap.map((account: any) => {
      const totalUsdt = +(accountBalances?.data?.accounts?.find((accountBalance: any) => accountBalance?.exchangeAccountUuid === account.exchangeAccountUuid)?.totalUsdt || 0);
      account.totalUsdt = totalUsdt;

      return account;
    });

    setExchangeAccs(resultAccounts);
  };

  const {
    formatPriceForExchange,
    formatTotalForExchange,
    formatReturnValueForBots,
    loadAllSymbols,
  } = useMultiExchangeFormatter(ALL_EXCHANGES);

  useEffect(() => {
    loadAllSymbols();
  }, []);

  useEffect(() => {
    getExchangeAccounts();
  }, [isDemoMode]);

  const handleSetDates = (action: Dispatch<SetStateAction<[dayjs.Dayjs | null, dayjs.Dayjs | null] | null>>) => {
    return (date: [dayjs.Dayjs | null, dayjs.Dayjs | null] | null) => {
      action(date);
    };
  };

  const getBotDealsData = async (botUuid: string) => {
    const botDeals = await fetchPublicBotDeals(botUuid, tradingHistoryDates!);
    setBotTrades(botDeals);
    setIsTradingHistoryLoading(false);
  };

  const createCopyBot = async () => {
    const bot = bots.find((bot) => bot.exchangeCode === exchangeCode)!;

    try {
      const responseFromCreateCopyBot = await createNewCopyBot(
        {
          tradingAmount: tradingBalance,
          botUuid: bot.botUuid,
          exchangeCode: bot.exchangeCode,
          exchangeAccountUuid: selectedExchange,
          botName: botName,
        },
      );

      if (!responseFromCreateCopyBot.success) {
        toast.error(responseFromCreateCopyBot.data.message);
        return;
      }

      const responseFromCreateBotSuccessSubscription = await createCopyBotSubscription({
        sourceUuid: responseFromCreateCopyBot.data.fields.sourceUuid,
        botUuid: responseFromCreateCopyBot.data.fields.botUuid,
      });

      if (!responseFromCreateBotSuccessSubscription.success) {
        return;
      }

      const createdCopyBotUuid = responseFromCreateCopyBot.data.fields.botUuid;
      navigate(`/trading-bots/my-bots/bot/${createdCopyBotUuid}`);
      return;
    } catch (error) {
      console.log(error);

      if (isDemoMode) {
        toast.error('Bot has no demo mode');
      }
    }
  };

  const handleCreateCopyBot = () => {
    form
      .validateFields()
      .then(() => {
        createCopyBot();
      })
      .catch((errorInfo) => {
        console.error('Validation Failed:', errorInfo);
      });
  };

  useEffect(() => {
    const currentExchangeCodeBot = bots.find((bot) => bot.exchangeCode === exchangeCode)!;
    getBotDealsData(currentExchangeCodeBot?.botUuid || bot.botUuid);
  }, [exchangeCode, tradingHistoryDates]);

  const handleSetPeriodOption = (value: string) => {
    setSelectedPeriodOption(value);
  };

  useEffect(() => {
    let chartData: [string, number][] = [];
    const botStatistic = statistic || bot.statistics;

    switch (selectedPeriodOption) {
    case 'day':
      chartData = botStatistic.dayChartData.map((element: any) => [
        element.date,
        (element.return / botStatistic.totalPnl) * botStatistic.roi,
      ]);
      break;

    case 'pair':
      chartData = botStatistic.pairChartData.map((element: any) => [
        element.pair,
        (element.return / botStatistic.totalPnl) * botStatistic.roi,
      ]);
      break;

    case 'summary':
    default:
      chartData = botStatistic.sumChartData.map((element: any) => [
        element.date,
        (element.return / botStatistic.totalPnl) * botStatistic.roi,
      ]);
      break;
    }

    setChartData(chartData);
  }, [selectedPeriodOption, dates, statistic]);

  const refetchBotStatistic = async () => {
    const existBot = bots.find((bot) => bot.exchangeCode === exchangeCode)! || bot;
    const statistic = await getBotStatistic(existBot?.botUuid, dates!);
    setStatistic(statistic);
  };

  useEffect(() => {
    refetchBotStatistic();
  }, [dates, exchangeCode]);

  const getAvailableAmount = async () => {
    const accounts = await getExchangeAccount(selectedExchange);
    if (!accounts?.length) {
      return;
    }

    const [account] = accounts;
    const balance = account.balances.find((balance: any) => balance.symbol === bot.quote)?.free || 0;
    setBalance(+balance);
  };

  useEffect(() => {
    if (!selectedExchange) {
      return;
    }

    getAvailableAmount();
  }, [selectedExchange]);

  const exchangeAccountClickHandler = () => {
    if (!exchangeAccs?.length && !isDemoMode) {
      connectExchange?.(true);
      closeModal();

      return;
    }

    if (isDemoMode) {
      toast.error('Bot has no demo mode');
    }
  };

  const statistics = (statistic || bot.statistics);
  const resultExchangeCode = (exchangeCode || bot?.exchangeCode) ?? 'binance';

  const TradingHistoryTable = useMemo(() => (
    <Table
      title='Trading history'
      headerBreakPoint='md'
      columns={columnsCopyBot}
      items={botTrades ? botTrades.filter(filterItemsBySearch).map((trade) => ({
        pair: {
          first: trade.baseSymbol, second: trade.quoteSymbol,
        },
        closeDate: trade.finishedDate,
        create: trade.createdDate,
        status: trade.status,
        total: formatTotalForExchange(
          resultExchangeCode as ExchangeName,
          trade.baseSymbol,
          trade.quoteSymbol,
          trade.executedQuoteFirst,
        ),
        entryPrice: formatPriceForExchange(
          resultExchangeCode as ExchangeName,
          trade.baseSymbol,
          trade.quoteSymbol,
          trade.executedQuoteFirst,
          trade.executedBaseFirst,
        ),
        closePrice: trade.status === 'completed'
          ? formatPriceForExchange(
            resultExchangeCode as ExchangeName,
            trade.baseSymbol,
            trade.quoteSymbol,
            trade.executedQuoteSecond,
            trade.executedBaseSecond,
          )
          : '-',
        return: formatReturnValueForBots(
          resultExchangeCode as ExchangeName,
          trade.baseSymbol,
          trade.quoteSymbol,
          trade as any,
        ),
        returnPercent: getReturnPercent(trade),
      })) : []}
      itemsCount={5}
      action={(
        <Box sx={action}>
          <Search
            value={search}
            onChange={changeSearchValue}
            placeholder='Search by pair'
          />

          <RangePicker
            value={tradingHistoryDates!}
            style={{
              padding: '7px 12px',
            }}
            handleSetNewDate={handleSetDates(setTradingHistoryDates)}
          />
        </Box>
      )}
    />
  ), [botTrades, search, tradingHistoryDates]);

  const exchangeView = useCallback(() => {
    if (!selectedExchange) {
      return null;
    }

    const exchange = convertExchange(exchangeAccs.find((exchangeAcc: any) => exchangeAcc.exchangeAccountUuid === selectedExchange));
    if (!exchange) {
      return null;
    }

    return (
      <Exchange
        title={exchange.name}
        code={exchange.code}
        label={exchange.type}
        balance={exchange.totalUsdt}
      />
    );
  }, [selectedExchange, exchangeAccs]);

  const exchangeOptionView = useCallback((option: BaseOptionType) => {
    const [name, code, totalUsdt] = (option.label as string || '').split('_');
    return (
      <Exchange
        title={name}
        code={code}
        label={getExchangeType(code || '')}
        balance={+totalUsdt || 0}
      />
    );
  }, []);

  const exchangeOptions = useMemo(() => {
    return exchangeAccs?.map((account: ExchangeAccount) => ({
      label: `${account.accountName}_${account.exchangeCode}_${account.totalUsdt || 0}`,
      value: account.exchangeAccountUuid,
    }));
  }, [exchangeAccs]);

  return (
    <Modal
      open={isOpen}
      footer={null}
      style={modal}
      styles={modalChildren}
      closable={true}
      destroyOnClose={true}
      onCancel={closeModal}
      onOk={closeModal}
      width='100%'
      centered={true}
    >
      <Box sx={wrapper}>
        {!isSignalSource && (
          <Form form={form} layout='vertical'>
            <Box sx={topWrapper}>
              <Form.Item
                name='selectedExchange'
                style={formItem}
                rules={connectedAccoutValidator}
              >
                <SingleSelect
                  label='Choose your account'
                  options={exchangeOptions}
                  onClick={exchangeAccountClickHandler}
                  select={{
                    value: selectedExchange || undefined,
                    placeholder: 'Choose from connected accounts',
                    onChange: handleSelectExchange,
                  }}
                  labelRender={exchangeView}
                  optionRender={exchangeOptionView}
                />
              </Form.Item>

              <Form.Item
                name='botName'
                style={formItem}
                rules={botNameValidator}
              >
                <LabelInput
                  label='Name your bot'
                  value={botName}
                  placeholder='My perfect bot'
                  onChange={handleSetBotName}
                />
              </Form.Item>

              <Form.Item
                name='tradingBalance'
                style={formItem}
                rules={tradingBalanceValidator(+bot.minInvestment)}
              >
                <LabelInput
                  label={`Trading amount (available: ${currencyFormatter(balance, 4)} ${bot.quote})`}
                  value={tradingBalance}
                  placeholder={`Set amount (minimum: ${bot.minInvestment ?? 0} ${bot.quote})`}
                  onChange={handleSetTradingBalance}
                  suffix={(
                    <Tooltip title='This amount will be managed by bot. Make sure you have that amount on selected exchange account'>
                      {SecondaryInfo}
                    </Tooltip>
                  )}
                />
              </Form.Item>

              <Box sx={buttonWrapper}>
                <MainButton
                  type='primary'
                  icon={PlusWhite}
                  iconPosition='start'
                  styles={button}
                  onClick={handleCreateCopyBot}
                >
                  Copy Bot
                </MainButton>
              </Box>
            </Box>
          </Form>
        )}

        <Box sx={inner(!!isSignalSource)}>
          <Box sx={titleWrapper}>
            <Title styles={title}>
              {bot?.name}
            </Title>

            <Box sx={titleInner}>
              <Tooltip title={getCapitalizedExchangeTitle(exchangeCode || bot.exchangeCode)}>
                <Box>
                  {exchangeIcons[(exchangeCode || bot.exchangeCode) as keyof IExchangeIcons]}
                </Box>
              </Tooltip>

              <Tooltip title={bot.quote}>
                <Box>
                  {marketIcons[bot.quote.toLowerCase() as keyof IMarketIcons]}
                </Box>
              </Tooltip>
            </Box>
          </Box>

          <TradingStatistic
            selectedQuote={bot.quote}
            disableTitle={true}
            customTitle={(
              <Title styles={{
                ...title,
                fontSize: 18,
              }}>
                Statistic
              </Title>
            )}
            listItems={[{
              title: 'ROI',
              tooltip: (
                <div style={tooltip}>
                  Profit taken from closed deals (resets at midnight UTC+0 each day).
                  Please note: this statistic is affected by any filters you have active
                </div>
              ),
              value: roundValue(statistics.roi, 2),
              type: 'roi',
            }, {
              title: 'Max drawdown',
              tooltip: (
                <div style={tooltip}>
                  Currently open deals. Please note: this statistic is affected by any filters you have active
                </div>
              ),
              value: roundValue(statistics.maxDrawdown, 2),
              type: 'max-drawdown',
            }, {
              title: 'Total trades',
              tooltip: (
                <div style={tooltip}>
                  Total amount of funds locked in active deals or on limit orders in the exchange orderbooks for active deals.
                  Please note: this statistic is affected by any filters you have active
                </div>
              ),
              value: statistics.totalTrades,
            }, {
              title: 'Profit sharing fee',
              tooltip: (
                <div style={tooltip}>
                  Total lifetime profit generated from all closed deals.
                  Please note: this statistic is affected by any filters you have active
                </div>
              ),
              value: fee || 0,
            }, {
              title: 'Min. trading amount',
              tooltip: (
                <div style={tooltip}>
                  Current profit or loss total for any active deals your have open.
                  Please note, this statistic is affected by any filters you have active
                </div>
              ),
              value: bot.minInvestment ?? 0,
            }]}
            chartData={chartData}
            isCopyBot={true}
            selectedSegment={selectedPeriodOption}
            setSelectedSegment={handleSetPeriodOption}
            chartAction={(
              <Box sx={action}>
                <RangePicker
                  value={dates!}
                  handleSetNewDate={handleSetDates(setDates)}
                />
              </Box>
            )}
          />

          <Box position='relative'>
            {isTradingHistoryLoading && (
              <Loader isContentOverflow={true} />
            )}

            {TradingHistoryTable}
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};
