import { SxProps, Theme } from '@mui/material';

export const INNER = (gap?: string) => {
  const styles: SxProps<Theme> = {
    flexDirection: 'row',
    alignItems: 'center',
    gap: gap || '22px',
  };

  return styles;
};
