import { BaseOptionType } from 'antd/es/select';
import { useCallback } from 'react';
import { Label } from 'widgets/label';
import { SingleSelect } from 'shared/ui';
import { Market as MarketView } from 'shared/ui/selects/single-select/views';

interface MarketSelectProps {
  marketOptions: any[];
  selectedMarket: any;
  isLoading: boolean;
  onSelect: (value: string) => void;
}

export const MarketSelect = ({
  marketOptions,
  selectedMarket,
  isLoading,
  onSelect,
}: MarketSelectProps) => {
  
  const marketView = useCallback(() => {

    if (!selectedMarket?.value) {
      return null;
    }
    
    return (
      <MarketView
        title={selectedMarket.label}
        code={selectedMarket.label}
        value={`${selectedMarket.formattedValue} ${selectedMarket.label}`}
      />
    );
  }, [selectedMarket?.value, selectedMarket?.label, selectedMarket?.formattedValue]);

  const marketOptionView = (option: BaseOptionType) => {
    
    if (!option.value) {
      return null;
    }
    
    const {
      label, formattedValue, 
    } = option.data;

    return (
      <MarketView
        title={label}
        code={label}
        value={`${formattedValue} ${label}`}
      />
    );
  };
  
  return (
    <SingleSelect
      label={(
        <Label
          title='Market'
        />
      )}
      options={marketOptions}
      select={{
        value: (isLoading || !selectedMarket?.value) ? '' : selectedMarket,
        placeholder: 'Select market',
        onChange: onSelect,
      }}
      labelRender={marketView}
      optionRender={marketOptionView}
      size='large'
      loading={isLoading || !selectedMarket?.value}
    />
  );
};
