import { SxProps, Theme } from '@mui/material';

export const WRAPPER: SxProps<Theme> = {
  maxWidth: {
    xs: '100%',
    md: 747,
  },
  width: '100%',
  position: 'relative',
  '&>video': {
    width: '100%',
    height: '100%',
    aspectRatio: '16/9',
  },
};
