import { Stack } from '@mui/material';
import { Segmented } from 'antd';
import { SecondaryInfo } from 'shared/icons';
import { Switch, Title, Tooltip } from 'shared/ui';
import { IBlockTitleProps } from '../interfaces';
import { INNER, SEGMENT_WRAPPER, TITLE, WRAPPER } from '../styles';

export const BlockTitle = (props: IBlockTitleProps) => {
  const {
    title,
    toggle,
    segment,
  } = props;

  const getSegmnet = () => {
    if (!segment) {
      return null;
    }

    return (
      <Stack sx={SEGMENT_WRAPPER(segment.maxWidth)}>
        <Segmented
          value={segment.selected}
          options={segment.options}
          onChange={segment.change}
          block={true}
          style={{
            width: '100%',
          }}
        />

        {segment.tooltip && (
          <Tooltip title={segment.tooltip}>
            {SecondaryInfo}
          </Tooltip>
        )}
      </Stack>
    );
  };

  return (
    <Stack sx={WRAPPER}>
      <Stack sx={INNER(toggle?.gap)}>
        <Title styles={TITLE}>
          {title}
        </Title>

        {toggle && (
          <Switch
            value={toggle.toggled}
            onChange={toggle.change}
          />
        )}
      </Stack>

      {getSegmnet()}
    </Stack>
  );
};
