import { Stack } from '@mui/material';
import { MainButton, Text, Title } from 'shared/ui';
import { ListItem } from '../components';
import { IIncomeProps } from '../interfaces';
import { BUTTON, INFO, INFO_TITLE, INNER, LIST, TITLE, WRAPPER } from '../styles';

export const Income = (props: IIncomeProps) => {
  return (
    <Stack sx={WRAPPER}>
      <Title styles={TITLE}>
        Income
      </Title>

      <Stack sx={INNER}>
        <Stack sx={LIST}>
          <ListItem
            title='Total'
            text={`${props.total.toFixed(2).toString()} USDT`}
          />

          <ListItem
            title='Withdrawn'
            text={`${props.withDrawn.toFixed(2)} USDT`}
          />

          <ListItem
            title='Available'
            text={`${props.available.toFixed(2)} USDT`}
            primary={!!props.available}
          />
        </Stack>

        <Stack sx={INFO}>
          <MainButton
            type='primary'
            size='large'
            styles={BUTTON}
            onClick={props.openModal}
          >
            Withdraw
          </MainButton>

          <Text styles={INFO_TITLE}>
            Request will be processed in 24 hours
          </Text>
        </Stack>
      </Stack>
    </Stack>
  );
};
