import { SxProps, Theme } from '@mui/material';

export const titleWrapper: SxProps<Theme> = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: {
    xs: 'space-between',
    md: 'initial',
  },
  gap: '14px',
  flexDirection: {
    xs: 'column',
    sm: 'row',
  },
  '&>h1':{
    textAlign: {
      xs: 'center',
      sm: 'initial',
    },
  },
};
