import { Box, Stack } from '@mui/material';
import dayjs from 'dayjs';
import { combineBots } from 'pages/trading-bots/marketplace/helpers/combine-bots';
import { Bot } from 'pages/trading-bots/marketplace/types/bots.types';
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { exportToCsv } from 'shared/helpers';
import { getUserEmail } from 'shared/helpers/storage-helper';
import { ExportGreen, PlusGreen } from 'shared/icons';
import { EmptySubscription, Loader, MainButton, PublicBotMarket, PublicBotParameters, RangePicker, SingleSelect, Table, Text, Title } from 'shared/ui';
import { Card as MarketplaceCard } from '../../marketplace/components';
import { getSubscriptions, getTotalSales, getUserPublicBots, getWithDrawals } from '../api';
import { Card, WithDraw } from '../components';
import { columns } from '../consts';
import { IProviderPayout } from '../interfaces';
import { button, EMPTY_BOTS_TITLE, inner, LIST, tableTitle, tableWrapper, title, titleWrapper, wrapper } from '../styles';

export const PublicBotSales = () => {
  const [bots, setBots] = useState<Bot[]>([]);
  const [combinedBots, setCombinedBots] = useState<{[key: string]: Bot[];} | null>(null);
  const [providerPayouts, setProviderPayouts] = useState<IProviderPayout[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isBotsLoading, setIsBotsLoading] = useState<boolean>(true);
  const [isSalesLoading, setIsSalesLoadingLoading] = useState<boolean>(true);
  const [isSubscriptionLoading, setIsSubscriptionLoading] = useState<boolean>(true);
  const [dates, setDates] = useState<[dayjs.Dayjs | null, dayjs.Dayjs | null] | null>([null, null]);
  const [withDrawalDates, setWithDrawalDates] = useState<[dayjs.Dayjs | null, dayjs.Dayjs | null] | null>([null, null]);
  const [sales, setSales] = useState<any>({
    total: 0,
    withDrawal: 0,
  });
  const [subscriptions, setSubscriptions] = useState<any[]>([]);
  const [subscription, setSubscription] = useState<any>(null);

  const [chooseMarketModal, setChooseMarketModal] = useState<boolean>(false);
  const [markets, setMarkets] = useState<any[]>([]);
  const [market, setMarket] = useState<string>('');
  const [botUuids, setBotUuids] = useState<string[]>([]);
  const [botItemName, setBotItemName] = useState<string>('');
  const [isEmptyBotsModalOpened, setIsEmptyBotsModalOpened] = useState<boolean>(false);

  const exportHandler = () => {
    exportToCsv(providerPayouts, `${getUserEmail()}-sales`);
  };

  const datesHandler = (action: Dispatch<SetStateAction<[dayjs.Dayjs | null, dayjs.Dayjs | null] | null>>) => {
    return (date: [dayjs.Dayjs | null, dayjs.Dayjs | null] | null) => {
      action(date);
    };
  };

  const onSelectChange = async (value: any) => {
    const requireSubscription = subscriptions.find((subscription) => subscription.bot === value);
    setSubscription(requireSubscription);
  };

  const getTotalSalesHandler = async () => {
    const response = await getTotalSales(withDrawalDates);
    if (!response.success) {
      return;
    }

    setSales({
      total: response.data.totalSales,
      withDrawal: response.data.availableForWithdrawal,
    });
    setIsSalesLoadingLoading(false);
  };

  const getDrawalsHandler = async () => {
    setIsLoading(true);
    const response = await getWithDrawals(dates);
    if (!response.success)  {
      return;
    }

    setProviderPayouts(response.data?.providerPayouts || []);
    setIsLoading(false);
  };

  const getSubscriptionsHandler = async () => {
    const response = await getSubscriptions(withDrawalDates);
    const subscriptions = response?.data?.stats;
    if (!subscriptions?.length)  {
      setIsSubscriptionLoading(false);
      return;
    }


    setSubscriptions(subscriptions);
    setSubscription(subscriptions[0]);
    setIsSubscriptionLoading(false);
  };

  const getBots = async () => {
    try {
      setIsBotsLoading(true);

      const responseFromAllBots = await getUserPublicBots();
      const uniqueMarkets = (responseFromAllBots as any[]).reduce((acc, current) => {
        if (!acc.includes(current.quote)) {
          acc.push(current.quote);
        }

        return acc;
      },[]);

      setMarkets(uniqueMarkets);
      setBots(responseFromAllBots);

      const combinedBots = combineBots(responseFromAllBots.filter((bot) => bot.allowSharing === 'TRUE'));
      setCombinedBots(combinedBots);

      setIsBotsLoading(false);
    } catch (error) {
      console.log('error', error);
    }
  };
  
  const fetchData = async () => {
    await getBots();
  };

  const fetchDrawalDatesData = async () => {
    try {
      setIsSalesLoadingLoading(true);
      setIsSubscriptionLoading(true);

      await getTotalSalesHandler();
      await getSubscriptionsHandler();      
    } catch (error) {
      console.log('error', error);
    }
  };
  
  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    fetchDrawalDatesData();
  }, [withDrawalDates]);
  
  useEffect(() => {
    getDrawalsHandler();
  }, [dates]);

  const chooseMarketModalHandler = async () => {
    if (!bots.length) {
      setIsEmptyBotsModalOpened(true);
      return;
    }

    setChooseMarketModal((prev) => !prev);
  };

  const options = useMemo(() => {
    const options = subscriptions.map((subscription: any) => {
      return {
        label: subscription.bot,
        value: subscription.bot,
      };
    });

    return options;
  }, [subscriptions]);

  const resultBots = useMemo(() => {
    return bots.filter((bot) => {
      return bot.quote === market && botUuids.length ? (bot.allowSharing === 'FALSE' || (bot.allowSharing === 'TRUE' && bot.itemName === botItemName)) : bot.allowSharing === 'FALSE';
    });
  }, [market, bots, botUuids, botItemName]);

  const getPublicBotsContent = () => {
    if (!bots.length && !isBotsLoading) {
      return (
        <Stack
          sx={{
            gap: '10px',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Text styles={EMPTY_BOTS_TITLE}>
            You don't have any shared bots yet. Create your own alert bot, share it with others to earn commissions and grow your community!
          </Text>

          <Box
            sx={{
              maxWidth: 558,
              '&>img': {
                width: '100%',
                height: '100%',
              },
            }}
          >
            <img src='/images/empty-public-bots.png' alt='empty-public-bots' />
          </Box>
        </Stack>
      );
    }

    return (
      <Box sx={LIST(isBotsLoading)}>
        {isBotsLoading && (
          <Loader isContentOverflow={true} />
        )}

        {combinedBots && Object.entries<Bot[]>(combinedBots).map(([key, bots]) => (
          <MarketplaceCard
            key={key}
            bots={bots}
            subscription={subscription}
            button={{
              title: 'Edit item',
              action: () => {
                setMarket(bots[0].quote);
                setBotUuids(bots.map((bot) => bot.botUuid));
                setBotItemName(bots[0].itemName!);
              },
            }}
          />
        ))}
      </Box>
    );
  };

  return (
    <Box sx={wrapper}>
      <Stack
        sx={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Title styles={title}>
          My public bots
        </Title>

        <MainButton
          icon={PlusGreen}
          iconPosition='start'
          type='primary'
          ghost={true}
          onClick={chooseMarketModalHandler}
          styles={button}
          size='large'
        >
          Publish new bot
        </MainButton>
      </Stack>

      {getPublicBotsContent()}

      <Stack
        sx={{
          backgroundColor: '#F8FBF9',
          padding: '32px',
          borderRadius: '16px',
          gap: '24px',
        }}
      >
        <RangePicker
          value={withDrawalDates!}
          handleSetNewDate={datesHandler(setWithDrawalDates)}
        />

        <Box sx={inner}>
          <Box
            position='relative'
            display='flex'
          >
            {isSubscriptionLoading && (
              <Loader isContentOverflow={true} />
            )}

            <Card
              title='Subscriptions'
              items={[{
                title: 'Total',
                value: subscription?.total || 0,
              }, {
                title: 'Active',
                value: subscription?.active || 0,
              }, {
                title: 'Pending',
                value: subscription?.pending || 0,
              }, {
                title: 'Unique users',
                value: subscription?.uniqueUsers || 0,
              }]}
              action={!!subscriptions?.length && (
                <SingleSelect
                  select={{
                    value: subscription?.bot,
                    placeholder: '',
                    onChange: onSelectChange,
                  }}
                  options={options}
                  maxWidth={130}
                />
              )}
            />
          </Box>

          <Box
            position='relative'
            display='flex'
          >
            {isSalesLoading && (
              <Loader isContentOverflow={true} />
            )}

            <Card
              title='Sales'
              items={[{
                title: 'Total',
                value: `${(sales?.total || 0).toFixed(2)} USDT`,
              }, {
                title: 'Withdrawn',
                value: `${((sales.total - sales?.withDrawal) || 0).toFixed(2)} USDT`,
              }, {
                title: 'Available',
                value: `${(sales.withDrawal || 0).toFixed(2)} USDT`,
              }]}
            />
          </Box>

          <WithDraw
            available={sales.withDrawal || 0}
            fetchData={() => {
              fetchData();
              getDrawalsHandler();
            }}
          />
        </Box> 
      </Stack>

      <Box sx={tableWrapper}>
        <Box sx={titleWrapper}>
          <Title styles={tableTitle}>
            Withdrawals
          </Title>

          <Box
            display='flex'
            flexDirection='row'
            alignItems='center'
            gap='20px'
            flex={1}
            justifyContent='flex-end'
          >
            <RangePicker
              value={dates!}
              handleSetNewDate={datesHandler(setDates)}
            />
        
            <MainButton
              type='primary'
              ghost={true}
              icon={ExportGreen}
              styles={button}
              onClick={exportHandler}
              size='middle'
            >
              Export
            </MainButton>
          </Box>
        </Box>

        <Box position='relative'>
          {isLoading && (
            <Loader isContentOverflow={true} />
          )}
          
          <Table
            columns={columns}
            items={(providerPayouts || [])}
            itemsCount={10}
          />
        </Box>
      </Box>

      <PublicBotMarket
        isOpen={chooseMarketModal}
        closeHandler={() => {
          setChooseMarketModal(false);
        }}
        market={{
          value: market,
          items: markets,
          setMarket,
        }}
      />

      <PublicBotParameters
        isOpen={!!market}
        closeHandler={() => {
          setMarket('');
          setBotItemName('');
        }}
        refetchBots={fetchData}
        setChooseMarketModal={setChooseMarketModal}
        bots={resultBots}
        botUuids={botUuids}
        setBotUuids={setBotUuids}
      />

      <EmptySubscription
        isOpen={isEmptyBotsModalOpened}
        modalTitle='Oops! You have no alert bots'
        modalDescription='Create an alert bot, to publish it in the marketplace'
        handleClose={() => {
          setIsEmptyBotsModalOpened(false);
        }}
        button={{
          title: 'Okay',
          action: () => {
            setIsEmptyBotsModalOpened(false);
          },
        }}
        isError={true}
      />
    </Box>
  );
};
