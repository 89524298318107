import { Box, Hidden } from '@mui/material';
import { Button, Layout } from 'antd';
import { createDemoExchangeAccount } from 'entities/exchange/api/create-demo-exchange-account';
import { getExchangeAccounts } from 'entities/exchange/api/get-exchange-accounts';
import { setExchangeData } from 'entities/exchange/helpers/set-exchanges-data';
import { exchangeActions } from 'entities/exchange/model/slices/exchange-slice';
import { disableDemoAccount, enableDemoAccount } from 'entities/user/api';
import { getIsDemoMode } from 'entities/user/model/selectors/get-is-demo-mode/get-is-demo-mode';
import { userActions } from 'entities/user/model/slices/user-slice';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getSkyrexUuid, removeDemoMode, setDemoMode } from 'shared/helpers/storage-helper';
import { useQuery } from 'shared/hooks';
import {
  Burger,
  Logo,
} from 'shared/icons';
import { ChangePasswordModal, Switch } from 'shared/ui';
import {
  ConnectedExchangeAccountStatus as FailureModal,
  Onboarding,
  ConnectedExchangeAccountStatus as SuccessModal,
} from 'shared/ui/modals';
import {
  DesktopPopup,
  HorizontalMenu,
  MobilePopup,
  UserMenu,
} from '../components';
import {
  burger,
  header,
  wrapper,
} from '../styles';

export const Header = () => {
  const isDemoMode = useSelector(getIsDemoMode);

  const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false);
  const [changePasswordModalOpen, setChangePasswordModalOpen] = useState<boolean>(false);
  const [successModalOpen, setSuccessModalOpen] = useState<boolean>(false);
  const [failureModalOpen, setFailureModalOpen] = useState<boolean>(false);
  const [isDemoEnabled, setIsDemoEnabled] = useState<boolean>(isDemoMode || false);
  const [isDemoLoading, setIsDemoLoading] = useState<boolean>(false);

  const location = useLocation();
  const navigate = useNavigate();
  const query = useQuery();
  const dispatch = useDispatch();

  const modalStateHabdler = (action: Dispatch<SetStateAction<boolean>>) => {
    return () => {
      action((prev) => !prev);
    };
  };

  const openPopupHandler = () => {
    setIsPopupOpen((prev) => !prev);
  };

  const handleOpenOnboardingModal = () => {
    navigate(`${location.pathname}?mt=onboarding`);

    localStorage.setItem('connection_type', 'onboarding');
    localStorage.setItem('prev_location', location.pathname);
  };

  const handleCloseOnboardingModal = () => {
    navigate(location.pathname);

    localStorage.removeItem('connection_type');
    localStorage.removeItem('prev_location');
  };

  const switchHandler = async (value: boolean) => {
    setIsDemoEnabled(value);
    setIsDemoLoading(true);

    if (!value) {
      const response = await disableDemoAccount();
      setIsDemoLoading(false);
      if (!response?.data?.skyrexUserUuid) {
        toast.error('Failed to disable demo mode');
        return;
      }

      removeDemoMode();
      dispatch(userActions.setIsDemoMode(false));
      return;
    }

    const response = await enableDemoAccount();
    if (!response?.data?.skyrexUserUuid) {
      toast.error('Failed to enable demo mode');
      return;
    }

    const accountsResponse = await getExchangeAccounts();
    const accounts = accountsResponse?.data?.accounts;

    const demoAccount = accounts?.find((account: any) => account.exchangeCode === 'demo');
    if (demoAccount) {
      dispatch(exchangeActions.setAllExchanges(demoAccount));
      dispatch(userActions.setIsDemoMode(true));
      setDemoMode('demo');
      setIsDemoLoading(false);
      return;
    }

    const demoAccountResponse = await createDemoExchangeAccount();
    if (!demoAccountResponse?.data?.fields) {
      toast.error('Cannot create demo account');
      return;
    }

    const createdAccounts = await setExchangeData(getSkyrexUuid()!, value);
    dispatch(exchangeActions.setAllExchanges(createdAccounts));

    setDemoMode('demo');
    dispatch(userActions.setIsDemoMode(true));
    setIsDemoLoading(false);
  };

  const getDemoModeHandler = async () => {
    setIsDemoEnabled(isDemoMode);
  };

  useEffect(() => {
    getDemoModeHandler();
  }, [isDemoMode]);

  const isOpen = query.get('mt') === 'onboarding';
  const isSuccess = query.get('status');

  return (
    <Layout.Header style={header}>
      <Box
        display='flex'
        justifyContent='space-between'
        alignItems='center'
        sx={wrapper}
      >
        <Box
          display='flex'
          alignItems='center'
          gap={1.5}
        >
          <Hidden lgUp>
            <div
              onClick={openPopupHandler}
              style={burger}
            >
              {Burger}
            </div>
          </Hidden>

          <Link to='/'>
            <Box
              display='flex'
              alignItems='center'
            >
              {Logo}
            </Box>
          </Link>
        </Box>

        <HorizontalMenu />

        <DesktopPopup
          isOpened={isPopupOpen}
          openHandler={setIsPopupOpen}
        />

        <MobilePopup
          isOpened={isPopupOpen}
          openHandler={setIsPopupOpen}
        />

        <Hidden smDown>
          <Button
            type='primary'
            ghost={true}
            onClick={handleOpenOnboardingModal}
          >
            Product tour
          </Button>
        </Hidden>

        <Box
          display='flex'
          alignItems='center'
          gap={{
            xs: '8px',
            md: '24px',
          }}
        >
          <Switch
            value={isDemoEnabled}
            checkedChildren='Demo'
            unCheckedChildren='Demo'
            onChange={switchHandler}
            disabled={isDemoLoading}
          />

          <UserMenu
            handleOpenChangePasswordModal={modalStateHabdler(setChangePasswordModalOpen)}
          />
        </Box>
      </Box>

      <ChangePasswordModal
        isOpen={changePasswordModalOpen}
        handleClose={modalStateHabdler(setChangePasswordModalOpen)}
        handleSuccess={modalStateHabdler(setSuccessModalOpen)}
        handleFailure={modalStateHabdler(setFailureModalOpen)}
      />

      <SuccessModal
        buttonTitle='Okay'
        buttonStyles={{
          color: '#2ECD99',
          border: '0.5px solid #2ECD99',
          height: 'auto',
          padding: '10px 40px',
          maxWidth: 'max-content',
          alignSelf: 'center',
        }}

        isOpen={successModalOpen}
        action={modalStateHabdler(setSuccessModalOpen)}
        title='Your password is updated'
        description=''
      />

      <FailureModal
        buttonTitle='Okay'
        buttonStyles={{
          color: 'red',
          border: '0.5px solid red',
          height: 'auto',
          padding: '10px 40px',
          maxWidth: 'max-content',
          alignSelf: 'center',
        }}
        isOpen={failureModalOpen}
        action={modalStateHabdler(setFailureModalOpen)}
        title='Failed to update password'
        description=''
      />

      <Onboarding
        isOpen={!!isOpen}
        closeHandler={handleCloseOnboardingModal}
        isSuccess={!!isSuccess}
      />
    </Layout.Header>
  );
};
