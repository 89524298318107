import { SxProps, Theme } from '@mui/material';

export const rowItem: SxProps<Theme> = {
  flex: 1,
  '&>*': {
    fontSize: 10,
    fontWeight: 400,
    lineHeight: '27px',
  },
  '&:not(:last-of-type)': {
    maxWidth: 84,
  },
};
