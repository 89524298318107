import { IChooseAccount } from 'entities/choose-account/interfaces';
import { ADA, AVAX, DOGE, DOT, LINK, LTC, SOL, TRX, UNI, XLM, XRP } from 'shared/icons';

const generateLink = (exchangeCode: string, market: string) => {
  const formatedExchangeCode = exchangeCode.replace('-', '');
  return `https://www.tradingview.com/chart/?symbol=${formatedExchangeCode.toUpperCase()}:${market.toUpperCase()}`;
};

export const getCoins = (exchange: IChooseAccount['exchange'], market: IChooseAccount['market']) => {
  return Array.from({
    length: 11, 
  }).map((_, index) => {
    return {
      coin: [
        XLM,
        SOL,
        AVAX,
        ADA,
        XRP,
        LINK,
        DOGE,
        DOT,
        TRX,
        UNI,
        LTC,
      ][index],
      link: [
        generateLink(exchange.code, `XLM${market.code}`),
        generateLink(exchange.code, `SOL${market.code}`),
        generateLink(exchange.code, `AVAX${market.code}`),
        generateLink(exchange.code, `ADA${market.code}`),
        generateLink(exchange.code, `XRP${market.code}`),
        generateLink(exchange.code, `LINK${market.code}`),
        generateLink(exchange.code, `DOGE${market.code}`),
        generateLink(exchange.code, `DOT${market.code}`),
        generateLink(exchange.code, `TRX${market.code}`),
        generateLink(exchange.code, `UNI${market.code}`),
        generateLink(exchange.code, `LTC${market.code}`),
      ][index],
    };
  });
};
