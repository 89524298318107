import { TableColumnsType } from 'antd';
import dayjs from 'dayjs';
import { dateSorter } from 'shared/helpers/sorters';
import { SortIcon, Text } from 'shared/ui';

export const paymentColumns: TableColumnsType = [{
  title: 'Subscription',
  dataIndex: 'subscription',
  key: 'subscription',
  width: 193,
  render: (value: string) => (
    <Text>
      {value}
    </Text>
  ),
  showSorterTooltip: false,
}, {
  title: 'Type',
  dataIndex: 'type',
  key: 'type',
  width: 193,
  render: (value: string) => (
    <Text>
      {value}
    </Text>
  ),
  showSorterTooltip: false,
}, {
  title: 'Referral level',
  dataIndex: 'referralLevel',
  key: 'level',
  width: 193,
  render: (value: string) => (
    <Text>
      {value}
    </Text>
  ),
  showSorterTooltip: false,
}, {
  title: 'Paid amount, USDT',
  dataIndex: 'paid',
  key: 'paid',
  width: 193,
  render: (value: string) => (
    <Text>
      {value}
    </Text>
  ),
  showSorterTooltip: false,
}, {
  title: 'Your share, USDT',
  dataIndex: 'referralShare',
  key: 'referralShare',
  width: 193,
  render: (value: string) => (
    <Text>
      {Number(value).toFixed(2)}
    </Text>
  ),
  showSorterTooltip: false,
}, {
  title: 'Paid date',
  dataIndex: 'date',
  key: 'date',
  width: 193,
  sorter: (a, b) => dateSorter(a.date, b.date),
  render: (value: string) => (
    <Text>
      {dayjs(value).format('DD.MM.YYYY, HH:mm:ss')}
    </Text>
  ),
  sortIcon: (props) => (
    <SortIcon sortOrder={props.sortOrder} />
  ),
  showSorterTooltip: false,
}];
