import { SxProps, Theme } from '@mui/material';

export const INNER: SxProps<Theme> = {
  flexDirection: 'row',
  alignItems: 'center',
  gap: '8px',
  '&>div:first-of-type': {
    display: 'flex',
    width: 30,
    height: 30,
    '&>svg': {
      width: '100%',
      height: '100%',
    },
  },
};
