import { SxProps, Theme } from '@mui/material';

export const BUTTONS_INNER: SxProps<Theme> = {
  display: 'flex',
  alignItems: 'center',
  gap: {
    xs: '12px',
    sm: '30px',
  },
  justifyContent: 'center',
  flexDirection: 'row',
};
