import { Box } from '@mui/material';
import { useMemo } from 'react';
import EChart from 'widgets/echart/echart';
import { EXCHANGE_NAMES } from 'shared/consts/available-exchanges';
import { useMultiExchangeFormatter } from 'shared/hooks/use-multi-exchange-formatter';
import { getSeries, getTooltip, grid, xAxis, yAxis } from '../consts';
import { IAreaChart } from '../interfaces';
import { chart, wrapper } from '../styles';

export const AreaChart = (props: IAreaChart) => {
  const {
    data,
    isMainChart,
    quoteAsset,
    isCopyBot,
    dates,
  } = props;

  const {
    formatters, 
  } = useMultiExchangeFormatter([EXCHANGE_NAMES.BINANCE]);
  
  
  const memoizedXAxis = useMemo(() => xAxis, [xAxis]);
  const memoizedYAxis = useMemo(() => yAxis, [yAxis]);
  
  const memoizedSeries = useMemo(() => {
    return getSeries(data, quoteAsset, isMainChart);
  }, [data, quoteAsset, isMainChart]);

  const memoizedTooltip = useMemo(() => {
    return getTooltip({
      formatters,
      exchange: EXCHANGE_NAMES.BINANCE,
      quoteAsset,
      isMainChart,
      isCopyBot,
    });
  }, [formatters, quoteAsset, isMainChart, isCopyBot]);
  
  
  return (
    <Box sx={wrapper}>
      <EChart
        dates={dates}
        data={data}
        style={chart}
        grid={grid}
        xAxis={memoizedXAxis}
        yAxis={memoizedYAxis}
        tooltip={memoizedTooltip}
        series={memoizedSeries}
      />
    </Box>
  );
};
