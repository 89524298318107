import { SxProps, Theme } from '@mui/material';

export const COIN: SxProps<Theme> = {
  width: 30,
  height: 30,
  '&>a>svg': {
    width: '100%',
    height: '100%',
  },
};
