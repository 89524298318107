import dayjs from 'dayjs';
import createAxiosInstance from 'shared/api/axios/axios';
import { HttpMethod } from 'shared/api/axios/types/http-method';
import { getSkyrexUuid } from 'shared/helpers/storage-helper';

const axios = createAxiosInstance({
  method: HttpMethod.Post,
});

export const getSubscriptions = async (dates: [dayjs.Dayjs | null, dayjs.Dayjs | null] | null) => {    
  const data: any = {
    skyrexUserUuid: getSkyrexUuid()!,
  };
    
  if (dates?.[0]) {
    data.createdDateFrom = dayjs(dates[0]).startOf('day').format('YYYY-MM-DD HH:mm:ss');
    data.createdDateTo = dayjs(dates[1]).endOf('day').format('YYYY-MM-DD HH:mm:ss');
  }

  const response = await axios.request({
    url: '/providerPayout/totalSubscriptions',
    data,
  });
    
  return response?.data;
};
