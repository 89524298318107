import { SxProps, Theme } from '@mui/material';

export const WRAPPER: SxProps<Theme> = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '30px',
  padding: '25px 20px',
  flex: 1,
  width: '100%',
  backgroundColor: 'rgba(46, 205, 153, 0.03)',
  borderRadius: '4px',
  maxWidth: {
    xs: '100%',
    md: 747,
  },
  flexDirection: {
    xs: 'column',
    lg: 'row',
  },
};
