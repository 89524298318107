import { CSSProperties } from 'react';
import { ProgressMarkProps } from '../active-trade/types/active-trade.types';

const LINE_TOP_OFFSET = -5;
const MIN_LINE_HEIGHT = 5;
const MARGIN_TOP = {
  DEFAULT: '-3px',
  HIDDEN: '4px',
} as const;

const TOP_OFFSET = -15;

export const baseStyles = {
  mark: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    cursor: 'default',
  },
  line: {
    width: '1px',
    backgroundColor: '#9E9E9E',
    position: 'relative' as const,
    zIndex: 0,
  },
  labelValue: {
    display: 'flex',
    alignItems: 'baseline',
    position: 'absolute' as const,
    zIndex: 1,
  },
  label: {
    fontSize: '9px',
    whiteSpace: 'nowrap' as const,
  },
  value: {
    fontSize: '9px',
    marginLeft: '3px',
  },
} as const;

export const getMarkStyles = ({
  isExchangePrice, 
}: ProgressMarkProps): CSSProperties => ({
  ...baseStyles.mark,
  flexDirection: isExchangePrice ? 'column-reverse' : 'column',
  position: isExchangePrice ? 'absolute' : 'relative',
  top: isExchangePrice ? '-35px' : '0px',
});

export const getLineStyles = ({
  visible, 
  isEntryPrice, 
  distance,
}: ProgressMarkProps): CSSProperties => ({
  ...baseStyles.line,
  height: `${(visible || isEntryPrice) ? distance + LINE_TOP_OFFSET : MIN_LINE_HEIGHT}px`,
  top: `${LINE_TOP_OFFSET}px`,
});

export const getLabelValueStyles = ({
  visible,
  isExchangePrice,
  isEntryPrice,
  distance,
  isHighestPrice,
  isLowestPrice,
  highestPrice,
  lowestPrice,
  value,
}: ProgressMarkProps): CSSProperties => {
  
  const labelValueBaseStyles: CSSProperties = {
    ...baseStyles.labelValue,
    marginTop: !visible ? MARGIN_TOP.HIDDEN : MARGIN_TOP.DEFAULT,
    top: isExchangePrice || !visible 
      ? `${TOP_OFFSET}px` 
      : `${distance + TOP_OFFSET}px`,
  };
  
  // Общая функция для определения позиции метки
  const getMarkPosition = (): 'left' | 'right' => {
    // Если это самая низкая цена
    if (isLowestPrice) {
      return 'left';
    }

    if (isHighestPrice) {
      return 'right';
    }
    
    if (highestPrice && lowestPrice && value) {
      // Вычисляем относительное положение текущей цены
      const relativePosition = (value - lowestPrice) / (highestPrice - lowestPrice);
      
      if (!isExchangePrice && !isEntryPrice) {
        return relativePosition >= 0.9 ? 'left' : 'right';
      } else {
        // Для exchangePrice инвертируем позиционирование
        return relativePosition >= 0.9 ? 'right' : 'left';
      }
    }
    // Дефолтное значение
    return 'left';
  };
  
  return {
    ...labelValueBaseStyles,
    [getMarkPosition()]: '3px',
  };
};
