import { SxProps, Theme } from '@mui/material';

export const WRAPPER: SxProps<Theme> = {
  gap: '15px',
  borderRadius: '16px',
  backgroundColor: '#fff',
  padding: '18px 14px',
  flex: 1,
  width: '100%',
  maxWidth: {
    xs: '100%',
    lg: 450,
  },
};
