import { CurrentSymbol } from 'entities/terminal/model/types/terminal-schema';
import { formatByPrecisionAndTrim } from 'pages/manual-trading/trading-terminal/helpers/helpers';

export const formatSymbolPrice = (
  symbols: CurrentSymbol[],
  symbol: string,
  value: number,
): string => {
  const symbolInfo = symbols.find(s => s.symbol === symbol);
  
  if (!symbolInfo) {
    return String(value.toFixed(6));
  }
  return formatByPrecisionAndTrim(value.toString(), symbolInfo.quoteAssetPrecision, parseFloat(symbolInfo.priceMin), parseFloat(symbolInfo.priceMax));
};

export const calculatePrice = (
  executedQuote: number,
  executedBase: number,
  symbols: CurrentSymbol[],
  symbol: string,
): string => {
  if (!executedBase || isNaN(executedQuote / executedBase)) {
    return '-';
  }
  return formatSymbolPrice(symbols, symbol, executedQuote / executedBase);
};
