import { Box, Stack } from '@mui/material';
import { Title } from 'shared/ui';
import { IStepBlockProps } from '../interfaces';
import { TITLE, WRAPPER } from '../styles';

export const StepBlock = (props: IStepBlockProps) => {
  return (
    <Stack sx={WRAPPER}>
      {props.title && (
        <Title styles={TITLE}>{props.title}</Title>
      )}

      <Box>{props.children}</Box>
    </Stack>
  );
};
