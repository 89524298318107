import { SxProps, Theme } from '@mui/material';

export const BANNER: SxProps<Theme> = {
  width: '100%',
  flex: 1,
  borderRadius: '16px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '&>img': {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    objectPosition: 'center',
  },
};
