import { Stack } from '@mui/material';
import { Divider } from 'antd';
import { getActiveTradingPairs, getExchange, getMarket, getTradingAmount } from 'entities/choose-account';
import { getAlertSource } from 'entities/choose-source';
import {
  getAlertOrders,
  getAlertOrdersSizeScale,
  getIsOpened,
  getPresetOrders,
  getPresetOrdersPriceChangeScale,
  getPresetOrdersSizeScale,
  getPresetPriceChange,
  getSegment as getSegmentAdditional,
} from 'entities/risk-managment-additional';
import { getSegment, getValues } from 'entities/risk-managment-auto';
import {
  getTakeProfitOrders,
  getTakeProfitOrdersPriceChangeScale,
  getTakeProfitOrdersSizeScale,
  getTakeProfitPriceChange as getTakeProfitPriceChangeAction,
  getStopLossPriceChange,
  getSegment as getSegmentCloseOrders,
} from 'entities/risk-managment-close';
import { getAdditionalCopyValue, getTakeProfitPriceChange, getTakeProfitValume } from 'pages/trading-bots/configurator-v2/helpers';
import { getAdditionalEntries } from 'pages/trading-bots/configurator-v2/helpers/get-additional-entries';
import { getBaseOrder } from 'pages/trading-bots/configurator-v2/helpers/get-base-order';
import { getFullPosition } from 'pages/trading-bots/configurator-v2/helpers/get-full-position';
import { Fragment, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { currencyFormatter } from 'shared/helpers/currency-formatter';
import { Block } from '../../../../block';
import { WRAPPER } from '../../../styles';

const divider = (
  <Divider
    style={{
      margin: 0,
    }}
  />
);

export const ModalPanel = () => {
  const tradingAmount = useSelector(getTradingAmount);
  const activeTradingPairs = useSelector(getActiveTradingPairs);
  const market = useSelector(getMarket);
  const exchange = useSelector(getExchange);

  const additionalSegment = useSelector(getSegmentAdditional);

  const isAlert = additionalSegment === 'alert';

  const isAdditionalOpened = useSelector(getIsOpened);
  const additionalOrders = useSelector(getPresetOrders);
  const additionalOrdersSizeScale = useSelector(getPresetOrdersSizeScale);
  const additionalOrdersPriceChangeScale = useSelector(getPresetOrdersPriceChangeScale);
  const additionalPriceChange = useSelector(getPresetPriceChange);
  const additionalAlertOrders = useSelector(getAlertOrders);
  const additionalAlertOrdersSizeScale = useSelector(getAlertOrdersSizeScale);
  const values = useSelector(getValues);

  const orders = useSelector(getTakeProfitOrders);
  const takeProfitPriceChange = useSelector(getTakeProfitPriceChangeAction);
  const takeProfitOrdersSizeScale = useSelector(getTakeProfitOrdersSizeScale);
  const takeProfitOrdersPriceChangeScale = useSelector(getTakeProfitOrdersPriceChangeScale);

  const closeOrdersPriceChange = useSelector(getStopLossPriceChange);
  const closeOrdersSegment = useSelector(getSegmentCloseOrders);

  const isCloseOrdersAlert = closeOrdersSegment === 'alert';

  const segment = useSelector(getSegment);
  const alertSource = useSelector(getAlertSource);

  const isAutoSegment = useMemo(() => segment === 'auto', [segment]);

  const baseOrder = useMemo(() => {
    const result = getBaseOrder({
      additional: {
        isOpened: isAdditionalOpened,
        orders: additionalOrders,
        ordersSizeScale: additionalOrdersSizeScale,
      },
      chooseAccount: {
        tradingAmount: +tradingAmount,
        activeTradingPairs: activeTradingPairs,
      },
    });

    return result;
  }, [
    isAdditionalOpened,
    additionalOrders,
    additionalOrdersSizeScale,
    tradingAmount,
    activeTradingPairs,
  ]);

  const fullPosition = useMemo(() => {
    const result = getFullPosition({
      additional: {
        isOpened: isAutoSegment || isAdditionalOpened,
        orders: isAutoSegment ? values.maxAdditionalOrders : additionalOrders,
        priceChange: isAutoSegment ? values.additionalOrderPriceChange : additionalPriceChange,
        ordersSizeScale: isAutoSegment ? values.additionalOrderSizeScale : additionalOrdersSizeScale,
        ordersPriceChangeScale: isAutoSegment ? values.additionalOrderPriceChangeScale : additionalOrdersPriceChangeScale,
        segment: additionalSegment,
      },
      chooseAccount: {
        tradingAmount: +tradingAmount,
        activeTradingPairs: activeTradingPairs,
      },
    });

    return result;
  }, [
    isAdditionalOpened,
    additionalOrders,
    additionalPriceChange,
    additionalOrdersSizeScale,
    additionalOrdersPriceChangeScale,
    additionalSegment,
    tradingAmount,
    activeTradingPairs,
    values,
    isAutoSegment,
  ]);

  const additiobalHeaders = useMemo(() => {
    if (!isAlert) {
      return [{
        label: '№',
        key: 'order',
      }, {
        label: 'Order size',
        key: 'order-size',
      }, {
        label: 'Price change',
        key: 'price-change',
      }];
    }

    switch (alertSource) {
    case 'indicator':
      return [{
        label: '№',
        key: 'order',
      }, {
        label: 'Order size',
        key: 'order-size',
      }, {
        label: 'Alert',
        key: 'alert-message',
      }];
    case 'strategy':
      return [{
        label: '№',
        key: 'order',
      }, {
        label: 'Order size',
        key: 'order-size',
      }, {
        label: 'PineScript',
        key: 'pineScript-parameter',
      }];
    case 'custom':
      return [{
        label: '№',
        key: 'order',
      }, {
        label: 'Order size',
        key: 'order-size',
      }, {
        label: 'JSON body',
        key: 'json-body',
      }];
    default:
      return [];
    }
  }, [alertSource, isAlert]);

  const items = useMemo(() => {
    const autoParams = {
      isOpened: true,
      segment: 'auto',
      orders: values.maxAdditionalOrders,
      priceChange: values.additionalOrderPriceChange,
      ordersSizeScale: values.additionalOrderSizeScale,
      ordersPriceChangeScale: values.additionalOrderPriceChangeScale,
      tradingAmount: +tradingAmount,
      activeTradingPairs: activeTradingPairs,
    };

    const customParams = {
      isOpened: isAdditionalOpened,
      segment: additionalSegment,
      orders: isAlert ? additionalAlertOrders : additionalOrders,
      priceChange: additionalPriceChange,
      ordersSizeScale: isAlert ? additionalAlertOrdersSizeScale : additionalOrdersSizeScale,
      ordersPriceChangeScale: additionalOrdersPriceChangeScale,
      tradingAmount: +tradingAmount,
      activeTradingPairs: activeTradingPairs,
    };

    const entries = getAdditionalEntries(isAutoSegment ? autoParams : customParams);

    const formatedItems = entries.map((item, index) => {
      return [{
        value: item.position,
      }, {
        value: `${currencyFormatter(item.orderSize, market.presition)} ${market.code}`,
      }, {
        value: isAlert ? 'Copy' : `${item.priceChange}%`,
        copy: isAlert ? getAdditionalCopyValue({
          exchangeAccountUuid: exchange.id,
          source: alertSource,
          position: (++index) + 1,
        }) : '',
      }];
    });

    return formatedItems;
  }, [
    tradingAmount,
    activeTradingPairs,
    market,
    isAdditionalOpened,
    additionalSegment,
    additionalAlertOrders,
    additionalOrders,
    additionalPriceChange,
    additionalAlertOrdersSizeScale,
    additionalOrdersSizeScale,
    additionalOrdersPriceChangeScale,
    isAutoSegment,
    isAlert,
    values,
    alertSource,
    exchange.id,
  ]);

  const {
    formatedItems: takeProfitItems,
  } = useMemo(() => {
    const arraySettings = {
      length: orders || 1, 
    };
    const result = Array.from(arraySettings).map((_, index) => {
      const position = ++index;

      const priceChange = getTakeProfitPriceChange({
        orders: orders,
        priceChange: takeProfitPriceChange,
        ordersPriceChangeScale: takeProfitOrdersPriceChangeScale,
        position,
      });
      const volume = getTakeProfitValume({
        orders: orders,
        ordersSizeScale: takeProfitOrdersSizeScale,
        position,
      });

      return {
        position,
        priceChange,
        volume: (!isFinite(volume) || isNaN(volume)) ? 0 : volume,
      };
    });

    const formatedItems = result.map((item) => {
      return [{
        value: item.position,
      }, {
        value: `${item.volume.toFixed(2)}%`,
      }, {
        value: isCloseOrdersAlert ? 'Alert' : `${item.priceChange.toFixed(2)}%`,
      }];
    });

    return {
      formatedItems,
      result,
    };
  }, [
    orders,
    takeProfitPriceChange,
    takeProfitOrdersPriceChangeScale,
    takeProfitOrdersSizeScale,
    isCloseOrdersAlert,
  ]);

  const getCloseOrdersContent = () => {
    if (!isAutoSegment) {
      return {
        headers: [{
          label: '№',
          key: 'order',
        }, {
          label: 'Volume',
          key: 'volume',
        }, {
          label: 'Price change',
          key: 'price-change',
        }],
        items: takeProfitItems as any,
        prefixTitle: 'Take profits',
      };
    }

    const rows = [null, {
      title: 'Take profit',
      value: `${values.takeProfitPercent || 0}%`,
    }];

    if (values?.stopLossPercent) {
      return [...rows, {
        title: 'Stop loss',
        value: `${values?.stopLossPercent}%`,
      }];
    }
    
    return rows;
  };

  return (
    <Stack sx={WRAPPER}>
      <Block
        title='Entry order'
        layout='row'
        content={[{
          title: 'Open order size',
          value: `${currencyFormatter(isAutoSegment ? (values?.baseOrderSize || 0) : baseOrder, market.presition)} ${market.code}`,
        }]}
      />


      {!!items.length && (
        <Fragment>
          {divider}
          
          <Block
            title='Additional entry orders'
            layout='table'
            content={{
              headers: additiobalHeaders,
              items: items as any,
            }}
          />
        </Fragment>
      )}

      {divider}

      <Block
        layout='row'
        content={[{
          title: 'Full position per coin',
          value: `${currencyFormatter(isAutoSegment ? (values?.fullPositionSize || 0) : fullPosition, market.presition)} ${market.code}`,
        }]}
      />

      <Block
        title='Close orders'
        layout={isAutoSegment ? 'row' : 'table'}
        content={getCloseOrdersContent()}
      />

      {!isAutoSegment && (
        <Block
          title=''
          layout='row'
          content={[{
            title: 'Stop loss',
            value: isCloseOrdersAlert ? 'Alert' : `${-Math.abs(closeOrdersPriceChange)}%`,
          }]}
        />
      )}
    </Stack>
  );
};
