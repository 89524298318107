import { updateTrade } from 'entities/new-terminal/model/services/update-trade';
import { cancelManualTrade } from 'pages/manual-trading/trading-terminal/api/manual-trade-actions';
import { manualTradeCloseByLimit, manualTradeCloseByMarket, manualTradeOpenByLimit, manualTradeOpenByMarket } from 'pages/trading-terminal-page/order-panel/ExchangeForm/api/trade-actions';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { delay } from 'shared/helpers/delay';

interface UseTradeActionsParams {
  tradeUuid: string;
  closeModal: () => void;
  setIsLoading?: (loading: boolean) => void;
}

export const useTradeActions = ({
  tradeUuid,
  closeModal,
  setIsLoading,
}: UseTradeActionsParams) => {
  const dispatch = useDispatch();

  //@TODO refactor method, use only one method to update trade
  const updateTradeInStore = async (uuid: string) => {
    dispatch(updateTrade(uuid));
  };
  
  const handleCloseTradeByMarket = async () => {
    try {
      setIsLoading?.(true);
      const response = await manualTradeCloseByMarket(tradeUuid);
      
      if (!response.success) {
        toast.error('Error closing trade by market');
        return;
      }
      
      await delay(1000);
      closeModal();
      await updateTradeInStore(tradeUuid);
    } catch (error) {
      toast.error('Error closing trade by market');
    } finally {
      setIsLoading?.(false);
    }
  };

  const handleCloseTradeByLimit = async () => {
    try {
      const response = await manualTradeCloseByLimit(tradeUuid);
      
      if (!response.success) {
        toast.error('Error closing trade by limit');
        return;
      }
      
      await delay(1000);
      await updateTradeInStore(tradeUuid);
      closeModal();
    } catch (error) {
      toast.error('Error closing trade by limit');
    }
  };

  const handleOpenTradeByMarket = async () => {
    try {
      const response = await manualTradeOpenByMarket(tradeUuid);
      
      if (!response.success) {
        toast.error('Error opening trade by market');
        return;
      }
      
      await delay(1000);
      await updateTradeInStore(tradeUuid);
      closeModal();
    } catch (error) {
      toast.error('Error opening trade by market');
    }
  };

  const handleOpenTradeByLimit = async () => {
    try {
      const response = await manualTradeOpenByLimit(tradeUuid);
      
      if (!response.success) {
        toast.error('Error opening trade by limit');
        return;
      }
      
      await delay(1000);
      await updateTradeInStore(tradeUuid);
      closeModal();
    } catch (error) {
      toast.error('Error opening trade by limit');
    }
  };

  const handleCancelTrade = async () => {
    try {
      const response = await cancelManualTrade(tradeUuid);
      if (!response.success) {
        toast.error('Error cancelling trade');
        return;
      }
      
      const smartTradeUuid = response.data.smartTradeUuids[0];
      await delay(1000);
      
      await updateTradeInStore(smartTradeUuid);
      closeModal();
    } catch (error) {
      toast.error('Error cancelling trade');
    }
  };

  const handleUpdateTrade = async () => {
    setIsLoading?.(true);
    await dispatch(updateTrade(tradeUuid));
    setIsLoading?.(false);
  };

  return {
    handleCloseTradeByMarket,
    handleCloseTradeByLimit,
    handleOpenTradeByMarket,
    handleOpenTradeByLimit,
    handleCancelTrade,
    handleUpdateTrade,
  };
};
