import { PlusOutlined } from '@ant-design/icons';
import { Box } from '@mui/material';
import { Dropdown } from 'antd';
import { Dispatch, SetStateAction } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { INVITE_FRIENDS } from 'shared/consts';
import { Down } from 'shared/icons';
import { Bagde, MainButton, TextButton } from 'shared/ui';
import { IMenuItem } from '../../../interfaces';
import { ItemNavLink } from '../components';
import { actionItem, activeButton, button, plusIcon } from '../styles';

interface IRenderMenu {
  menuItems: IMenuItem[];
  setIsOpened: Dispatch<SetStateAction<boolean>>;
}
export const renderMenu = (params: IRenderMenu) => {
  const {
    menuItems,
    setIsOpened,
  } = params;

  const openAddNewBotModal = () => {
    setIsOpened(true);
  };

  const items = menuItems.map((item: IMenuItem) => {
    if (item.children) {
      return (
        <Dropdown
          key={item.key}
          menu={{
            items: item.children.map((childrenItem) => {
              if (childrenItem.key === 'add') {
                return {
                  label: (
                    <Box
                      sx={{
                        padding: '8px 0',
                      }}
                    >
                      <MainButton
                        type='primary'
                        size='large'
                        styles={actionItem}
                        onClick={openAddNewBotModal}
                      >
                        <PlusOutlined style={plusIcon} />

                        {childrenItem.label}
                      </MainButton>
                    </Box>
                  ),
                  key: childrenItem.key,
                };
              }

              return {
                label: (
                  <ItemNavLink
                    key={childrenItem.label}
                    label={childrenItem.label}
                    link={childrenItem.link}
                    icon={(
                      <Box
                        width={32}
                        height={32}
                        display='flex'
                        alignItems='center'
                        justifyContent='center'
                      >
                        {childrenItem.icon}
                      </Box>
                    )}
                    description={childrenItem.description}
                  />
                ),
                key: childrenItem.label,
              };
            }),
          }}
        >
          <a onClick={(e) => e.preventDefault()}>
            <TextButton
              icon={Down}
              styles={button}
            >
              {item.label}
            </TextButton>
          </a>
        </Dropdown>
      );
    }

    if (item.key === 'invite-friends') {
      return (
        <Link
          to={INVITE_FRIENDS}
        >
          <MainButton
            key={item.key}
            type='text'
            styles={button}
          >
            {item.label}
          </MainButton>
        </Link>
      );
    }

    return (
      <NavLink
        key={item.key}
        to={item.link}
      >
        {({
          isActive,
        }) => (
          <MainButton
            type='text'
            styles={{
              ...button,
              ...(isActive && activeButton),
            }}
          >
            {item.label}

            {item.badge && (
              <Bagde title={item.badge} />
            )}
          </MainButton>
        )}
      </NavLink>
    );
  });

  return items;
};
