import { createSlice } from '@reduxjs/toolkit';
import { fetchSymbols } from '../services/fetch-symbols';
import { ExchangeSymbolsSchema } from '../types/exchange-symbols-schema';

const initialState: ExchangeSymbolsSchema = {
  data: {},
  loading: {},
  error: {},
  lastUpdated: {},
};

//@TODO увеличить время кеширования
export const CACHE_TTL = 30 * 60 * 60 *1000;

export const symbolsSlice = createSlice({
  name: 'symbols',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSymbols.pending, (state, action) => {
        const exchange = action.meta.arg;
        state.loading[exchange] = true;
        state.error[exchange] = null;
      })
      .addCase(fetchSymbols.fulfilled, (state, action) => {
        const exchange = action.meta.arg;
        if (action.payload) {
          state.data[exchange] = action.payload.symbols;
          state.lastUpdated[exchange] = Date.now();
        }
        state.loading[exchange] = false;
      })
      .addCase(fetchSymbols.rejected, (state, action) => {
        const exchange = action.meta.arg;
        state.loading[exchange] = false;
        state.error[exchange] = action.error.message || 'Unknown error';
      });
  },
});

export const {
  actions: exchangeSymbolsActions,
  reducer: exchangeSymbolsReducer,
} = symbolsSlice;
