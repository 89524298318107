import { Box } from '@mui/material';
import { CurrentSymbol } from 'entities/terminal/model/types/terminal-schema';
import EChart from 'widgets/echart/echart';
import { EXCHANGE_NAMES } from 'shared/consts/available-exchanges';
import { useMultiExchangeFormatter } from 'shared/hooks/use-multi-exchange-formatter';
import { getSeries, getTooltip, getXAxis, grid, yAxis } from '../consts';
import { chart, wrapper } from '../styles';

interface BarChartData {
  data?: [string, number][];
  exchangeSymbols?: CurrentSymbol[];
  quoteAsset: string;
  isCopyBot?: boolean;
}

export const BarChart = ({
  data = [],
  quoteAsset,
  isCopyBot,
}: BarChartData) => {
  const {
    formatters, 
  } = useMultiExchangeFormatter([EXCHANGE_NAMES.BINANCE]);
  
  const xAxisData = data?.map(([x]) => x);
  const seriesData = data?.map(([, y]) => y);
  
  return (
    <Box sx={wrapper}>
      <EChart
        style={chart}
        tooltip={getTooltip({
          formatters,
          exchange: EXCHANGE_NAMES.BINANCE,
          quoteAsset,
          isCopyBot,
        })}
        grid={grid}
        xAxis={getXAxis(xAxisData)}
        yAxis={yAxis}
        series={getSeries(seriesData)}
      />
    </Box>
  );
};
  
