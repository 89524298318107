import { SxProps, Theme } from '@mui/material';

export const WRAPPER: SxProps<Theme> = {
  backgroundColor: '#fff',
  gap: '14px',
  padding: '6px 12px',
  width: '100%',
  flex: 1,
  textAlign: 'center',
  borderRadius: '16px',
};
