import createAxiosInstance from 'shared/api/axios/axios';
import { HttpMethod } from 'shared/api/axios/types/http-method';

const axios = createAxiosInstance({
  method: HttpMethod.Post,
});

interface GetBalances {
  exchangeAccountUuids: string[];
}

export const getBalances = async (params: GetBalances) => {
  const {
    exchangeAccountUuids, 
  } = params;
  
  const response = await axios.request({
    url: '/exchangeAccount/terminalGetByFilter',
    data: {
      exchangeAccountUuids,
      page: 1,
    },
  });

  return response.data.data.accounts;
};
