import { Stack } from '@mui/material';
import { Download } from 'shared/icons';
import { Link, Text } from 'shared/ui';
import { IMaterialProps } from '../interfaces';
import { BANNER, LOGO, TITLE, TITLE_WRAPPER, WRAPPER } from '../styles';

export const Material = (props: IMaterialProps) => {
  return (
    <Stack sx={WRAPPER}>
      <Stack sx={props.type === 'banner' ? BANNER : LOGO}>
        <img src={props.source} alt='' />
      </Stack>

      <Link
        styles={TITLE_WRAPPER}
        download={true}
        href={props.source}
      >
        <Text styles={TITLE}>
          {props.title}
        </Text>

        {Download}
      </Link>
    </Stack>
  );
};
