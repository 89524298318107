import { Box } from '@mui/material';
import { Select } from 'antd';
import { ArrowDown } from 'shared/icons';
import { Text } from 'shared/ui';
import { IMultipleSelect } from '../interfaces';
import { labelText, selectWrapper, wrapper } from '../styles';

export const MultipleSelect = (props: IMultipleSelect) => {
  const {
    label,
    select,
    options,
    dropdown,
    maxWidth,
    maxTagPlaceholder,
    optionRender,
  } = props;

  return (
    <Box sx={wrapper(maxWidth)}>
      {label && (
        <Text
          type='secondary'
          styles={labelText}
        >
          {label}
        </Text>
      )}

      <Select
        value={select.value}
        placeholder={select.placeholder}
        options={options}
        onChange={select.onChange}
        optionRender={optionRender}
        variant='outlined'
        style={selectWrapper}
        suffixIcon={ArrowDown}
        dropdownRender={dropdown}
        size='middle'
        mode='multiple'
        maxTagCount='responsive'
        maxTagPlaceholder={maxTagPlaceholder}
      />
    </Box>
  );
};
