import { Box } from '@mui/material';
import { getSortedActiveTrades } from 'entities/new-terminal/model/selectors/get-sorted-active-trades';
import { setActiveTrades } from 'entities/new-terminal/model/services/set-active-trades';
import { memo, useCallback, useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Text } from 'shared/ui';
import { header, wrapper } from '../styles';
import { TradeItem } from './components/trade-item';

export const ActiveTrades = memo((props: any) => {
  const {
    exchanges,
  } = props;

  const {
    activeTrades,
  } = useSelector(getSortedActiveTrades, shallowEqual);

  const dispatch = useDispatch();
  
  const fetchManualTrades = useCallback(async () => {
    const accounts = exchanges.map((exchange: any) => exchange.value);
    dispatch(setActiveTrades(accounts));
  }, [dispatch, exchanges]);

  useEffect(() => {
    fetchManualTrades();
  }, [fetchManualTrades]);

  return (
    <Box sx={wrapper}>
      <Box sx={header}>
        <Box><Text>Pair</Text></Box>
        <Box><Text>Account</Text></Box>
        <Box><Text>Start date</Text></Box>
        <Box><Text>Volume</Text></Box>
        <Box><Text>Progress</Text></Box>
        <Box><Text>Status</Text></Box>
        <Box><Text>Return</Text></Box>
        <Box><Text>Actions</Text></Box>
      </Box>

      {activeTrades?.map((trade, index) => (
        <TradeItem
          key={index}
          trade={trade}
          exchanges={exchanges}
        />
      ))}
    </Box>
  );  
});

ActiveTrades.displayName = 'ActiveTrades';
