import createAxiosInstance from 'shared/api/axios/axios';
import { HttpMethod } from 'shared/api/axios/types/http-method';
import { getSkyrexUuid } from 'shared/helpers/storage-helper';

const axios = createAxiosInstance({
  method: HttpMethod.Post,
});

export const createReferalPayout = async (payoutAmount: number, paymentMethod: string) => {    
  const response = await axios.request({
    url: '/referral/createReferralPayout',
    data: {
      skyrexUserUuid: getSkyrexUuid()!,
      paymentMethod,
      payoutAmount,
    },
  });
    
  return response?.data;
};
