import { CSSProperties } from 'react';
import { getFontSize } from 'shared/helpers';

export const TITLE: CSSProperties = {
  fontSize: getFontSize(14, 20),
  fontWeight: 400,
  lineHeight: 'normal',
  color: '#3A3A3C',
  textAlign: 'center',
};
