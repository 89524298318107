import { useCallback, useMemo } from 'react';
import { EXCHANGE_NAMES, ExchangeName } from 'shared/consts/available-exchanges';
import { useSymbolFormatter } from './use-symbol-formatter';
    
const useAllExchangeFormatters = () => {
  const binanceFormatter = useSymbolFormatter(EXCHANGE_NAMES.BINANCE);
  const bybitFormatter = useSymbolFormatter(EXCHANGE_NAMES.BYBIT);
  const okxFormatter = useSymbolFormatter(EXCHANGE_NAMES.OKX);
  const gateioFormatter = useSymbolFormatter(EXCHANGE_NAMES.GATEIO);
  const cryptoComFormatter = useSymbolFormatter(EXCHANGE_NAMES.CRYPTO_COM);
  const bitmartFormatter = useSymbolFormatter(EXCHANGE_NAMES.BITMART);
  const htxFormatter = useSymbolFormatter(EXCHANGE_NAMES.HTX);
  const cryptoCompareFormatter = useSymbolFormatter(EXCHANGE_NAMES.CRYPTO_COMPARE);
  const kucoinFormatter = useSymbolFormatter(EXCHANGE_NAMES.KUCOIN);
  return useMemo(() => ({
    [EXCHANGE_NAMES.BINANCE]: binanceFormatter,
    [EXCHANGE_NAMES.BYBIT]: bybitFormatter,
    [EXCHANGE_NAMES.OKX]: okxFormatter,
    [EXCHANGE_NAMES.GATEIO]: gateioFormatter,
    [EXCHANGE_NAMES.CRYPTO_COM]: cryptoComFormatter,
    [EXCHANGE_NAMES.BITMART]: bitmartFormatter,
    [EXCHANGE_NAMES.HTX]: htxFormatter,
    [EXCHANGE_NAMES.CRYPTO_COMPARE]: cryptoCompareFormatter,
    [EXCHANGE_NAMES.KUCOIN]: kucoinFormatter,
  }), [
    binanceFormatter,
    bybitFormatter,
    okxFormatter,
    gateioFormatter,
    cryptoComFormatter,
    bitmartFormatter,
    htxFormatter,
    cryptoCompareFormatter,
    kucoinFormatter,
  ]);
};

export const useMultiExchangeFormatter = (exchanges: ExchangeName[]) => {
  const allFormatters = useAllExchangeFormatters();

  // Фильтруем только запрошенные биржи
  const formatters = useMemo(() => 
    exchanges.reduce((acc, exchange) => ({
      ...acc,
      [exchange]: allFormatters[exchange],
    }), {} as Record<ExchangeName, ReturnType<typeof useSymbolFormatter>>),
  [exchanges, allFormatters]);

  const formatPriceForExchange = useCallback((
    exchange: ExchangeName,
    baseSymbol: string,
    quoteSymbol: string,
    executedQuote: string,
    executedBase: string,
  ) => {
    
    const formatter = allFormatters[exchange];
    if (!formatter) return '-';
    
    return formatter.formatTradePrice(
      baseSymbol,
      quoteSymbol,
      parseFloat(executedQuote),
      parseFloat(executedBase),
    );
  }, [allFormatters]);

  const formatTotalForExchange = useCallback((
    exchange: ExchangeName,
    baseSymbol: string,
    quoteSymbol: string,
    quoteAmount: string,
  ) => {
    const formatter = allFormatters[exchange];
    if (!formatter) return '-';
    
    return formatter.formatPrice(
      `${baseSymbol}${quoteSymbol}`,
      parseFloat(quoteAmount),
    );
  }, [allFormatters]);
  
  const formatReturnValueForBots = useCallback((
    exchange: ExchangeName,
    baseSymbol: string,
    quoteSymbol: string,
    trade: {
      status: string;
      sideFirstStep: 'BUY' | 'SELL';
      returnQuote: number | null;
      returnBase: number | null;
    },
  ) => {
    if (trade.status !== 'completed') {
      return '-';
    }

    const formatter = allFormatters[exchange];
    if (!formatter) return '-';

    const symbol = `${baseSymbol}${quoteSymbol}`;
    // const symbolInfo = formatter.symbols.find(s => s.symbol === symbol);

    const returnQuote = trade.returnQuote !== null && trade.returnQuote !== undefined 
      ? trade.returnQuote.toString() 
      : '0';
    const returnBase = trade.returnBase !== null && trade.returnBase !== undefined 
      ? trade.returnBase.toString() 
      : '0';

    if (trade.sideFirstStep === 'BUY') {
      const returnQuoteValue = parseFloat(returnQuote);
      return returnQuoteValue === 0 
        ? '-' 
        : formatter.formatPrice(
          symbol,
          returnQuoteValue,
        );
    }
    
    if (trade.sideFirstStep === 'SELL') {
      const returnBaseValue = parseFloat(returnBase);
      return returnBaseValue === 0 
        ? '-' 
        : formatter.formatPrice(
          symbol,
          returnBaseValue,
        );
    }

    return '-';
  }, [allFormatters]);

  const loadAllSymbols = useCallback(() => {
    exchanges.forEach(exchange => {
      const hasData = formatters[exchange]?.symbols?.length > 0;
      if (!hasData) {
        formatters[exchange]?.loadSymbols();
      }
    });
  }, [formatters, exchanges]);

  return {
    formatters,
    formatPriceForExchange,
    formatTotalForExchange,
    formatReturnValueForBots,
    loadAllSymbols,
  };
};
