import { Stack } from '@mui/material';
import { Segmented } from 'antd';
import { Label } from 'widgets';
import { IOrderTypeProps } from '../interfaces';
import { WRAPPER } from '../styles';

export const OrderType = (props: IOrderTypeProps) => {
  return (
    <Stack sx={WRAPPER}>
      <Label
        title='Order type'
      />

      <Segmented
        value={props.value}
        options={[{
          label: 'Market',
          value: 'market',
        }, {
          label: 'Limit',
          value: 'limit',
        }]}
        onChange={props.onChange}
        block={true}
      />
    </Stack>
  );
};
