import { CSSProperties } from 'react';
import { getFontSize } from 'shared/helpers';

export const EMPTY_BOTS_TITLE: CSSProperties = {
  fontSize: getFontSize(16, 22),
  fontWeight: 400,
  lineHeight: 'normal',
  color: '#3A3A3C',
  textAlign: 'center',
  maxWidth: 898,
};
