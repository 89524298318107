import { botActions } from 'entities/bot/model/slices/bot-slice';
import { chooseAccountActions } from 'entities/choose-account';
import { ExchangeAccount } from 'entities/exchange/model/types/exchange-account';
import { getIsDemoMode } from 'entities/user/model/selectors/get-is-demo-mode/get-is-demo-mode';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getExchanges, getExchangesIds } from 'widgets/exchanges/api/get-exchanges';
import { getDemoAccount } from 'shared/helpers';
import { getSkyrexUuid } from 'shared/helpers/storage-helper';
import { setExchanges } from '../actions';
import { getBotInfo } from '../api';
import { convertExchange } from '../helpers';

export const useGetExchanges = (id?: string) => {
  const isDemoMode = useSelector(getIsDemoMode);
  const dispatch = useDispatch();

  const getAccountsHandler = async () => {
    try {
      const userId = getSkyrexUuid();

      const {
        data,
      } = await getExchangesIds(userId as string);

      const responseAccounts = data?.accounts;
      if (!responseAccounts?.length) {
        dispatch(setExchanges([]));
        return;
      }

      if (id) {
        const botInfoResponse = await getBotInfo({
          botUuid: id,
        });
  
        if (!botInfoResponse?.length) {
          return;
        }
  
        const [bot] = botInfoResponse;
        dispatch(botActions.setCurrentBot(bot));
  
        const exchangeAccount = responseAccounts.find((account: ExchangeAccount) => account.exchangeAccountUuid === bot.exchangeAccountUuid);
        const convertedExchange = convertExchange(exchangeAccount);
        
        dispatch(chooseAccountActions.setExchanges([exchangeAccount]));
        dispatch(chooseAccountActions.selectExchange(convertedExchange));
        return;
      }

      const accounts = getDemoAccount(responseAccounts, isDemoMode);

      const accountBalances = await getExchanges(responseAccounts.map((account: any) => account.exchangeAccountUuid));

      const resultAccounts = accounts.map((account: any) => {
        const totalUsdt = +(accountBalances?.data?.accounts?.find((accountBalance: any) => accountBalance?.exchangeAccountUuid === account.exchangeAccountUuid)?.totalUsdt || 0);
        account.totalUsdt = totalUsdt;

        return account;
      });

      dispatch(chooseAccountActions.setExchanges(accounts?.length ? resultAccounts : []));
      
      const exchange = [...resultAccounts]?.sort((a: any, b: any) => +b.totalUsdt - +a.totalUsdt)?.[0] || accounts?.[0];
      dispatch(chooseAccountActions.selectExchange(convertExchange(exchange)));
    } catch (error) {
      console.log('error', error);
    }
  };

  useEffect(() => {
    getAccountsHandler();
  }, [isDemoMode, id]);
};
