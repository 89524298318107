import { ExchangeName } from 'shared/consts/available-exchanges';
import { Colors } from 'shared/consts/colors';
import { formatValueListItems } from 'shared/helpers/format-value-list-items';

interface TooltipProps {
  formatters: Record<string, any>;
  exchange: ExchangeName;
  quoteAsset: string;
  isMainChart?: boolean;
  isCopyBot?: boolean;
}

export const getTooltip = ({
  formatters,
  exchange,
  quoteAsset,
  isMainChart,
  isCopyBot,
}: TooltipProps) => {
  const btc = quoteAsset === 'BTC';

  const getValueColor = (params: any) => {
    if (isMainChart) {
      return `${btc ? Colors.BLUE : Colors.GREEN}`;
    }

    const values = params[0].data[1];


    if (values > 0) {
      return Colors.GREEN;
    }
    if (values < 0) {
      return Colors.RED;
    }

    return Colors.GREY;
  };
  
  const getChartValue = (params: any) => {
    const value = +params[0].data[1];
    
    if (isMainChart) {
      return formatValueListItems({
        value,
        currency: quoteAsset,
        formatters,
        exchange: exchange as ExchangeName,
      });
    }

    const formattedValue = formatValueListItems({
      value,
      currency: quoteAsset,
      formatters,
      exchange: exchange as ExchangeName,
      skipQuote: isCopyBot,
    });
    return `${formattedValue}${isCopyBot ? '%' : ''}`;
  };

  const tooltip: echarts.TooltipComponentOption = {
    trigger: 'axis',
    padding: 0,
    position: function (point, _, __, ___, size) {
      const x = point[0];
      const y = point[1];
      const tooltipWidth = size.contentSize[0];
      const chartWidth = size.viewSize[0];

      if (x + tooltipWidth > chartWidth) {
        return [x - (tooltipWidth - 30), y];
      }

      if (x < tooltipWidth / 2) {
        return [x + 10, y];
      }

      return [x, y];
    },
    formatter: (params: any) => {
      return `
      <div style='display: flex; flex-direction: column; align-items: center; gap: 4px; background-color: #fff; padding: 14px 25px; font-family: Poppins;'>
        <h3 style='fontSize: 12px; font-weight: 400; color: #3A3A3C; margin: 0;'>
                ${params[0].data[0]}
        </h3>

        <strong style='color: ${getValueColor(params)}; font-size: 14px; font-weight: 600;'>
            ${getChartValue(params)}
        </strong>
      </div>
    `;
    },
  };

  return tooltip;
}; 
