import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { DEFAULT_SYMBOL } from '../helpers/form-hook-helpers';
import { formatByPrecisionAndTrim } from '../helpers/helpers';

export const useTradingForm = (
  chartLastPrice: number,
  currentSymbol: any,
  userWalletData: any,
  userWalletBaseAssetBalance: any,
  editingTrade: any,
) => {
  const [isPriceUpdatePending, setIsPriceUpdatePending] = useState(false);
  
  const methods = useForm({
    mode: 'onChange',
    defaultValues: {
      selectedSide: 'buy',
      skipBaseOrder: false,
      currentSymbol: {
        ...DEFAULT_SYMBOL,
        ...currentSymbol,
      },
      baseAsset: currentSymbol?.baseAsset,
      quoteAsset: currentSymbol?.quoteAsset,
      orderPrice: editingTrade ? editingTrade.orderPrice : '',
      userWalletQuoteAsset: userWalletData,
      orderType: 'limit',
      conditionalOrderType: 'limit',
      units: '',
      total: '',
      slider: 10,
      boughtPrice: '',
      triggerPrice: '',
      triggerPriceType: 'last',
      additionalOrderPrice: '',
      additionalOrderPricePercent: '',
      additionalTriggerPricePercent: '',
      additionalSlider: 10,
      additionalUnits: '',
      additionalTotal: '',
      additionalOrderType: 'limit',
      additionalTriggerPriceType: 'last',
      additionalEntries: [],
      takeProfitEntries: [],
      stopLossPriceRecalculation: 'fixed',
      takeProfitPriceRecalculation: 'fixed',
      takeProfitOrderPrice: '',
      takeProfitTriggerPrice: '',
      takeProfitOrderType: 'limit',
      takeProfitOrderPricePercent: '',  
      takeProfitTriggerPricePercent: '',
      takeProfitTriggerPriceType: 'last',
      takeProfitSlider: 100,
      stopLossTriggerPrice: '',
      stopLossOrderPrice: '',
      userWalletBaseAsset: {},
      stopLossOrderPricePercent: '',
      stopLossTriggerPricePercent: '',
      stopLossOrderType: 'cond.limit',
      stopLossTriggerPriceType: 'last',
      conditionalSegment: '',
      additionalEntryDuplicatePriceError: '',
      takeProfitDuplicatePriceError: '',
      additionalTriggerPrice: '',
      addEntryEnabled: false,
      takeProfitEnabled: false,
      stopLossEnabled: false,
      ...(editingTrade || {}),
    },
  });

  const {
    watch, setValue, trigger,
  } = methods;
  
  useEffect(() => {
    setValue('userWalletQuoteAsset', userWalletData);
  }, [userWalletData, setValue]);
  
  const selectedSide = watch('selectedSide');
  
  const isFirstRender = useRef(true);
  const prevSymbolRef = useRef(currentSymbol?.symbol);
  const prevSideRef = useRef(selectedSide);

  const prevChartPriceRef = useRef(chartLastPrice);
  
  useEffect(() => {
    if (editingTrade) {
  
      // Устанавливаем значения из editingTrade
      setValue('orderPrice', formatByPrecisionAndTrim(editingTrade.orderPrice.toString(), currentSymbol?.quoteAssetPrecision, currentSymbol?.priceMin, currentSymbol?.priceMax));
      setValue('units', formatByPrecisionAndTrim(editingTrade.units.toString(), currentSymbol?.baseAssetPrecision, currentSymbol?.lotMin, currentSymbol?.lotMax));
      setValue('total', formatByPrecisionAndTrim(editingTrade.total.toString(), currentSymbol?.quoteAssetPrecision, currentSymbol?.minNotional, currentSymbol?.maxNotional));
      setValue('slider', editingTrade.slider);
      setValue('selectedSide', editingTrade.selectedSide);
  
      if (editingTrade.addEntryEnabled) {
        setValue('addEntryEnabled', editingTrade.addEntryEnabled);
        setValue('additionalEntries', editingTrade.additionalEntries);
      }
  
      if (editingTrade.takeProfitEnabled) {
        setValue('takeProfitEnabled', editingTrade.takeProfitEnabled);
        setValue('takeProfitEntries', editingTrade.takeProfitEntries);
      }
      
      if (editingTrade.stopLossEnabled) {
        setValue('stopLossEnabled', editingTrade.stopLossEnabled);
        setValue('stopLossOrderPrice', formatByPrecisionAndTrim(editingTrade.stopLossOrderPrice.toString(), currentSymbol?.quoteAssetPrecision, currentSymbol?.priceMin, currentSymbol?.priceMax));
        setValue('stopLossTriggerPrice', formatByPrecisionAndTrim(editingTrade.stopLossTriggerPrice.toString(), currentSymbol?.quoteAssetPrecision, currentSymbol?.priceMin, currentSymbol?.priceMax));
        setValue('stopLossOrderPricePercent', editingTrade.stopLossOrderPricePercent);
        setValue('stopLossTriggerPricePercent', editingTrade.stopLossTriggerPricePercent);
        setValue('stopLossTriggerPriceType', editingTrade.stopLossTriggerPriceType);
        setValue('stopLossOrderType', editingTrade.stopLossOrderType);
        
        setValue('stopLossOrderUuid', editingTrade.stopLossOrderUuid);
        setValue('initialStopLossOrderPrice', editingTrade.stopLossOrderPrice);
        setValue('initialStopLossTriggerPrice', editingTrade.stopLossTriggerPrice);
        setValue('initialStopLossOrderPricePercent', editingTrade.stopLossOrderPricePercent);
        setValue('initialStopLossTriggerPricePercent', editingTrade.stopLossTriggerPricePercent);
        setValue('initialStopLossTriggerPriceType', editingTrade.stopLossTriggerPriceType);
        setValue('initialStopLossOrderType', editingTrade.stopLossOrderType); 
      }
      
      // Сохраняем те же значения как изначальные
      setValue('initialOrderPrice', editingTrade.orderPrice);
      setValue('initialUnits', editingTrade.units);
      setValue('initialTotal', editingTrade.total);
      setValue('initialSlider', editingTrade.slider);
      setValue('initialSelectedSide', editingTrade.selectedSide);
      setValue('initialCurrentSymbol', editingTrade.currentSymbol);
      setValue('initialAdditionalEntries', editingTrade.additionalEntries || []);
      setValue('initialTakeProfitEntries', editingTrade.takeProfitEntries || []);
      setValue('initialAddEntryEnabled', editingTrade.addEntryEnabled);
      setValue('initialTakeProfitEnabled', editingTrade.takeProfitEnabled);
      setValue('initialStopLossEnabled', editingTrade.stopLossEnabled);
      setValue('baseOrderUuid', editingTrade.baseOrderUuid);
      // Устанавливаем UUID для редактирования
      setValue('smartTradeUuid', editingTrade.smartTradeUuid);
      // Установите другие значения из editingTrade, если необходимо
      return; // Выходим из useEffect, чтобы не выполнять остальную логику
    }
    
    const updateDefaultValues = () => {
      const price = chartLastPrice || 0; 
      const additionalMultiplier = selectedSide === 'buy' ? 0.95 : 1.05;
      
      const precision = currentSymbol?.quoteAssetPrecision || 8;
      const priceMin = Number(currentSymbol?.priceMin || 0);
      const priceMax = Number(currentSymbol?.priceMax || 999999999);

      const initialTotal = parseFloat(userWalletData.free) * 0.1;
      const initialUnits = initialTotal / price;
      const formattedUnits = formatByPrecisionAndTrim(
        initialUnits.toString(),
        currentSymbol.baseAssetPrecision,
        Number(currentSymbol.lotMin),
        Number(currentSymbol.lotMax),
      );
      
      setValue('units', formattedUnits);
      
      const recalculatedTotal = parseFloat(formattedUnits) * price;
      const formattedTotal = formatByPrecisionAndTrim(
        recalculatedTotal.toString(),
        currentSymbol.quoteAssetPrecision,
        Number(currentSymbol.minNotional),
        Number(currentSymbol.maxNotional),
      );
      
      setValue('total', formattedTotal);
      
      setValue('additionalOrderPrice', formatByPrecisionAndTrim(
        (price * additionalMultiplier).toString(),
        precision,
        priceMin,
        priceMax,
      ));
      
      // Для take profit - противоположная логика относительно выбранной стороны
      const takeProfitMultiplier = selectedSide === 'buy' ? 1.1 : 0.9;

      setValue('takeProfitOrderPrice', formatByPrecisionAndTrim(
        (price * takeProfitMultiplier).toString(),
        precision,
        priceMin,
        priceMax,
      ));

      setValue('takeProfitTriggerPrice', formatByPrecisionAndTrim(
        (price * takeProfitMultiplier).toString(),
        precision,
        priceMin,
        priceMax,
      ));
      
      // Для stop loss - противоположная логика относительно take profit
      const stopLossMultiplier = selectedSide === 'buy' ? 0.9 : 1.1;

      setValue('stopLossOrderPrice', formatByPrecisionAndTrim(
        (price * stopLossMultiplier).toString(),
        precision,
        priceMin,
        priceMax,
      ));
      
      setValue('stopLossTriggerPrice', formatByPrecisionAndTrim(
        (price * stopLossMultiplier).toString(),
        precision,
        priceMin,
        priceMax,
      ));
      
      setValue('currentSymbol', currentSymbol);
      setValue('additionalEntries', []);
      setValue('takeProfitEntries', []);
      setValue('addEntryEnabled', false);
      setValue('takeProfitEnabled', false);
      setValue('stopLossEnabled', false);
      
    };
    
    //@TODO устанавливаем дефолты для формы, если изменились символ или сторона
    const isSymbolChanged = prevSymbolRef.current !== currentSymbol?.symbol;
    const isSideChanged = prevSideRef.current !== selectedSide;
    const isValidPrice = chartLastPrice > 0;

    if (isSymbolChanged) {
      setIsPriceUpdatePending(true);
      const timer = setTimeout(() => {
        setIsPriceUpdatePending(false);
        
        if (isValidPrice) {
          setValue('currentSymbol', {
            ...DEFAULT_SYMBOL,
            ...currentSymbol,
          });
          
          if (!editingTrade) {
            setValue('orderPrice', formatByPrecisionAndTrim(chartLastPrice.toString(), currentSymbol?.quoteAssetPrecision || 8, currentSymbol?.priceMin || 0, currentSymbol?.priceMax || 999999999));
            setValue('triggerPrice', formatByPrecisionAndTrim(chartLastPrice.toString(), currentSymbol?.quoteAssetPrecision || 8, currentSymbol?.priceMin || 0, currentSymbol?.priceMax || 999999999));
          }
  
          updateDefaultValues();
          
          
          trigger();
          
          
          prevSymbolRef.current = currentSymbol?.symbol;
          prevSideRef.current = selectedSide;
          prevChartPriceRef.current = chartLastPrice;
          isFirstRender.current = false;
        }
      }, 1000);
  
      return () => clearTimeout(timer);
    } 
    
    
    if ((isFirstRender.current || isSymbolChanged || isSideChanged) && isValidPrice) {
      debugger;
      
      setValue('currentSymbol', {
        ...DEFAULT_SYMBOL,
        ...currentSymbol,
      });
      
      if (!editingTrade) {
        setValue('orderPrice', formatByPrecisionAndTrim(chartLastPrice.toString(), currentSymbol?.quoteAssetPrecision || 8, currentSymbol?.priceMin || 0, currentSymbol?.priceMax || 999999999));
        setValue('triggerPrice', formatByPrecisionAndTrim(chartLastPrice.toString(), currentSymbol?.quoteAssetPrecision || 8, currentSymbol?.priceMin || 0, currentSymbol?.priceMax || 999999999));
      }

      setValue('userWalletQuoteAsset', userWalletData);

      updateDefaultValues();
      
      trigger();
      
      prevSymbolRef.current = currentSymbol?.symbol;
      prevSideRef.current = selectedSide;
      prevChartPriceRef.current = chartLastPrice;
      isFirstRender.current = false;
    }
    
    
    
    if (!isPriceUpdatePending) {
      prevSymbolRef.current = currentSymbol?.symbol;
      prevSideRef.current = selectedSide;
      if (isValidPrice) {
        prevChartPriceRef.current = chartLastPrice;
      }
      isFirstRender.current = false;
      return;
    }
    
    return;
  }, [
    userWalletBaseAssetBalance?.free,
    userWalletData,
    currentSymbol,
    trigger,
    chartLastPrice,
    currentSymbol?.symbol,
    selectedSide,
    currentSymbol?.quoteAssetPrecision,
    currentSymbol?.priceMin,
    currentSymbol?.priceMax,
    setValue,
    isPriceUpdatePending,
  ]);
  
  return methods;
};
