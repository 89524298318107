import { CSSProperties } from 'react';

export const TITLE_WRAPPER: CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'baseline',
  gap: '8px',
  cursor: 'pointer',
  // '&>svg': {
  //   width: '100%',
  //   maxWidth: '12px',
  // },
};
