import createAxiosInstance from 'shared/api/axios/axios';
import { HttpMethod } from 'shared/api/axios/types/http-method';
import { getSkyrexUuid } from 'shared/helpers/storage-helper';

const axios = createAxiosInstance({
  method: HttpMethod.Post,
});

export const createShareCode = async (referralCode: string) => {
  const response = await axios.request({
    url: '/referral/createCode',
    data: {
      skyrexUserUuid: getSkyrexUuid()!,
      referralCode,
    },
  });

  return response.data?.data;
};
