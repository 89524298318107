import { Bot } from '../types/bots.types';

export const combineBots = (bots: Bot[]): {[key: string]: Bot[];} => {
  return bots.reduce((acc: any ,next) => {
    const key = `${next.itemName}_${next.quote}_${next.skyrexUserUuid}`;
    if (acc[key]) {
      return {
        ...acc,
        [key]: [...acc[key], next],
      };
    }
  
    return {
      ...acc,
      [key]: [next],
    };
  }, {});
};
