import { SxProps, Theme } from '@mui/material';

export const WRAPPER: SxProps<Theme> = {
  backgroundColor: 'rgba(46, 205, 153, 0.03)',
  borderRadius: '4px',
  padding: '26px',
  gap: '45px',
  flex: 1,
  width: '100%',
  flexDirection: 'column',
  maxWidth: {
    xs: '100%',
    md: 747,
  },
};
