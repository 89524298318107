import { terminalActions } from 'entities/terminal/model/slices/terminal-slice';
import { Bar, ResolutionString } from '../../charting_library/datafeed-api';
import { WebSocketHandlerParams, WebSocketHandler } from '../types/chart-view.types';
import { resolutionToExchangeInterval } from '../utils/chart-utils';
import { createBaseWebSocketHandler } from './websocket-utils';

interface KucoinKline {
  time: number;      // Время начала периода
  open: string;      // Цена открытия
  close: string;     // Цена закрытия
  high: string;      // Максимальная цена
  low: string;       // Минимальная цена
  volume: string;    // Объем
  turnover: string;  // Оборот
}

interface KucoinMessage {
  type: string;
  topic: string;
  subject: string;
  data: KucoinKline;
  id?: string;
}

export const createKucoinHandler = (params: WebSocketHandlerParams): WebSocketHandler => {
  const {
    symbolInfo,
    resolution,
    dispatch,
    onTick,
  } = params;

  let ws: WebSocket | null = null;
  let pingInterval: NodeJS.Timeout | null = null;

  const handleMessage = (event: MessageEvent) => {
    try {
      const message = JSON.parse(event.data) as KucoinMessage;
      console.log('Received Kucoin message:', message);

      // Обработка пинга
      if (message.type === 'pong') {
        return;
      }

      // Обработка подтверждения подписки
      if (message.type === 'ack') {
        console.log('Successfully subscribed to Kucoin channel:', message);
        return;
      }

      // Обработка данных свечи
      if (message.subject === 'trade.klines.update') {
        const kline = message.data;
        
        if (!isValidKline(kline)) {
          console.warn('Получены неполные данные свечи Kucoin:', kline);
          return;
        }

        const bar: Bar = createBar(kline);
        console.log('Created bar:', bar);
        dispatch(terminalActions.setLimitLastPrice(bar.close));
        onTick(bar);
      }
    } catch (error) {
      console.error('Ошибка обработки сообщения Kucoin:', error);
    }
  };

  const isValidKline = (kline: KucoinKline): boolean => {
    return Boolean(
      kline.time &&
      kline.open &&
      kline.close &&
      kline.high &&
      kline.low &&
      kline.volume,
    );
  };

  const createBar = (kline: KucoinKline): Bar => ({
    time: kline.time,
    open: parseFloat(kline.open),
    high: parseFloat(kline.high),
    low: parseFloat(kline.low),
    close: parseFloat(kline.close),
    volume: parseFloat(kline.volume),
  });

  const getSubscriptionMessage = () => {
    const interval = resolutionToExchangeInterval(resolution as ResolutionString, 'kucoin');
    const symbol = symbolInfo.full_name;

    return {
      type: 'subscribe',
      topic: `/market/candles:${symbol}_${interval}`,
      privateChannel: false,
      response: true,
    };
  };

  const startPingPong = (ws: WebSocket) => {
    if (pingInterval) {
      clearInterval(pingInterval);
    }

    pingInterval = setInterval(() => {
      if (ws.readyState === WebSocket.OPEN) {
        ws.send(JSON.stringify({
          type: 'ping',
        }));
      }
    }, 20000); // Пинг каждые 20 секунд
  };

  const handleOpen = (ws: WebSocket) => {
    console.log('Connecting to Kucoin WebSocket...');
    
    const subscriptionMsg = getSubscriptionMessage();
    console.log('Sending subscription message:', subscriptionMsg);
    ws.send(JSON.stringify(subscriptionMsg));
    
    startPingPong(ws);
  };

  return {
    connect: () => {
      ws = createBaseWebSocketHandler(
        'wss://ws-api.kucoin.com/endpoint', // Базовый URL для WebSocket KuCoin
        handleMessage,
        null,
        handleOpen,
      );
      return ws;
    },
    disconnect: () => {
      if (ws && ws.readyState === WebSocket.OPEN) {
        const interval = resolutionToExchangeInterval(resolution as ResolutionString, 'kucoin');
        const symbol = symbolInfo.full_name;

        ws.send(JSON.stringify({
          type: 'unsubscribe',
          topic: `/market/candles:${symbol}_${interval}`,
          privateChannel: false,
          response: true,
        }));
      }

      if (pingInterval) {
        clearInterval(pingInterval);
        pingInterval = null;
      }

      if (ws) {
        ws.close();
        ws = null;
      }
    },
  };
};
