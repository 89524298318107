import { Box } from '@mui/material';
import { ConfigProvider, Slider } from 'antd';
import { CurrentSymbol } from 'entities/terminal/model/types/terminal-schema';
import { getColorByReturnPercent, showProgressOnBar } from 'pages/manual-trading/trading-terminal/helpers/active-trades-utils';
import { formatByPrecisionAndTrim } from 'pages/manual-trading/trading-terminal/helpers/helpers';
import { SmartTrade } from 'pages/manual-trading/trading-terminal/types/smart-trade.types';
import React, { useEffect, useState } from 'react';
import { Text } from 'shared/ui';
import { useTradeStatuses } from '../../hooks/use-trade-statuses';
import { getSliderMarks, getSliderValues } from '../active-trade/helpers/trade-progress-helpers';
import ProgressMark from '../progress-mark/progress-mark';

interface TradeProgressProps {
  trade: SmartTrade;
  tradeSymbol: CurrentSymbol;
  onLoadingStateChange?: (isLoading: boolean) => void;
}

export const TradeProgress: React.FC<TradeProgressProps> = ({
  trade, tradeSymbol, onLoadingStateChange, 
}) => {
  const statuses = useTradeStatuses();

  const [sliderValue, setSliderValue] = useState(() => getSliderValues(trade, showProgressOnBar(trade)));

  const {
    marks, lowest, highest,
  } = getSliderMarks(trade);

  const sliderMarks = Object.entries(marks).reduce<Record<string, React.ReactNode>>((acc, [key, value]) => {
    acc[key] = (
      <ProgressMark
        label={value.label}
        value={+formatByPrecisionAndTrim(value.value.toString(), tradeSymbol.chartPrecision, Number(tradeSymbol.priceMin), Number(tradeSymbol.priceMax))}
        distance={value.distance}
        visible={value.visible}
        isExchangePrice={value.isExchangePrice}
        isEntryPrice={value.isEntryPrice}
        isHighestPrice={value.isHighestPrice}
        isLowestPrice={value.isLowestPrice}
        highestPrice={value.highestPrice}
        lowestPrice={value.lowestPrice}
      />
    );
    return acc;
  }, {});
  
  useEffect(() => {
    setSliderValue(getSliderValues(trade, showProgressOnBar(trade)));
  }, [trade]);
  
  useEffect(() => {
    const isLoading = statuses.loadingStatuses.includes(trade.status);
    onLoadingStateChange?.(isLoading);
  }, [trade.status, onLoadingStateChange, statuses.loadingStatuses]);

  if (statuses.cancelledStatuses.includes(trade.status)) {
    return (
      <Box sx={{
        width: '200px', 
      }}
      >
        <Text type='secondary'>Trade is cancelled</Text>
      </Box>
    );
  }

  if (statuses.sliderStatuses.includes(trade.status)) {

    const trackColor = getColorByReturnPercent(trade.returnPercent || '');

    return (
      <Box>
        <div
          style={{
            minWidth: 184,
          }}
        >
          <ConfigProvider
            theme={{
              components: {
                Slider: {
                  handleSize: -1,
                  handleSizeHover: 0,
                  handleLineWidth: 0,
                  handleLineWidthHover: 0,
                  dotSize: 5,
                  dotBorderColor: 'rgba(0,0,0,0)',
                  dotActiveBorderColor: 'rgba(0,0,0,0)',
                  railSize: 9,
                  trackBg: trackColor,
                  trackBgDisabled: trackColor,
                },
              },
            }}
          >
            <Slider
              className='my-slider'
              style={{
                cursor: 'default', 
              }}
              range
              min={lowest}
              max={highest}
              tooltip={{
                open: false, 
              }}
              marks={sliderMarks}
              value={sliderValue}
              disabled={true}
            />
          </ConfigProvider>
        </div>
      </Box>
    );
  }

  return null;
};
