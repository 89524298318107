import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IRiskManagmentAdditional } from '../interfaces';

const initialState: IRiskManagmentAdditional = {
  isOpened: true,
  segment: {
    value: 'preset',
    preset: {
      orders: 1,
      priceChange: -3,
      ordersSizeScale: 1,
      ordersPriceChangeScale: 1,
      orderType: 'market',
    },
    alert: {
      orders: 1,
      ordersSizeScale: 1,
      orderType: 'market',
    },
  },
};

export const riskManagmentAdditionalSlice = createSlice({
  name: 'risk-managment-additional',
  initialState,
  reducers: {
    init: (state, action: PayloadAction<IRiskManagmentAdditional>) => {
      const {
        isOpened,
        segment,
      } = action.payload;

      const {
        value,
        preset,
        alert,
      } = segment;

      state.isOpened = isOpened;
      state.segment = segment;
      state.segment.value = value;
      state.segment.preset = preset;
      state.segment.alert = alert;
    },
    toggle: (state, action: PayloadAction<IRiskManagmentAdditional['isOpened']>) => {
      state.isOpened = action.payload;
    },
    setError: (state, action: PayloadAction<IRiskManagmentAdditional['error']>) => {
      state.error = action.payload;
    },
    changeSegment: (state, action: PayloadAction<IRiskManagmentAdditional['segment']['value']>) => {
      state.segment.value = action.payload;
    },
    setPresetOrderType: (state, action: PayloadAction<IRiskManagmentAdditional['segment']['preset']['orderType']>) => {
      state.segment.preset.orderType = action.payload;
    },
    setPresetOrders: (state, action: PayloadAction<IRiskManagmentAdditional['segment']['preset']['orders']>) => {
      state.segment.preset.orders = action.payload;
    },
    setPresetPriceChange: (state, action: PayloadAction<IRiskManagmentAdditional['segment']['preset']['priceChange']>) => {
      state.segment.preset.priceChange = action.payload;
    },
    setPresetOrdersSizeScale: (state, action: PayloadAction<IRiskManagmentAdditional['segment']['preset']['ordersSizeScale']>) => {
      state.segment.preset.ordersSizeScale = action.payload;
    },
    setPresetOrdersPriceChangeScale: (state, action: PayloadAction<IRiskManagmentAdditional['segment']['preset']['ordersPriceChangeScale']>) => {
      state.segment.preset.ordersPriceChangeScale = action.payload;
    },
    setAlertOrderType: (state, action: PayloadAction<IRiskManagmentAdditional['segment']['alert']['orderType']>) => {
      state.segment.alert.orderType = action.payload;
    },
    setAlertOrders: (state, action: PayloadAction<IRiskManagmentAdditional['segment']['alert']['orders']>) => {
      state.segment.alert.orders = action.payload;
    },
    setAlertOrdersSizeScale: (state, action: PayloadAction<IRiskManagmentAdditional['segment']['alert']['ordersSizeScale']>) => {
      state.segment.alert.ordersSizeScale = action.payload;
    },
  },
});

export const {
  actions: riskManagmentAdditionalActions,
} = riskManagmentAdditionalSlice;
export const {
  reducer: riskManagmentAdditionalReducer,
} = riskManagmentAdditionalSlice;
