import { SmartTrade } from 'entities/new-terminal/model/types/new-terminal-schema';
import { CurrentSymbol } from 'entities/terminal/model/types/terminal-schema';
import { formatByPrecisionAndTrim, roundPercentage } from 'pages/manual-trading/trading-terminal/helpers/helpers';
import { Order } from 'pages/manual-trading/trading-terminal/types/smart-trade.types';
import { reverseTypeMapping } from 'shared/helpers/order-type-mappings';

interface ExchangeAccount {
    balances: {
        symbol: string;
        free: string;
    }[];
}

export const mapTradeToFormValues = (
  trade: SmartTrade, 
  exchangeAccount: ExchangeAccount, 
  tradeSymbol: CurrentSymbol,
) => {

  const userWalletQuoteAssetBalance = exchangeAccount.balances.find(
    balance => balance.symbol === tradeSymbol.quoteAsset,
  );
  
  const baseOrder = getBaseOrder(trade);
  const {
    orderPrice, units, total, 
  } = calculateBaseOrderValues(baseOrder, tradeSymbol);

  const slider = calculateSlider(total.toString(), userWalletQuoteAssetBalance?.free || '0');
    
  const additionalEntries = mapAdditionalEntries(trade.orders, tradeSymbol);
  const addEntryEnabled = additionalEntries.length > 0;
    
  const takeProfitEntries = mapTakeProfitEntries(trade.orders, tradeSymbol);
  const takeProfitEnabled = takeProfitEntries.length > 0;
    
  const stopLossValues = mapStopLossValues(trade.orders, orderPrice, tradeSymbol);
    
  return {
    orderPrice,
    units,
    total,
    slider: roundPercentage(slider),
    selectedSide: trade.sideFirstStep.toLowerCase(),
    currentSymbol: {
      symbol: `${trade.baseSymbol}${trade.quoteSymbol}`,
      baseAsset: trade.baseSymbol,
      quoteAsset: trade.quoteSymbol,
    },
    additionalEntries,
    takeProfitEntries,
    ...stopLossValues.stopLoss,
    stopLossOrderUuid: stopLossValues.stopLossOrderUuid,
    addEntryEnabled,
    takeProfitEnabled,
    baseOrderUuid: baseOrder.orderUuid,
    stopLossEnabled: stopLossValues.stopLossEnabled,
    smartTradeUuid: trade.smartTradeUuid,
  };
};
  
const getBaseOrder = (trade: SmartTrade) => {
  const baseOrder = trade.orders.find(order => order.viewType === 'base_order');
  if (!baseOrder) {
    throw new Error('Base order not found');
  }
  return baseOrder;
};
  
const calculateBaseOrderValues = (baseOrder: Order, tradeSymbol: CurrentSymbol) => {
  let orderPrice, units, total;
  
  if (baseOrder.status === 'active') {
    orderPrice = formatByPrecisionAndTrim(baseOrder.limitPrice, tradeSymbol.quoteAssetPrecision, parseFloat(tradeSymbol.priceMin), parseFloat(tradeSymbol.priceMax));
    units = formatByPrecisionAndTrim(baseOrder.originalBase, tradeSymbol.baseAssetPrecision, parseFloat(tradeSymbol.lotMin), parseFloat(tradeSymbol.lotMax));
    total = formatByPrecisionAndTrim((parseFloat(orderPrice) * parseFloat(units)).toString(), tradeSymbol.quoteAssetPrecision, parseFloat(tradeSymbol.minNotional), parseFloat(tradeSymbol.maxNotional));
  } else if (baseOrder.status === 'completed') {
    orderPrice = formatByPrecisionAndTrim(baseOrder.executionPrice, tradeSymbol.quoteAssetPrecision, parseFloat(tradeSymbol.priceMin), parseFloat(tradeSymbol.priceMax));
    units = formatByPrecisionAndTrim(baseOrder.executionBase || '0', tradeSymbol.baseAssetPrecision, parseFloat(tradeSymbol.lotMin), parseFloat(tradeSymbol.lotMax));
    total = formatByPrecisionAndTrim(baseOrder.executionQuote, tradeSymbol.quoteAssetPrecision, parseFloat(tradeSymbol.minNotional), parseFloat(tradeSymbol.maxNotional));
  } else {
    orderPrice = formatByPrecisionAndTrim(baseOrder.executionPrice || baseOrder.limitPrice, tradeSymbol.quoteAssetPrecision, parseFloat(tradeSymbol.priceMin), parseFloat(tradeSymbol.priceMax));
    units = formatByPrecisionAndTrim(baseOrder.originalBase, tradeSymbol.baseAssetPrecision, parseFloat(tradeSymbol.lotMin), parseFloat(tradeSymbol.lotMax));
    total = formatByPrecisionAndTrim((parseFloat(orderPrice) * parseFloat(units)).toString(), tradeSymbol.quoteAssetPrecision, parseFloat(tradeSymbol.minNotional), parseFloat(tradeSymbol.maxNotional));
  }
  
  return {
    orderPrice, units, total, 
  };
};
  
const calculateSlider = (total: string, freeAmount: string) => {
  return (parseFloat(total) / parseFloat(freeAmount)) * 100;
};
  
const mapAdditionalEntries = (orders: Order[], tradeSymbol: CurrentSymbol) => {
  return orders
    .filter(order => order.viewType === 'additional_base_order' && order.status !== 'cancelled')
    .map(el => {
      const additionalOrderType = el.orderType.toLowerCase();
      const isActiveStatus = ['new', 'await_send', 'active'].includes(el.status);
        
      const baseValues = isActiveStatus
        ? {
          total: formatByPrecisionAndTrim((parseFloat(el.originalBase) * parseFloat(el.limitPrice)).toString(), tradeSymbol.quoteAssetPrecision, parseFloat(tradeSymbol.minNotional), parseFloat(tradeSymbol.maxNotional)),
          units: formatByPrecisionAndTrim(el.originalBase!, tradeSymbol.baseAssetPrecision, parseFloat(tradeSymbol.lotMin), parseFloat(tradeSymbol.lotMax)),
        }
        : {
          total: formatByPrecisionAndTrim(el.executionQuote, tradeSymbol.quoteAssetPrecision, parseFloat(tradeSymbol.minNotional), parseFloat(tradeSymbol.maxNotional)),
          units: formatByPrecisionAndTrim(el.executionBase!, tradeSymbol.baseAssetPrecision, parseFloat(tradeSymbol.lotMin), parseFloat(tradeSymbol.lotMax)),
        };
  
      const orderTypeValues = getAdditionalEntryOrderTypeValues(el, isActiveStatus, tradeSymbol);
  
      return {
        isCompleted: el.status === 'completed',
        orderUuid: el.orderUuid,
        additionalOrderType,
        ...baseValues,
        ...orderTypeValues,
      };
    });
};
  
const getAdditionalEntryOrderTypeValues = (el: any, isActiveStatus: boolean, tradeSymbol: CurrentSymbol) => {
  const orderTypeMap = {
    CONDITIONAL_MARKET: () => ({
      price: formatByPrecisionAndTrim(isActiveStatus ? el.triggerPrice : el.executionPrice, tradeSymbol.quoteAssetPrecision, parseFloat(tradeSymbol.priceMin), parseFloat(tradeSymbol.priceMax)),
      triggerPrice: formatByPrecisionAndTrim(isActiveStatus ? el.triggerPrice : el.executionPrice, tradeSymbol.quoteAssetPrecision, parseFloat(tradeSymbol.priceMin), parseFloat(tradeSymbol.priceMax)),
      typeTriggerPrice: el.triggerPriceType,
      limitPrice: formatByPrecisionAndTrim(isActiveStatus ? el.triggerPrice : el.executionPrice, tradeSymbol.quoteAssetPrecision, parseFloat(tradeSymbol.priceMin), parseFloat(tradeSymbol.priceMax)),
    }),
    CONDITIONAL_LIMIT: () => ({
      price: formatByPrecisionAndTrim(isActiveStatus ? el.limitPrice : el.executionPrice, tradeSymbol.quoteAssetPrecision, parseFloat(tradeSymbol.priceMin), parseFloat(tradeSymbol.priceMax)),
      triggerPrice: formatByPrecisionAndTrim(el.triggerPrice, tradeSymbol.quoteAssetPrecision, parseFloat(tradeSymbol.priceMin), parseFloat(tradeSymbol.priceMax)),
      typeTriggerPrice: el.triggerPriceType,
      orderPrice: formatByPrecisionAndTrim(isActiveStatus ? el.limitPrice : el.executionPrice, tradeSymbol.quoteAssetPrecision, parseFloat(tradeSymbol.priceMin), parseFloat(tradeSymbol.priceMax)),
      limitPrice: formatByPrecisionAndTrim(isActiveStatus ? el.limitPrice : el.executionPrice, tradeSymbol.quoteAssetPrecision, parseFloat(tradeSymbol.priceMin), parseFloat(tradeSymbol.priceMax)),
    }),
    LIMIT: () => ({
      price: formatByPrecisionAndTrim(isActiveStatus ? el.limitPrice : el.executionPrice, tradeSymbol.quoteAssetPrecision, parseFloat(tradeSymbol.priceMin), parseFloat(tradeSymbol.priceMax)),
      orderPrice: formatByPrecisionAndTrim(isActiveStatus ? el.limitPrice : el.executionPrice, tradeSymbol.quoteAssetPrecision, parseFloat(tradeSymbol.priceMin), parseFloat(tradeSymbol.priceMax)),
      limitPrice: formatByPrecisionAndTrim(isActiveStatus ? el.limitPrice : el.executionPrice, tradeSymbol.quoteAssetPrecision, parseFloat(tradeSymbol.priceMin), parseFloat(tradeSymbol.priceMax)),
    }),
  };
  
  //@ts-ignore
  return orderTypeMap[el.orderType]?.() || {};
};

const mapTakeProfitEntries = (orders: any, tradeSymbol: CurrentSymbol) => {
  return orders
    .filter((order: any) => order.viewType === 'take_profit' && order.status !== 'cancelled')
    .map((el: any) => {
      const isActiveStatus = ['new', 'await_send', 'active'].includes(el.status);
        
      return {
        isCompleted: el.status === 'completed',
        orderUuid: el.orderUuid,
        takeProfitOrderType: reverseTypeMapping[el.orderType],
        price: formatByPrecisionAndTrim(isActiveStatus
          ? (el.orderType === 'LIMIT' ? el.limitPrice : el.triggerPrice)
          : el.executionPrice, tradeSymbol.quoteAssetPrecision, parseFloat(tradeSymbol.priceMin), parseFloat(tradeSymbol.priceMax)),
        orderPricePercent: 100,
        volume: roundPercentage(el.percent),
        typeTriggerPrice: el.triggerPriceType || 'last',
      };
    });
};
  
const mapStopLossValues = (orders: any, baseOrderPrice: string, tradeSymbol: CurrentSymbol) => {
  const stopLossEntry = orders.find((order: any) => order.viewType === 'stop_loss' && order.status !== 'cancelled');
  const stopLossEnabled = !!stopLossEntry;

  if (!stopLossEntry) {
    return {
      stopLoss: {}, stopLossEnabled, 
    };
  }
  
  const stopLoss = stopLossEntry.orderType === 'CONDITIONAL_LIMIT'
    ? {
      stopLossOrderType: reverseTypeMapping[stopLossEntry.orderType],
      stopLossOrderPrice: formatByPrecisionAndTrim(stopLossEntry.limitPrice, tradeSymbol.quoteAssetPrecision, parseFloat(tradeSymbol.priceMin), parseFloat(tradeSymbol.priceMax)),
      stopLossOrderPricePercent: roundPercentage(((parseFloat(stopLossEntry.limitPrice) / parseFloat(baseOrderPrice)) - 1) * 100),
      stopLossTriggerPrice: formatByPrecisionAndTrim(stopLossEntry.triggerPrice, tradeSymbol.quoteAssetPrecision, parseFloat(tradeSymbol.priceMin), parseFloat(tradeSymbol.priceMax)),
      stopLossTriggerPriceType: stopLossEntry.triggerPriceType ?? 'last',
      stopLossTriggerPricePercent: roundPercentage(((parseFloat(stopLossEntry.triggerPrice) / parseFloat(baseOrderPrice)) - 1) * 100),
    }
    : {
      stopLossOrderType: reverseTypeMapping[stopLossEntry.orderType],
      stopLossTriggerPrice: formatByPrecisionAndTrim(stopLossEntry.triggerPrice, tradeSymbol.quoteAssetPrecision, parseFloat(tradeSymbol.priceMin), parseFloat(tradeSymbol.priceMax)),
      stopLossTriggerPriceType: stopLossEntry.triggerPriceType ?? 'last',
      stopLossTriggerPricePercent: roundPercentage(((parseFloat(stopLossEntry.triggerPrice) / parseFloat(baseOrderPrice)) - 1) * 100),
    };
  
  return {
    stopLoss, 
    stopLossEnabled, 
    stopLossOrderUuid: stopLossEntry.orderUuid,
  };
};
