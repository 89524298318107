import { SxProps, Theme } from '@mui/material';

export const CARD_LIST: SxProps<Theme> = {
  flexDirection: {
    xs: 'column',
    sm: 'row',
  },
  flex: 1,
  gap: {
    xs: '8px',
    md: '18px',
  },
  alignItems: 'stretch',
  '&>div:nth-child(3)': {
    display: {
      xs: 'none',
      sm: 'block',
    },
  },
};
