import { Stack } from '@mui/material';
import { Modal } from 'antd';
import dayjs from 'dayjs';
import { Actions } from 'pages/trading-bots/configurator-v2/components';
import { getBotStatistic } from 'pages/trading-bots/my-bot/api/bot-statistic-api';
import { Fragment, useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Label, Tabs } from 'widgets';
import { delay } from 'shared/helpers/delay';
import { getCapitalizedExchangeTitle } from 'shared/helpers/get-capitalized-exchange-title';
import { BotEligible, LabelInput, MultipleSelect, Text } from 'shared/ui';
import { publishBot } from '../api';
import { IPublicBotParameters } from '../interfaces';
import { modal, modalChildren, TITLE, WRAPPER } from '../styles';

export const PublicBotParameters = (props: IPublicBotParameters) => {
  const {
    isOpen,
    closeHandler,
    bots,
    botUuids,
    setBotUuids,
    setChooseMarketModal,
    refetchBots,
  } = props;

  const [selectedBotsOrigin, setSelectedBotsOrigin] = useState<string[]>([]);
  const [selectedBots, setSelectedBots] = useState<string[]>([]);
  const [statistic, setStatistic] = useState<any>(null);

  const [amount, setAmount] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [author, setAuthor] = useState<string>('');
  const [profit, setProfit] = useState<string>('');

  const closeModalHandler = () => {
    closeHandler();
    setBotUuids?.([]);
    setChooseMarketModal(false);
  };

  const chooseBotHandler = async (value: string[]) => {
    if (!value.length) {
      setSelectedBots([]);
      return;
    }

    const id = value.at(-1);
    const bot = bots.find((bot) => bot.botUuid === id)!;

    const botsPlatforms: any[] = [];
    for (let i = 0; i < selectedBots.length; i++) {
      const currentBotId = selectedBots[i];
      const currentBot = bots.find((bot) => bot.botUuid === currentBotId)!;
      
      if (currentBot.botUuid === bot.botUuid) {
        setSelectedBots((prev) => prev.filter((selectedBot) => selectedBot !== bot.botUuid));
        return;
      }
      
      if (currentBot.exchangeCode === bot.exchangeCode) {
        botsPlatforms.push(currentBot);
        continue;
      }
    }

    if (botsPlatforms.length) {
      toast.error('Bot with the same exchange is already selected');
      return;
    }

    const response = await getBotStatistic({
      botUuid: bot.botUuid,
      dates: [
        dayjs().subtract(30, 'day').startOf('day'),
        dayjs().endOf('day'),
      ],
    });

    if (!response) {
      return;
    }

    if (response.roi >= 1 && response.totalTrades >= 10 && response.maxDrawdown >= -15 && dayjs().diff(bot.createdDate, 'day') >= 30) {
      setSelectedBots(value);
      return;
    }

    setStatistic({
      ...response,
      days: dayjs(dayjs()).diff(bot?.createdDate, 'day'),
    });
  };

  const options = useCallback(() => {
    if (!bots?.length) {
      return [];
    }

    return bots.map((bot) => {
      const option = {
        label: bot.botName || bot.name,
        value: bot.botUuid,
        placeholder: `${bot.botName || bot.name}_${bot.exchangeCode}`,
      };

      return option;
    });
  }, [bots]);

  useEffect(() => {
    if (!isOpen) {
      setAmount('');
      setName('');
      setDescription('');
      setAuthor('');
      setProfit('');
    
      setSelectedBots([]);
      setSelectedBotsOrigin([]);
      return;
    }

    if (!botUuids?.length) {
      return;
    }

    const currentBot = bots.find((bot) => bot.botUuid === botUuids[0]);
    if (!currentBot) {
      return;
    }

    setAmount(currentBot.minInvestment);
    setName(currentBot.itemName || currentBot.name || '');
    setDescription(currentBot.description || '');
    setAuthor(currentBot.author || '');
    setProfit(currentBot.copyCommissionPercent?.toString() || '');
    
    setSelectedBots(botUuids);
    setSelectedBotsOrigin(botUuids);
  }, [botUuids, bots, isOpen]);

  const publishHandler = async () => {
    const deletedBots = selectedBotsOrigin.filter((selectedBotOrigin) => !selectedBots.includes(selectedBotOrigin));
    for (let i = 0; i < deletedBots.length; i++) {
      const bot = deletedBots[i];
      
      const data: any = {
        botUuid: bot,
        minInvestment: null,
        itemName: null,
        description: null,
        author: null,
        isDeleted: true,
        copyCommissionPercent: null,
      };
      
      new Promise((resolve): any => {
        const id = publishBot(data);
        if (!id) {
          resolve(['Error', null]);
        }
        resolve([null, id]);
      });
    }

    for (let i = 0; i < selectedBots.length; i++) {
      const bot = selectedBots[i];
      
      const data: any = {
        botUuid: bot,
        minInvestment: amount,
        itemName: name,
        description,
        author,
      };

      if (profit) {
        data.copyCommissionPercent = +profit;
      }
      
      new Promise((resolve): any => {
        const id = publishBot(data);
        if (!id) {
          resolve(['Error', null]);
        }
        resolve([null, id]);
      });
    }

    await delay(700);
    refetchBots();
  };

  const optionRender = (option: any) => {
    const [name, code] = option.data.placeholder.split('_');

    return (
      <Stack
        key={option.label}
        sx={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          flex: 1,
          width: '100%',
          pr: '8px',
        }}
      >
        <Text type='success'>
          {name}
        </Text>

        <Text type='success'>
          {getCapitalizedExchangeTitle(code)}
        </Text>
      </Stack>
    );
  };

  const getActions = () => {
    if (botUuids?.length) {
      return (
        <Actions
          button={{
            title: 'Save changes',
            action: () => {
              publishHandler();
              closeModalHandler();
            },
          }}
        />
      );
    }

    return (
      <Actions
        handleBack={() => {
          closeHandler();
        }}
        button={{
          title: 'Publish',
          action: publishHandler,
        }}
      />
    );
  };

  return (
    <Fragment>
      <Modal
        open={isOpen}
        footer={null}
        style={modal}
        styles={modalChildren}
        closable={true}
        destroyOnClose={true}
        onCancel={closeModalHandler}
        width='100%'
        centered={true}
      >
        <Stack sx={WRAPPER}>
          <Text styles={TITLE}>
            Set marketplace item parameters
          </Text>

          <Stack
            sx={{
              gap: '18px',
              width: '100%',
              maxWidth: 387,
            }}
          >
            <MultipleSelect
              label={(
                <Label
                  title='Choose bots'
                />
              )}
              options={options()}
              select={{
                value: selectedBots,
                placeholder: 'Each bot adds avilable exchange to your item',
                onChange: chooseBotHandler,
              }}
              optionRender={optionRender}
            />

            <LabelInput
              label={(
                <Label
                  title='Set minimum amount'
                />
              )}
              value={amount}
              placeholder='Minimum amount to copy the bot'
              onChange={(value: string) => {
                setAmount(value.replace(/[^\d.]/g, '').replace(/(\..*)\./g, '$1'));
              }}
            />

            <LabelInput
              label={(
                <Label
                  title='Marketplace item name'
                />
              )}
              value={name}
              placeholder='Name of the bot card'
              onChange={(value: string) => {
                setName(value);
              }}
            />

            <LabelInput
              label={(
                <Label
                  title='Description (max 200 symbols)'
                />
              )}
              value={description}
              placeholder='Short description of the bot strategy'
              onChange={(value: string) => {
                if (value.length > 200) {
                  return;
                }
                
                setDescription(value);
              }}
            />

            <LabelInput
              label={(
                <Label
                  title='Author'
                />
              )}
              value={author}
              placeholder='Name of creator'
              onChange={(value: string) => {
                setAuthor(value);
              }}
            />
          </Stack>

          <Tabs
            defaultValue={profit ? 0 : 1}
            tabs={[{
              label: 'Paid',
              value: 0,
            }, {
              label: 'Free',
              value: 1,
            }]}
            getTab={(tab) => {
              if (tab === 1) {
                setProfit('');
              }
            }}
            content={[(
              <LabelInput
                label={(
                  <Label
                    title='Profit sharing, %'
                  />
                )}
                value={profit}
                placeholder='Percentage of monthly earnings shared to you'
                onChange={(value: string) => {
                  setProfit(value.replace(/[^\d]/g, ''));
                }}
              />
            ), null]}
            styles={{
              width: '100%',
              maxWidth: 387,
              justifyContent: 'center',
            }}
          />

          {getActions()}
        </Stack>
      </Modal>

      <BotEligible
        isOpened={!!statistic}
        closeHandler={() => {
          setStatistic(null);
        }}
        botStatistic={statistic}
      />
    </Fragment>
  );
};
