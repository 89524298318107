import { SxProps, Theme } from '@mui/material';

export const inner = (isSignalSource: boolean) => {
  const styles: SxProps<Theme> = {
    display: 'flex',
    flexDirection: 'column',
    gap: '38px',
    maxHeight: 500,
    overflowX: 'hidden',
    padding: {
      xs: `${isSignalSource ? '20px' : '0'} 10px 20px 25px`,
      md: `${isSignalSource ? '40px' : '0'} 20px 40px 50px`,
    },
    marginRight: {
      xs: '10px',
      md: '20px',
    },
  };

  return styles;
};
