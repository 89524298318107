import { Box, Hidden } from '@mui/material';
import { Layout } from 'antd';
import { Link } from 'react-router-dom';
import { CopyRight } from 'widgets';
import { Logo } from 'shared/icons';
import {
  layoutFooter,
  layoutInner,
  layoutWrapper,
  left,
} from '../styles';

export const Footer = () => (
  <Layout.Footer style={layoutFooter}>
    <Box sx={layoutWrapper}>
      <div className='container'>
        <Box sx={layoutInner}>
          <Box sx={left}>
            <Link to='/'>
              <Box
                display='flex'
                flexDirection='column'
                justifyContent='space-between'
                minHeight='100%'
              >
                {Logo}
              </Box>
            </Link>

            <Hidden smDown>
              <CopyRight />
            </Hidden>
          </Box>

          <Hidden smUp>
            <Box textAlign='center'>
              <CopyRight />
            </Box>
          </Hidden>
        </Box>
      </div>
    </Box>
  </Layout.Footer>
);
