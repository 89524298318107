import { Stack } from '@mui/material';
import { getAlertSource } from 'entities/choose-source';
import { Actions, TradingViewLink } from 'pages/trading-bots/configurator-v2/components';
import { getPanelContent } from 'pages/trading-bots/configurator-v2/helpers';
import { useSelector } from 'react-redux';
import { Copy, Label } from 'widgets';
import { Text } from 'shared/ui';
import { IAlertMessageProps } from '../interfaces';
import { WRAPPER } from '../styles';

export const AlertMessage = (props: IAlertMessageProps) => {
  const alertSource = useSelector(getAlertSource);

  return (
    <Stack sx={WRAPPER}>
      <Stack
        sx={{
          gap: '20px',
          maxWidth: {
            xs: '100%',
            md: 400,
          },
        }}
      >
        <Copy
          label={(
            <Label
              title='Webhook URL'
              desciption={(
                <Text>
                  Copy and paste this URL into your Alert notifications tab on {' '}
                  <TradingViewLink />
                </Text>
              )}
            />
          )}
          value='https://panel.skyrexio.com/api/v1/deal/alert'
          display='row'
        />

        <Copy
          label={(
            <Label
              title='Alert with the message'
              desciption={(
                <Text>
                  Copy and paste this alert message into your Alert on {' '}

                  <TradingViewLink />
                </Text>
              )}
            />
          )}
          value='{{strategy.order.alert_message}}'
          display='row'
        />

        <Actions
          handleBack={props.handleBack!}
          button={{
            title: 'Create bot',
            action: props.createBot!,
          }}
        />
      </Stack>
      
      {getPanelContent({
        isAlert: true,
        source: alertSource,
        url: '/videos/alert-message-strategy.mp4',
      })}
    </Stack>
  );
};
