import { Typography } from '@mui/material';
import { SmartTrade } from 'entities/new-terminal/model/types/new-terminal-schema';
import { CurrentSymbol } from 'entities/terminal/model/types/terminal-schema';
import { currencyFormatter } from 'shared/helpers/currency-formatter';
import { FundsFormWrapper } from '../actions-modal-content/components/funds-form-wrapper/funds-form-wrapper';

interface TradeActionsContentProps {
  type: string;
  tradeSymbol: CurrentSymbol;
  trade: SmartTrade;
  exchangeAccount: any;
  onClose: () => void;
}

export const getTradeActionsContent = ({
  type,
  tradeSymbol,
  trade,
  exchangeAccount,
  onClose,
}: TradeActionsContentProps) => {
  const getTitle = () => {
    switch (type) {
    case 'addFunds': return 'Add Funds';
    case 'closeByMarket': return 'Close by market?';
    case 'closeByLimit': return 'Close by limit?';
    case 'openByMarket': return 'Open by market?';
    case 'openByLimit': return 'Open by limit?';
    case 'edit': return 'Edit position';
    case 'share': return 'Share';
    case 'cancel': return 'Cancel position?';
    default: return '';
    }
  };

  const getDescription = () => {
    const price = currencyFormatter((+trade.currentExchangePrice || 0), 2);
    const quote = trade.quoteSymbol;
    const returnPercent = trade.returnPercent;
  
    switch (type) {
    case 'addFunds': return 'Add funds';
    case 'closeByMarket':
    case 'closeByLimit': 
      if (trade.sideFirstStep === 'BUY') {
        return `Current price: ${price} ${quote} \n Return: ${Number(trade.returnQuote || 0).toFixed(2)} ${quote} (${Number(returnPercent).toFixed(2)}%)`;
      }
  
      return `Current price: ${price} ${quote} \n Return: ${Number(trade.returnBase).toFixed(2)} ${trade.baseSymbol} (${Number(returnPercent).toFixed(2)}%)`;
    case 'openByMarket':
    case 'openByLimit': return `Current price: ${price} ${quote}`;
    case 'edit': return 'Edit position';
    case 'cancel': return 'Trade will be canceled on Skyrexio, while assets stay untouched on your exchange account';
    case 'share': return 'Your share link';
    default: return '';
    }
  };

  return (
    <>
      <Typography
        variant='h6'
        gutterBottom
      >
        {getTitle()}
      </Typography>
      
      {(type === 'addFunds' || type === 'reduceFunds') && (
        <FundsFormWrapper 
          isAddFunds={type === 'addFunds'}
          tradeSymbol={tradeSymbol}
          trade={trade}
          exchangeAccount={exchangeAccount}
          onClose={onClose}
        />
      )}

      <Typography
        variant='body1'
        sx={{
          my: 2, 
        }}
      >
        {getDescription()}
      </Typography>
    </>
  );
};
