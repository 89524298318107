import { Box } from '@mui/material';
import { Select, Spin } from 'antd';
import { useRef } from 'react';
import { ArrowDown } from 'shared/icons';
import { Text } from 'shared/ui';
import { ISingleSelect } from '../interfaces';
import { labelText, selectWrapper, wrapper } from '../styles';

export const SingleSelect = (props: ISingleSelect) => {
  const {
    label,
    select,
    options,
    dropdown,
    maxWidth,
    labelRender,
    showSearch,
    optionRender,
    filterSort,
    size,
    loading,
    disabled,
    onClick,
    styles,
  } = props;

  const ref = useRef<any>(null);

  const onChangeHandler = (value: string | number, option: any) => {
    if (!select.onChange) {
      return;
    }

    select.onChange(value, option);
    ref.current.blur();
  };

  const getNotFounContent = () => {
    if (loading) {
      return (
        <Box>
          <Spin size='small' />
        </Box>
      );
    }

    return null;
  };

  return (
    <Box sx={wrapper(maxWidth)}>
      {label && (
        <Text
          type='secondary'
          styles={labelText}
        >
          {label}
        </Text>
      )}

      <Select
        ref={ref}
        showSearch={showSearch}
        value={select.value}
        optionFilterProp='label'
        filterSort={filterSort}
        placeholder={select.placeholder}
        options={loading ? [] : options}
        onChange={onChangeHandler}
        variant='outlined'
        style={{
          ...selectWrapper,
          ...styles,
        }}
        suffixIcon={loading ? undefined : ArrowDown}
        dropdownRender={dropdown}
        labelRender={labelRender}
        optionRender={optionRender}
        size={size || 'middle'}
        loading={loading}
        notFoundContent={getNotFounContent()}
        disabled={disabled}
        onClick={onClick}
      />
    </Box>
  );
};
