import { SxProps, Theme } from '@mui/material';

export const titleInner: SxProps<Theme> = {
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  '&>div': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 17,
    height: 17,
    '&>svg': {
      width: '100%',
      height: '100%',
    },
  },
};
