import { Box, Stack } from '@mui/material';
import { Segmented } from 'antd';
import { ErrorMessage } from 'pages/manual-trading/trading-terminal/components/error-message';
import { formatByPrecisionAndTrim, getTrailedZeroCutted } from 'pages/manual-trading/trading-terminal/helpers/helpers';
import { Controller, useFormContext } from 'react-hook-form';
import { SecondaryInfo } from 'shared/icons';
import { Input, SingleSelect, Switch, Text, Title, Tooltip } from 'shared/ui';
import { segments } from '../consts';
import { IStopLoss } from '../interfaces';
import { header, headerTitle, priceInner, priceTitle, priceWrapper, wrapper } from '../styles';

export const StopLoss = (props: IStopLoss) => {
  const {
    setIsSkipStopLoss,
    onStopLossOrderPriceChange,
    onStopLossTriggerPriceChange,
    onStopLossOrderPricePercentChange,
  } = props;

  const {
    control,
    formState: {
      errors, 
    },
    setValue,
    trigger,
    watch,
  } = useFormContext();
  
  const currentSymbol = watch('currentSymbol');
  const isStopLossEnabled = watch('stopLossEnabled');
  const stopLossTriggerPricePercent = watch('stopLossTriggerPricePercent');
  const quoteAsset = watch('quoteAsset');
  const units = watch('units');
  
  
  const stopLossOrderType = watch('stopLossOrderType');
  
  const changeSegmentHandler = (value: string) => {
    setValue('stopLossOrderType', value as 'cond.limit' | 'cond.market');
  };
  
  const stopLossPriceRecalculation = watch('stopLossPriceRecalculation');
  const changePriceRecalculationHandler = (value: boolean) => {
    setValue('stopLossPriceRecalculation', value ? 'average' : 'fixed');
  };

  const renderOrderPriceField = () => {
    if (stopLossOrderType === 'cond.market') {
      return null;
    }

    return (
      <Stack
        gap={0.5}
        height={81}
      >
        <Box sx={priceWrapper}>
          <Text
            type='secondary'
            styles={priceTitle}
          >
            {'Order price'}
          </Text>

          <Box sx={priceInner}>
          
            <Controller
              name='stopLossOrderPrice'
              control={control}
              rules={{
                required: 'This field is required', 
                validate: (value) => {
                  const numValue = Number(value);
                  if (isNaN(numValue)) return 'Value should be a number';
                  if (numValue < Number(currentSymbol.priceMin)) return `Minimum value is ${getTrailedZeroCutted(currentSymbol.priceMin)}`;
                  if (numValue > Number(currentSymbol.priceMax)) return `Maximum value is ${getTrailedZeroCutted(currentSymbol.priceMax)}`;
                  if (stopLossOrderType === 'cond.limit' && numValue * units <= Number(currentSymbol.minNotional)) {
                    return 'Order volume is less than minimal';
                  }
                  return true;
                },
              }}
              render={({
                field, 
              }) => (
                <Input
                  value={field.value}
                  onChange={(value) => {
                    if (/^[0-9]*\.?[0-9]*$/.test(value) || value === '') {
                      field.onChange(value);
                      onStopLossOrderPriceChange(value);
                    }
                    trigger('stopLossOrderPrice');
                  }}
                  onBlur={(value) => {
                    const formattedValue = formatByPrecisionAndTrim(value, currentSymbol.quoteAssetPrecision, Number(currentSymbol.priceMin), Number(currentSymbol.priceMax));
                    field.onChange(formattedValue);
                    onStopLossOrderPriceChange(formattedValue);
                    trigger('stopLossOrderPrice');
                  }}
                  icon={quoteAsset}
                  status={errors.stopLossOrderPrice ? 'error' : undefined}
                />
              )}
            />
          
            <Controller
              name='stopLossOrderPricePercent'
              control={control}
              render={({
                field, 
              }) => (
                <Input
                  value={field.value}
                  onChange={(value) => {
                    field.onChange(value);
                    onStopLossOrderPricePercentChange(value);
                  }}
                  maxWidth={58}
                  icon='%'
                />
              )}
            />
          </Box>
        </Box>
        <ErrorMessage message={errors?.stopLossOrderPrice?.message} />
      </Stack>
    );
  };

  console.log('stopLossPriceRecalculation', stopLossPriceRecalculation);
  
  const renderTriggerPriceField = () => {

    return (
      <Stack
        gap={0.5}
        height={81}
      >
        <Box sx={priceWrapper}>
          <Text
            type='secondary'
            styles={priceTitle}
          >
            {'Trigger price'}
          </Text>

          <Box sx={priceInner}>
            <Controller
              name='stopLossTriggerPrice'
              control={control}
              rules={{
                required: 'This field is required',
                validate: (value) => {
                  const numValue = Number(value);
                  if (isNaN(numValue)) return 'Value should be a number';
                  if (numValue < Number(currentSymbol.priceMin)) return `Minimum value is ${getTrailedZeroCutted(currentSymbol.priceMin)}`;
                  if (numValue > Number(currentSymbol.priceMax)) return `Maximum value is ${getTrailedZeroCutted(currentSymbol.priceMax)}`;
                  if (stopLossOrderType === 'cond.market' && numValue * units <= Number(currentSymbol.minNotional)) {
                    return 'Order volume is less than minimal';
                  }
                  return true;
                },
              }}
              render={({
                field,
              }) => (
                <Input
                  value={field.value}
                  onChange={(value) => {
                    if (/^[0-9]*\.?[0-9]*$/.test(value) || value === '') {
                      field.onChange(value);
                      onStopLossTriggerPriceChange(value);
                    }
                    trigger('stopLossTriggerPrice');
                  }}
                  onBlur={(value) => {
                    const formattedValue = formatByPrecisionAndTrim(value, currentSymbol.quoteAssetPrecision, Number(currentSymbol.priceMin), Number(currentSymbol.priceMax));
                    field.onChange(formattedValue);
                    onStopLossTriggerPriceChange(formattedValue);
                    trigger('stopLossTriggerPrice');
                  }}
                  icon={quoteAsset}
                  addonBefore={`${stopLossTriggerPricePercent}%`}
                  status={errors.stopLossTriggerPrice ? 'error' : undefined}
                />
              )}
            />
          
            <Controller
              name='stopLossTriggerPriceType'
              control={control}
              render={({
                field, 
              }) => (
                <SingleSelect
                  maxWidth='max-content'
                  select={{
                    value: field.value,
                    placeholder: 'Last',
                    onChange: (value) => {
                      field.onChange(value);
                      trigger('stopLossTriggerPriceType');
                    },
                  }}
                  options={[
                    {
                      label: 'Last', value: 'last', 
                    },
                    {
                      label: 'Bid', value: 'bid', 
                    },
                    {
                      label: 'Ask', value: 'ask', 
                    },
                  ]}
                />
              )}
            />
          </Box>
        </Box>
        <ErrorMessage message={errors?.stopLossTriggerPrice?.message} />
      </Stack>
    );
  };
  
  return (
    <Box sx={wrapper}>
      <Box sx={header}>
        <Switch
          value={isStopLossEnabled}
          onChange={setIsSkipStopLoss}
          suffixText={(
            <Title
              level={5}
              styles={headerTitle}
            >
              Stop loss
            </Title>
          )}
          size='small'
        />

        {isStopLossEnabled && (
          <Switch
            value={stopLossPriceRecalculation === 'average'}
            checkedChildren='Average'
            unCheckedChildren='Average'
            onChange={changePriceRecalculationHandler}
            suffixText={(
              <Tooltip
                title={(
                  <Box maxWidth={250}>
                    Automatically adjusts SL to maintain loss percentage when entry price is averaged.
                    Example: entry is $100, SL set at $90 (-10%), if price is averaged to $95, SL becomes $85.5 which is still -10%
                  </Box>
                )}
              >
                {SecondaryInfo}
              </Tooltip>
            )}
          />
        )}
      </Box>

      {isStopLossEnabled && (
        <>
          <Segmented
            value={stopLossOrderType}
            onChange={changeSegmentHandler}
            options={segments}
            block={true}
          />
          {renderTriggerPriceField()}
          {renderOrderPriceField()}
        </>
      )}
    </Box>
  );
};
