export const PublicBots = (
  <svg width='28' height='28' viewBox='0 0 28 28' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_10577_5248)'>
      <path d='M7.875 10.9375H3.5C3.01675 10.9375 2.625 11.3293 2.625 11.8125V16.1875C2.625 16.6707 3.01675 17.0625 3.5 17.0625H7.875C8.35825 17.0625 8.75 16.6707 8.75 16.1875V11.8125C8.75 11.3293 8.35825 10.9375 7.875 10.9375Z' stroke='#2ECD99' strokeWidth='1.75' strokeLinecap='round' strokeLinejoin='round'/>
      <path d='M23.625 4.375H18.375C17.8918 4.375 17.5 4.76675 17.5 5.25V10.5C17.5 10.9832 17.8918 11.375 18.375 11.375H23.625C24.1082 11.375 24.5 10.9832 24.5 10.5V5.25C24.5 4.76675 24.1082 4.375 23.625 4.375Z' stroke='#2ECD99' strokeWidth='1.75' strokeLinecap='round' strokeLinejoin='round'/>
      <path d='M23.625 16.625H18.375C17.8918 16.625 17.5 17.0168 17.5 17.5V22.75C17.5 23.2332 17.8918 23.625 18.375 23.625H23.625C24.1082 23.625 24.5 23.2332 24.5 22.75V17.5C24.5 17.0168 24.1082 16.625 23.625 16.625Z' stroke='#2ECD99' strokeWidth='1.75' strokeLinecap='round' strokeLinejoin='round'/>
      <path d='M8.75 14H13.125' stroke='#2ECD99' strokeWidth='1.75' strokeLinecap='round' strokeLinejoin='round'/>
      <path d='M17.5 20.125H15.75C15.0538 20.125 14.3861 19.8484 13.8938 19.3562C13.4016 18.8639 13.125 18.1962 13.125 17.5V10.5C13.125 9.80381 13.4016 9.13613 13.8938 8.64384C14.3861 8.15156 15.0538 7.875 15.75 7.875H17.5' stroke='#2ECD99' strokeWidth='1.75' strokeLinecap='round' strokeLinejoin='round'/>
    </g>
    <defs>
      <clipPath id='clip0_10577_5248'>
        <rect width='28' height='28' fill='white'/>
      </clipPath>
    </defs>
  </svg>
);
