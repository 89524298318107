import { SxProps, Theme } from '@mui/material';

export const INNER: SxProps<Theme> = {
  flexDirection: {
    xs: 'column',
    sm: 'row',
  },
  gap: '20px',
  justifyContent: 'space-between',
  alignItems: 'center',
};
