import { SxProps, Theme } from '@mui/material';

export const LOGO: SxProps<Theme> = {
  padding: '20px',
  width: '100%',
  flex: 1,
  backgroundColor: '#fff',
  borderRadius: '16px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};
