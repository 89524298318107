import { createAsyncThunk } from '@reduxjs/toolkit';
import { getExchangeSymbols } from 'pages/trading-terminal-page/trading-chart/helpers/get-symbols';
import { CACHE_TTL } from '../slice/exchange-symbols-slice';
import { ExchangeSymbolsSchema } from '../types/exchange-symbols-schema';

export const fetchSymbols = createAsyncThunk(
  'symbols/fetch',
  async (exchange: string, {
    getState, 
  }) => {
    const state = getState() as { symbols: ExchangeSymbolsSchema; };
    const lastUpdated = state.symbols.lastUpdated[exchange];
    const hasData = state.symbols.data[exchange]?.length > 0;
    
    
    if (hasData && lastUpdated && Date.now() - lastUpdated < CACHE_TTL) {
      return null;
    }
    
    const symbols = await getExchangeSymbols(exchange);
    return {
      exchange, symbols, 
    };
  },
);
