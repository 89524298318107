import { SxProps, Theme } from '@mui/material';

export const WRAPPER: SxProps<Theme> = {
  gap: '40px',
  flexDirection: 'row',
  alignItems: 'center',
  // justifyContent: 'flex-end',
  flex: 1,
  // maxWidth: 'max-content',
};
