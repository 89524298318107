import { SxProps, Theme } from '@mui/material';

export const WRAPPER: SxProps<Theme> = {
  gap: {
    xs: '20px',
    sm: '40px',
  },
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
};
