import { SxProps, Theme } from '@mui/material';

export const BLOCK: SxProps<Theme> = {
  backgroundColor: '#F8FBF9',
  borderRadius: '16px',
  gap: '30px',
  justifyContent: 'center',
  textAlign: 'left',
  padding: '34px',
};
