import { collectFormData, formatDataForConfirmation } from 'pages/manual-trading/trading-terminal/helpers/collect-form-data';
import { useCallback, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { typeMapping } from 'shared/helpers/order-type-mappings';
import { collectDataForEditTrade } from '../helpers/collect-data-for-edit-trade';
import { getOppositeSide } from '../helpers/helpers';

interface UseFormSubmissionProps {
    isSkipBaseOrder: boolean;
    exchangeAccountUuid: string;
    editingTrade?: any;
  }
  
  interface ConfirmationItem {
    title: string;
    columns: string[];
    items: any;
  }
  
export const useFormSubmission = ({
  isSkipBaseOrder,
  exchangeAccountUuid,
  editingTrade,
}: UseFormSubmissionProps) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [confirmationData, setConfirmationData] = useState<ConfirmationItem[]>([]);
  const [collectedData, setCollectedData] = useState<any[]>([]);
  
  const {
    clearErrors, 
    trigger, 
    handleSubmit, 
    formState,
  } = useFormContext();
  
  const handleFormSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    clearErrors('additionalEntryDuplicatePriceError');
    clearErrors('takeProfitDuplicatePriceError');
    trigger();
    event.preventDefault();
    handleSubmit(handleSubmitData)(event);
  };
  
  const collectChangedData = (data: any) => {
    
    const cancelOrderUuids = new Set<string>();
    const result: any = {
      smartTradeUuid: data.smartTradeUuid,
      cancelOrderUuids: [],
      newOrdersForm: {},
    };
    
    const hasBaseOrderChanges = 
    data.orderPrice !== data.initialOrderPrice ||
    data.units !== data.initialUnits ||
    data.total !== data.initialTotal ||
    data.selectedSide !== data.initialSelectedSide;

    if (hasBaseOrderChanges) {
      
      if (data.baseOrderUuid) {
        cancelOrderUuids.add(data.baseOrderUuid);
      }
      
      result.newOrdersForm.baseOrder = {
        side: data.selectedSide.toUpperCase(),
        type: typeMapping[data.orderType],
        limitPrice: parseFloat(data.orderPrice),
        unitsBase: parseFloat(data.units),
        // total: parseFloat(data.total),
      };
    }
    
    // Проверяем additional entries
    // Сначала проверяем удаленные ордера
    data.initialAdditionalEntries.forEach((initialEntry: any) => {
      // debugger;
      const stillExists = data.additionalEntries
        .some((entry: any) => entry.orderUuid === initialEntry.orderUuid);
      if (!stillExists) {
        cancelOrderUuids.add(initialEntry.orderUuid);
      }
    });

    // Затем проверяем измененные и новые ордера
    const changedAdditionalEntries = data.additionalEntries
      .filter((entry: any) => {
        const initialEntry = data.initialAdditionalEntries
          .find((initial: any) => initial.orderUuid === entry.orderUuid);
    
        if (initialEntry && hasOrderChanges(entry, initialEntry)) {
          cancelOrderUuids.add(initialEntry.orderUuid);
          return true;
        }
        return !entry.orderUuid; // Включаем новые записи
      })
      .map((entry: any) => {
        // Модифицируем данные перед отправкой
        return {
          side: data.selectedSide.toUpperCase(),
          type: typeMapping[entry.additionalOrderType],
          ...(entry.additionalOrderType === 'limit' && {
            limitPrice: parseFloat(entry.price),
          }),
          ...(entry.additionalOrderType === 'cond.market' && {
            triggerPrice: parseFloat(entry.triggerPrice),
            triggerPriceType: entry.typeTriggerPrice,
          }),
          ...(entry.additionalOrderType === 'cond.limit' && {
            limitPrice: parseFloat(entry.price),
            triggerPrice: parseFloat(entry.triggerPrice),
            triggerPriceType: entry.typeTriggerPrice,
          }),
          unitsBase: parseFloat(entry.units),
          // Добавляем остальные необходимые поля
          ...(entry.orderUuid && {
            orderUuid: entry.orderUuid, 
          }),
        };
      });

    if (changedAdditionalEntries.length > 0) {
      result.newOrdersForm.additionalBaseOrders = changedAdditionalEntries;
    }
    
    // Проверяем take profit entries
    // Сначала проверяем удаленные ордера
    data.initialTakeProfitEntries.forEach((initialEntry: any) => {
      // debugger;
      const stillExists = data.takeProfitEntries
        .some((entry: any) => entry.orderUuid === initialEntry.orderUuid);
      if (!stillExists) {
        cancelOrderUuids.add(initialEntry.orderUuid);
      }
    });

    // Затем проверяем измененные и новые ордера
    
    const changedTakeProfits = data.takeProfitEntries
      .filter((entry: any) => {
        const initialEntry = data.initialTakeProfitEntries
          .find((initial: any) => initial.orderUuid === entry.orderUuid);
    
        if (initialEntry && hasOrderChanges(entry, initialEntry)) {
          cancelOrderUuids.add(initialEntry.orderUuid);
          return true;
        }
        return !entry.orderUuid;
      })
      .map((entry: any) => {
        // Модифицируем данные перед отправкой
        return {
          side: getOppositeSide(data.selectedSide),
          type: typeMapping[entry.takeProfitOrderType],
          ...(entry.takeProfitOrderType === 'limit' && {
            limitPrice: parseFloat(entry.price),
          }),
          ...(entry.takeProfitOrderType === 'cond.market' && {
            triggerPrice: parseFloat(entry.price),
            triggerPriceType: entry.typeTriggerPrice,
          }),
          ...(entry.takeProfitOrderType === 'cond.limit' && {
            limitPrice: parseFloat(entry.price),
            triggerPrice: parseFloat(entry.triggerPrice),
            triggerPriceType: entry.typeTriggerPrice,
          }),
          percent: parseFloat(entry.volume),
          // Добавляем остальные необходимые поля
          ...(entry.orderUuid && {
            orderUuid: entry.orderUuid, 
          }),
        };
      });

    if (changedTakeProfits.length > 0) {
      result.newOrdersForm.takeProfits = changedTakeProfits;
    }    
  
    if (data.stopLossEnabled) {

      const stopLossChanged = 
        data.stopLossTriggerPrice !== data.initialStopLossTriggerPrice ||
        data.stopLossOrderPrice !== data.initialStopLossOrderPrice ||
        data.stopLossTriggerPriceType !== data.initialStopLossTriggerPriceType ||
        data.stopLossOrderType !== data.initialStopLossOrderType;
    
      // debugger; 
        
      if (stopLossChanged) {

        if (data.stopLossOrderUuid) {
          cancelOrderUuids.add(data.stopLossOrderUuid);
        }
        result.newOrdersForm.stopLoss = {
          side: getOppositeSide(data.selectedSide),
          type: typeMapping[data.stopLossOrderType],
          triggerPrice: parseFloat(data.stopLossTriggerPrice),
          typeTriggerPrice: data.stopLossTriggerPriceType.toUpperCase(),
          ...(data.stopLossOrderType === 'cond.limit' && {
            limitPrice: parseFloat(data.stopLossOrderPrice),
          }),
        };
      }
    } else if (data.initialStopLossEnabled) {
      // Stop loss был отключен
      if (data.stopLossOrderUuid) {
        cancelOrderUuids.add(data.stopLossOrderUuid);
      }
    }
  
    result.cancelOrderUuids = Array.from(cancelOrderUuids);
    return result;
  };
  
  const hasOrderChanges = (current: any, initial: any): boolean => {
    // Проверяем, что оба объекта существуют
    if (!current || !initial) {
      return false;
    }
  
    const fieldsToCompare = [
      'orderPrice',
      'triggerPrice',
      'orderType',
      'limitPrice',
      'unitsBase',
      'percent',
      'priceRecalculation',
      'type',
      'typeTriggerPrice',
      'side',
    ];
  
    return fieldsToCompare.some(field => {
      if (field in current && field in initial) {
        return current[field] !== initial[field];
      }
      return false;
    });
  };
  
  const handleSubmitData = (data: any) => {
    if (editingTrade) {
      const changedData = collectChangedData(data);
      const collectedModalData = collectDataForEditTrade(data);
      setCollectedData(changedData);
      const formattedData = formatDataForConfirmation(collectedModalData);
      setConfirmationData(formattedData);
    } else {
      const collectedFormData = collectFormData(data, isSkipBaseOrder, exchangeAccountUuid);
      setCollectedData(collectedFormData);
      const formattedData = formatDataForConfirmation(collectedFormData);
      setConfirmationData(formattedData);
    }
    setIsModalVisible(true);
  };
  
  const isFormValidExceptAdditionalEntries = useCallback(() => {    
    const currentErrors = {
      ...formState.errors, 
    };
    delete currentErrors.additionalEntryDuplicatePriceError;
    delete currentErrors.takeProfitDuplicatePriceError;
    return Object.keys(currentErrors).length === 0;
  }, [formState.errors]);
  
  return {
    formState: {
      isModalVisible,
      confirmationData,
      collectedData,
    },
    handlers: {
      handleFormSubmit,
      setIsModalVisible,
      isFormValidExceptAdditionalEntries,
    },
  };
};
