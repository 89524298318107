import { SxProps, Theme } from '@mui/material';

export const ROW_WRAPPER: SxProps<Theme> = {
  flexDirection: {
    xs: 'column-reverse',
    md: 'row',
  },
  width: '100%',
  alignItems: {
    xs: 'center',
    md: 'flex-end',
  },
  gap: {
    xs: '4px',
    md: 0,
  },
  justifyContent: 'space-between',
  '&>span:last-of-type': {
    fontSize: 13,
    fontWeight: 400,
    lineHeight: 'normal',
  },
};
