import { SxProps, Theme } from '@mui/material';

export const LIST_ITEM: SxProps<Theme> = {
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  '&>h1': {
    fontSize: 12,
    fontWeight: 400,
    lineHeight: 'normal',
  },
  '&>span': {
    fontSize: 15,
    fontWeight: 400,
    lineHeight: 'normal',
    color: '#7C7C7C',
  },
};
