import { SxProps, Theme } from '@mui/material';

export const CREATE_LINK: SxProps<Theme> = {
  flexDirection: 'row',
  alignItems: 'flex-end',
  gap: '25px',
  '&>div': {
    maxWidth: {
      xs: '100%',
      md: 380,
    },
  },
};
