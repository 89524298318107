import { Box, Stack } from '@mui/material';
import { Text } from 'shared/ui';
import { ICard } from '../interfaces';
import { button, img, text, textWrapper, wrapper } from '../styles';

export const Card = (props: ICard) => {
  const {
    image,
    title,
    description,
    onClickHandler,
    children,
  } = props;

  const getContent = () => {
    if (children) {
      return (
        <Stack
          sx={{
            gap: '40px',
            paddingTop: '34px',
          }}
        >
          {children}

          <img src={image} alt='' />
        </Stack>
      );
    }
    
    return (
      <img src={image} alt={title} />
    );
  };

  return (
    <div
      onClick={onClickHandler}
      style={wrapper}
    >
      <Box sx={button}>
        <Box sx={textWrapper}>
          <Text
            styles={text({
              fontSize: 24,
            })}
            type='success'
          >
            {title}
          </Text>

          <Text
            styles={text({
              fontSize: 12,
            })}
          >
            {description}
          </Text>
        </Box>

        <Box sx={img}>
          {getContent()}
        </Box>
      </Box>
    </div>
  );
};
