import { Navigate } from 'react-router-dom';
import { getAuthToken } from 'shared/helpers/storage-helper';
import { RouteGuardProps } from '../model/types/route-guard';

export const GuestRoute = (props: RouteGuardProps) => {
  const isAuthenticated = getAuthToken();

  if (!isAuthenticated) {
    return (
      <>{props.children}</>
    );
  }

  return <Navigate to='/home' replace />;
};
