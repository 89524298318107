import { SxProps, Theme } from '@mui/material';

export const CONTENT: SxProps<Theme> = {
  flexDirection: {
    xs: 'column-reverse',
    lg: 'row',
  },
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: '24px',
};
