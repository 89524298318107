import { Box } from '@mui/material';
import { exchangeActions } from 'entities/exchange/model/slices/exchange-slice';
import { ExchangeAccount } from 'entities/exchange/model/types/exchange-account';
import { CreateExchangeProps } from 'entities/exchange/model/types/exchange-connect-update.types';
import { getIsDemoMode } from 'entities/user/model/selectors/get-is-demo-mode/get-is-demo-mode';
import { connectExchange } from 'features/connect-exchange/api/connect-exchange';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getExchanges, getExchangesIds } from 'widgets/exchanges/api/get-exchanges';
import { ActionBlock, Form, Info } from 'widgets/exchanges/components';
import { exchangeConfigs } from 'widgets/exchanges/helpers/exchange-config-map';
import { generateRandomHash } from 'widgets/exchanges/helpers/generate-hash-oauth-link';
import { getWrapperStyles, logo, platformLogo } from 'widgets/exchanges/styles';
import { Tabs } from 'widgets/tabs';
import { getDemoAccount } from 'shared/helpers';
import { getSkyrexUuid } from 'shared/helpers/storage-helper';
import { Loader, Title } from 'shared/ui';
import {
  EmptySubscription,
} from 'shared/ui/modals';

export const CreateExchange: React.FC<CreateExchangeProps & { exchangeType: keyof typeof exchangeConfigs; }> = ({
  isModalView,
  returnToAllExchanges,
  handleClose,
  exchangeType,
}) => {
  const uuid = getSkyrexUuid();
  const dispatch = useDispatch();
  const config = exchangeConfigs[exchangeType];
  const navigate = useNavigate();
  const isDemoMode = useSelector(getIsDemoMode);

  const [formData, setFormData] = useState<Record<string, string>>({});
  const [connectExchangeSuccessModalOpen, setConnectExchangeSuccessModalOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>({
    isOpened: false,
    title: 'Failed to connect',
    description: 'Check API keys, permissions, IP whitelist or contact support',
    button: {
      title: 'Okay',
      action: () => {
        setError((prev: any) => ({
          ...prev,
          isOpened: false,
          title: 'Failed to connect',
          description: 'Check API keys, permissions, IP whitelist or contact support',
        }));
      },
    },
  });

  const handleCloseModal = () => {
    setConnectExchangeSuccessModalOpen(false);
    handleClose?.();
  };

  const connectExchangeWithApiKeys = async () => {
    setIsLoading(true);
    try {
      const dataForConnectOauth = {
        uuid: uuid ?? '',
        exchangeName: exchangeType === 'crypto' ? 'crypto-com' : exchangeType,
        // exchangeName: exchangeType,
        ...formData,
      };

      const connectResponse = await connectExchange(dataForConnectOauth);

      if (!connectResponse.success) {
        if (connectResponse.data.message === 'Maximum number of active accounts reached. Please upgrade subscription') {
          setError((prev: any) => ({
            ...prev,
            isOpened: true,
            description: 'Maximum connected exchange accounts is reached',
            button: {
              ...prev.button,
              action: () => {
                navigate('/subscriptions');
              },
            },
          }));
          return;
        }

        setError((prev: any) => ({
          ...prev,
          isOpened: true,
          description: connectResponse.data.message,
        }));
        return;
      }

      const exchangesIdsResponse = await getExchangesIds(uuid ?? '');
      if (!exchangesIdsResponse.success) {
        setError((prev: any) => ({
          ...prev,
          isOpened: true,
          description: connectResponse.data.message,
        }));
        return;
      }

      const {
        accounts,
      } = exchangesIdsResponse.data;
      const accountsIds = getDemoAccount(accounts, isDemoMode).map(
        (account: ExchangeAccount) => account.exchangeAccountUuid,
      );

      const allAccountsData = await getExchanges(accountsIds);
      dispatch(exchangeActions.setAllExchanges(allAccountsData.data.accounts));
      setIsLoading(false);
      setConnectExchangeSuccessModalOpen(true);
      return;
    } catch (error) {
      setError((prev: any) => ({
        ...prev,
        isOpened: true,
        title: 'Something went wrong',
        description: 'We will fix it soon, try again later or contact support',
      }));
    } finally {
      setIsLoading(false);
    }
  };

  const handleInputChange = (key: string, value: string) => {
    setFormData(prev => ({
      ...prev, [key]: value,
    }));
  };

  const connectExchangeOauth = () => {
    const skyrexUserUuid = getSkyrexUuid();

    let url: string;
    
    if (typeof config.oauthUrl === 'function') {
      if (exchangeType === 'htx') {
        url = config.oauthUrl({
          mode: 'create',
        });
      } else {
        const randomHash = generateRandomHash(exchangeType === 'okx' ? 16 : 48);
        url = config.oauthUrl({
          mode: 'create',
          userId: skyrexUserUuid ?? '',
          exchangeAccountUuid: '',
        }) + randomHash;
      }
    } else {
      const randomHash = generateRandomHash(exchangeType === 'okx' ? 16 : 48);
      url = `${config.oauthUrl}${randomHash}`;
    }

    window.open(url, '_self');
  };

  const infoForm = (
    <Info
      returnToAllExchanges={returnToAllExchanges}
      actionBlock={(
        <ActionBlock
          button={{
            label: `Connect ${config.name}`,
            action: connectExchangeOauth,
          }}
          createAccount={{
            label: `Sign up at ${config.name} with Skyrexio and get bonuses`,
            link: config.createAccountUrl,
          }}
        />
      )}
    />
  );

  const form = (
    <Form
      returnToAllExchanges={returnToAllExchanges}
      type={exchangeType}
      fields={config.fields.map(field => ({
        ...field,
        value: formData[field.key] || '',
        onChange: (value: string) => handleInputChange(field.key, value),
      }))}
      actionBlock={(
        <ActionBlock
          button={{
            label: `Connect ${config.name}`,
            action: connectExchangeWithApiKeys,
          }}
          createAccount={{
            label: `Sign up at ${config.name} with Skyrexio and get bonuses`,
            link: config.createAccountUrl,
          }}
        />
      )}
    />
  );

  return (
    <>
      <EmptySubscription
        isOpen={connectExchangeSuccessModalOpen}
        handleClose={handleCloseModal}
        modalTitle='Account connected'
        modalDescription='Create or copy your first trading bot or smart terminal position'
        button={{
          title: 'Okay',
          action: handleCloseModal,
        }}
      />

      <EmptySubscription
        isOpen={error.isOpened}
        handleClose={() => {
          setError((prev: any) => ({
            ...prev,
            isOpened: false,
          }));
        }}
        modalTitle={error.title}
        modalDescription={error.description}
        isError={error.isOpened}
        button={{
          title: error.button.title,
          action: error.button.action,
        }}
      />

      <Box position='relative'>
        {isLoading && (
          <Loader isContentOverflow={true} />
        )}

        <Box sx={getWrapperStyles(!!isModalView)}>
          <Box sx={logo}>
            <Title>Connect</Title>
            <Box sx={platformLogo}>{config.logo}</Box>
            <Title>exchange</Title>
          </Box>

          <Tabs
            tabs={[{
              label: 'Fast OAuth',
              value: 0,
              disabled: config.isOauthDisabled,
            }, {
              label: 'API keys',
              value: 1,
            }]}
            styles={{
              width: '100%',
              flex: 1,
              justifyContent: 'center',
            }}
            defaultValue={config.isOauthDisabled ? 1 : 0}
            maxWidth='475px'
            content={[infoForm, form]}
          />
        </Box>
      </Box>
    </>
  );
};
