import { CSSProperties } from 'react';

export const BUTTON: CSSProperties = {
  height: 'auto',
  fontSize: 14,
  fontWeight: 400,
  lineHeight: 'normal',
  width: '100%',
  maxWidth: 180,
  padding: '7px 0',
};
