import { Typography } from 'antd';
import { IProps } from '../interfaces';

export const Link = (props: IProps): JSX.Element => {
  const {
    styles,
    children,
    type,
    href,
    onClick,
    target,
    download,
  } = props;

  return (
    <Typography.Link
      style={styles}
      type={type}
      href={href}
      onClick={onClick}
      target={target}
      download={download}
    >
      {children}
    </Typography.Link>
  );
};
