import { Stack } from '@mui/material';
import { Text, Title } from 'shared/ui';
import { IListItemProps } from '../interfaces';
import { LIST_ITEM } from '../styles';

export const ListItem = (props: IListItemProps) => {
  return (
    <Stack sx={LIST_ITEM}>
      <Title>
        {props.title}
      </Title>
  
      <Text type={props.primary ? 'success' : 'secondary'}>
        {props.text}
      </Text>
    </Stack>
  );
};
