import { SxProps, Theme } from '@mui/material';

export const ACTIONS: SxProps<Theme> = {
  flexDirection: 'row',
  alignItems: 'center',
  gap: '8px',
  '&>div': {
    alignSelf: 'initial !important',
  },
};
