import {
  HOME,
  INTRODUCTION,
  INVITE_FRIENDS,
  MANUAL_TRADING,
  MARKETPLACES,
  MY_ACCOUNTS,
  MY_BOTS,
  SALES,
  SUBSCRIPTIONS,
  TRADING_BOTS,
  TRADING_HISTORY,
  TRADING_TERMNIAL,
} from '../../../shared/consts';
import {
  Bot,
  Candles,
  Friends,
  History as HistoryIcon,
  Info,
  PublicBots,
} from '../../../shared/icons';
import { IMenuItem } from '../interfaces';

export const menuItems: IMenuItem[] = [{
  key: 'home',
  label: 'Home',
  link: HOME,
}, {
  key: 'my-accounts',
  label: 'Accounts',
  link: MY_ACCOUNTS,
}, {
  key: 'manual-trading',
  label: 'Terminal',
  link: MANUAL_TRADING,
  children: [{
    icon: Candles,
    label: 'Smart terminal',
    description: 'Create and manage advanced positions',
    link: `/${MANUAL_TRADING}/${TRADING_TERMNIAL}`,
  }, {
    icon: HistoryIcon,
    label: 'Trading diary',
    description: 'Track and export your statistic',
    link: `/${MANUAL_TRADING}/${TRADING_HISTORY}`,
  }, {
    icon: Info,
    label: 'Introduction',
    description: 'Explore benefits of Smart terminal',
    link: `/${MANUAL_TRADING}/${INTRODUCTION}`,
  }],
}, {
  key: 'trading-bots',
  label: 'Bots',
  link: TRADING_BOTS,
  children: [{
    icon: Bot,
    label: 'My Bots',
    description: 'Aggregate statistic and bots list',
    link: `/${TRADING_BOTS}/${MY_BOTS}`,
  }, {
    icon: HistoryIcon,
    label: 'Trading diary',
    description: 'Track and export your statistic',
    link: `/${TRADING_BOTS}/${TRADING_HISTORY}`,
  }, {
    icon: Friends,
    label: 'Marketplace',
    description: 'Copy top performing bots',
    link: `/${TRADING_BOTS}/${MARKETPLACES}`,
  }, {
    icon: PublicBots,
    label: 'My public bots',
    description: 'Share your bots and track sales',
    link: `/${TRADING_BOTS}/${SALES}`,
  }, {
    icon: Info,
    label: 'Introduction',
    description: 'Explore benefits of trading bots',
    link: `/${TRADING_BOTS}/${INTRODUCTION}`,
  }, {
    label: 'New Bot',
    key: 'add',
    description: '',
    link: `/${TRADING_BOTS}/create`,
  }],
}, {
  key: 'subscriptions',
  label: 'Subcriptions',
  badge: 'Sale',
  link: SUBSCRIPTIONS,
}, {
  key: 'invite-friends',
  label: 'Invite friends',
  link: INVITE_FRIENDS,
}];
