import { Box } from '@mui/material';
import { Input as BaseInput } from 'antd';
import { ChangeEvent } from 'react';
import { IInputProps } from '../interfaces';
import { input } from '../styles';

export const Input = (props: IInputProps) => {
  const {
    value,
    onChange,
    placeholder,
    icon,
    disabled,
    maxWidth,
    styles,
    addonBefore,
    addonAfter,
    status,
    onBlur,
    inputStyles,
    type,
    max,
  } = props;

  const onChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value);
  };

  return (
    <Box
      flex={1}
      maxWidth={maxWidth}
      sx={styles}
    >
      <BaseInput
        size='large'
        value={value}
        placeholder={placeholder}
        onChange={onChangeHandler}
        style={{
          ...input,
          ...inputStyles, 
        }}
        suffix={icon && (
          <div
            style={{
              fontSize: 12,
              fontWeight: 400,
            }}
          >
            {icon}
          </div>
        )}
        max={max}
        // min={1}
        type={type}
        disabled={disabled}
        addonBefore={addonBefore}
        addonAfter={addonAfter}
        status={status}
        onBlur={(e) => onBlur && onBlur(e.target.value)}
      />
    </Box>
  );
};
