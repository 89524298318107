import { SxProps, Theme } from '@mui/material';

export const TABLE_ROW: SxProps<Theme> = {
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  '&>span': {
    fontSize: 11,
    fontWeight: 400,
    lineHeight: 'normal',
    color: '#9E9E9E',
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    flex: 1,
    '&:first-of-type': {
      maxWidth: 20,
    },
    '&:last-of-type': {
      maxWidth: 70,
      justifyContent: 'flex-end',
    },
  },
};
