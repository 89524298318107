import { Stack } from '@mui/material';
import { getCurrentBot } from 'entities/bot/model/selectors/get-current-bot/get-current-bot';
import { getExchange } from 'entities/choose-account';
import { getAlertSource } from 'entities/choose-source';
import { getIsOpened, getSegment, riskManagmentCloseActions } from 'entities/risk-managment-close';
import { Actions } from 'pages/trading-bots/configurator-v2/components';
import { getCloseOrdersValue, getPanelContent } from 'pages/trading-bots/configurator-v2/helpers';
import { ReactNode, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Panel } from '../../configure-risk-managment/components';
import { ICloseOrdersProps } from '../interfaces';
import { WRAPPER } from '../styles';
import { Alerts, Preset } from '../tabs';

export const CloseOrders = (props: ICloseOrdersProps) => {
  const dispatch = useDispatch();

  const bot = useSelector(getCurrentBot);
  const segment = useSelector(getSegment);

  const alertSource = useSelector(getAlertSource);
  const exchange = useSelector(getExchange);
  const isOpened = useSelector(getIsOpened);

  const getContent = useCallback((): ReactNode => {
    switch (segment) {
    case 'preset':
      return (
        <Preset />
      );
    case 'alert':
      return (
        <Alerts
          alertSource={alertSource}
          exchangeAccountUuid={exchange.id}
          handleBack={props.handleBack}
          createBot={props.createBot}
          changeCurrentStep={props.changeCurrentStep}
          botUuid={bot?.botUuid}
        />
      );
    default:
      return null;
    }
  }, [segment, alertSource, exchange]);

  const getBlock = useCallback(() => {
    if (segment === 'preset') {
      const isStrategy = alertSource === 'strategy';

      return (
        <Stack
          sx={{
            gap: '24px',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
            maxWidth: {
              xs: '100%',
              md: 386,
            },
          }}
        >
          <Panel type='close' />

          <Actions
            handleBack={props.handleBack}
            button={{
              title: isStrategy ? 'Next step' : bot ? 'Update bot' : 'Create bot',
              action: isStrategy ? props.changeCurrentStep! : props.createBot!,
            }}
          />
        </Stack>
      );
    }

    return getPanelContent({
      isAlert: segment === 'alert',
      source: alertSource,
      url: `/videos/${alertSource}.mp4`,
    });
  }, [segment]);

  useEffect(() => {
    if (!bot?.botUuid) {
      return;
    }

    dispatch(riskManagmentCloseActions.init(getCloseOrdersValue(bot)));
  }, [bot?.botUuid, dispatch]);

  return (
    <Stack sx={WRAPPER}>
      {isOpened && getContent()}

      {isOpened && getBlock()}
    </Stack>
  );
};

export default CloseOrders;
