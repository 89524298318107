import { Box } from '@mui/material';
import { Modal } from 'antd';
import { MainButton, Text, Title } from 'shared/ui';
import { items } from '../consts';
import { IBotEligible } from '../interfaces';
import {
  button,
  content,
  image,
  inner,
  item,
  list,
  modal,
  modalChildren,
  title,
  wrapper,
} from '../styles';

export const BotEligible = (props: IBotEligible) => {
  const {
    isOpened,
    closeHandler,
    botStatistic,
  } = props;

  const getNowField = (currentItem: any) => {
    if (currentItem.key === 'days') {
      return botStatistic?.days;
    }

    return `${(botStatistic?.[currentItem.key as string])?.toFixed(2)}${['roi', 'maxDrawdown'].includes(currentItem.key) ? '%' : ''}`;
  };

  const getKPIField = (currentItem: any) => {
    const value = `> ${currentItem.kpi}`;
    if (currentItem.key === 'maxDrawdown' || currentItem.key === 'roi') {
      return `${value}%`;
    }

    return value;
  };

  const getStatus = (currentItem: any) => {
    const key = currentItem.key;

    if (key === 'days') {
      return +currentItem.kpi <= +botStatistic?.['days'];
    }

    return +currentItem.kpi <= botStatistic?.[currentItem.key as string];
  };

  return (
    <Modal
      open={isOpened}
      footer={null}
      style={modal}
      styles={modalChildren}
      closable={false}
      destroyOnClose={true}
      onCancel={closeHandler}
      width='100%'
      centered={true}
    >
      <Box sx={wrapper}>
        <Title styles={title}>
          Bot does not meet all criteria to be shared at Marketplace
        </Title>

        <Box sx={inner}>
          <Box sx={content}>
            <Box sx={list}>
              <Box sx={item}>
                <Text>Criteria</Text>
                <Text>KPI</Text>
                <Text>Now</Text>
                <Text>Status</Text>
              </Box>

              {items.map((currentItem) => (
                <Box
                  key={currentItem.criteria}
                  sx={item}
                >
                  <Text>{currentItem.criteria}</Text>
                  <Text>{getKPIField(currentItem)}</Text>
                  <Text>{getNowField(currentItem) || currentItem.now}</Text>
                  <Text>
                    <Box sx={{
                      backgroundColor: getStatus(currentItem) ? '#2ECD99' : '#F32222',
                      width: 4,
                      height: 4,
                      borderRadius: '50%',
                    }}>
                    </Box>
                  </Text>
                </Box>
              ))}
            </Box>
          </Box>

          <Box sx={image}>
            <img src='/images/trading-bots/bot-eligible.png' alt='bot-eligible' />
          </Box>
        </Box>

        <MainButton
          type='primary'
          styles={button}
          onClick={closeHandler}
        >
          Okay
        </MainButton>
      </Box>
    </Modal>
  );
};
