import { SxProps, Theme } from '@mui/material';

export const WRAPPER: SxProps<Theme> = {
  borderRadius: '14px',
  backgroundColor: 'rgba(158, 158, 158, 0.05)',
  width: '100%',
  maxWidth: '100%',
  padding: {
    xs: '14px',
    md: '34px',
  },
  alignItems: 'initial',
  gap: '24px',
};
