import { Stack } from '@mui/material';
import { Modal } from 'antd';
import { useCallback } from 'react';
import { Label } from 'widgets';
import { MainButton, SingleSelect, Text } from 'shared/ui';
import { IPublicBotMarket } from '../interfaces';
import { BUTTON, modal, modalChildren, TITLE, WRAPPER } from '../styles';

export const PublicBotMarket = (props: IPublicBotMarket) => {
  const {
    isOpen,
    closeHandler,
    market,
  } = props;

  const onChangeOption = (value: string) => {
    market.setMarket(value);
  };

  const options = useCallback(() => {
    if (!market.items) {
      return [];
    }

    return market.items.map((market) => {
      const option = {
        label: market,
        value: market,
        key: market,
      };

      return option;
    });
  }, [market.items]);

  return (
    <Modal
      open={isOpen}
      footer={null}
      style={modal}
      styles={modalChildren}
      closable={true}
      destroyOnClose={true}
      onCancel={closeHandler}
      width='100%'
      centered={true}
    >
      <Stack sx={WRAPPER}>
        <Text styles={TITLE}>
          Choose market from your alert bots
        </Text>

        <SingleSelect
          label={(
            <Label
              title='Market'
            />
          )}
          options={options()}
          select={{
            value: market.value || undefined,
            placeholder: 'Choose market for your item',
            onChange: onChangeOption,
          }}
        />

        <MainButton
          type='primary'
          styles={BUTTON}
        >
          Next step
        </MainButton>
      </Stack>
    </Modal>
  );
};
