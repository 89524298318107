import { SxProps, Theme } from '@mui/material';

export const INNER: SxProps<Theme> = {
  gap: '42px',
  width: '100%',
  maxWidth: {
    xs: '100%',
    md: 400,
  },
  alignItems: {
    xs: 'initial',
    md: 'flex-start',
  },
};
