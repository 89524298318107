import { SxProps, Theme } from '@mui/material';

export const BLOCK_ACTIONS: SxProps<Theme> = {
  flexDirection: {
    xs: 'column',
    md: 'row',
  },
  alignItems: {
    xs: 'initial',
    lg: 'center',
  },
  gap: {
    xs: '12px',
    md: '24px',
  },
};
