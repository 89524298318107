import { Stack } from '@mui/material';
import { getIsOpened, getSegment as getSegmentAdditional, riskManagmentAdditionalActions } from 'entities/risk-managment-additional';
import { getSegment, riskManagmentAutoActions } from 'entities/risk-managment-auto';
import { getError } from 'entities/risk-managment-auto/selectors/get-error';
import { getIsOpened as getIsOpenedCloseOrders, getSegment as getSegmentCloseOrders, riskManagmentCloseActions } from 'entities/risk-managment-close';
import { BlockTitle } from 'pages/trading-bots/configurator-v2/components/block-title';
import { IStepProps } from 'pages/trading-bots/configurator-v2/interfaces';
import { memo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Tabs } from 'widgets';
import { Text } from 'shared/ui';
import { AdditionalEntry } from '../../additional-entry';
import { CloseOrders } from '../../close-orders';
import { Auto } from '../tabs';

export const ConfigureRiskManagment = memo((props: IStepProps) => {
  const dispatch = useDispatch();

  const additionalIsOpened = useSelector(getIsOpened);
  const additionalSegment = useSelector(getSegmentAdditional);

  const closeOrdersIsOpened = useSelector(getIsOpenedCloseOrders);
  const closeOrdersSegment = useSelector(getSegmentCloseOrders);
  
  const segment = useSelector(getSegment);

  const changeSegmentHandler = (value: number) => {
    const segment = value ? 'custom' : 'auto';
    dispatch(riskManagmentAutoActions.selectSegment(segment));
  };

  const toggleHandler = useCallback((action: any) => {
    return (value: boolean) => {
      dispatch(action.toggle(value));
    };
  }, [dispatch]);

  const changeCustomSegmentHandler = useCallback((action: any) => {
    return (value: string) => {
      dispatch(action.changeSegment(value));
    };
  }, [dispatch]);

  const autoError = useSelector(getError);

  const getContent = () => {
    if (props.currentStep === 2) {
      return (
        <Stack gap='20px'>
          <BlockTitle
            title='Additional entries'
            toggle={{
              toggled: additionalIsOpened,
              change: toggleHandler(riskManagmentAdditionalActions),
            }}
            segment={additionalIsOpened ? {
              selected: additionalSegment,
              options: [{
                label: 'Preset',
                value: 'preset',
              }, {
                label: 'Alerts',
                value: 'alert',
              }],
              change: changeCustomSegmentHandler(riskManagmentAdditionalActions),
              tooltip: (
                <Stack gap='8px'>
                  <Text>
                    Preset: Enables you to configure additional entries that activate when specific price levels are reached.
                  </Text>

                  <Text>
                    Alert: Allows you to dynamically open additional entries based on alerts from your indicators and strategies.
                  </Text>
                </Stack>
              ),
            } : undefined}
          />
        
          <AdditionalEntry
            handleBack={props.handleBack}
            changeCurrentStep={props.changeCurrentStep}
          />
        </Stack>
      );
    }

    return (
      <Stack gap='20px'>
        <BlockTitle
          title='Close orders'
          toggle={{
            toggled: closeOrdersIsOpened,
            change: toggleHandler(riskManagmentCloseActions),
          }}
          segment={closeOrdersIsOpened ? {
            selected: closeOrdersSegment,
            options: [{
              label: 'Preset',
              value: 'preset',
            }, {
              label: 'Alerts',
              value: 'alert',
            }],
            maxWidth: 258,
            change: changeCustomSegmentHandler(riskManagmentCloseActions),
            tooltip: (
              <Stack gap='8px'>
                <Text>
                  Preset: Enables you to configure close orders that activate when specific price levels are reached.
                </Text>

                <Text>
                  Alert: Allows you to dynamically close positions based on alerts from your and strategies.
                </Text>
              </Stack>
            ),
          } : undefined}
        />

        <CloseOrders
          handleBack={props.handleBack}
          createBot={props.createBot}
          changeCurrentStep={props.changeCurrentStep}
          currentStep={props.currentStep}
        />
      </Stack>
    );
  };

  return (
    <Tabs
      defaultValue={segment === 'auto' ? 0 : 1}
      tabs={[{
        label: 'Auto',
        value: 0,
      }, {
        label: 'Custom',
        value: 1,
      }]}
      content={[(
        <Auto
          handleBack={props.handleBack!}
          createBot={props.createBot!}
          error={!!autoError}
        />
      ), getContent()]}
      styles={{
        alignItems: 'flex-start',
      }}
      maxWidth='400px'
      segmentStyles={{
        alignSelf: 'flex-start',
      }}
      getTab={changeSegmentHandler}
      tabsLabel={{
        value: 'Choose risk management type',
        tooltiop: '',
      }}
    />
  );
});

ConfigureRiskManagment.displayName = 'ConfigureRiskManagment';
