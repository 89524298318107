import { Box } from '@mui/material';
import { setExchangeData } from 'entities/exchange/helpers/set-exchanges-data';
import { exchangeActions } from 'entities/exchange/model/slices/exchange-slice';
import { UpdateExchangeProps } from 'entities/exchange/model/types/exchange-connect-update.types';
import { updateExchange } from 'features/update-exchange/api/update-exchange';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { ActionBlock, Form, Info } from 'widgets/exchanges/components';
import { exchangeConfigs } from 'widgets/exchanges/helpers/exchange-config-map';
import { generateRandomHash } from 'widgets/exchanges/helpers/generate-hash-oauth-link';
import { getWrapperStyles, logo, platformLogo } from 'widgets/exchanges/styles';
import { Tabs } from 'widgets/tabs';
import {
  getSkyrexUuid,
  setExchangeAccountNameBeingUpdated,
  setExchangeUuidBeingUpdated,
} from 'shared/helpers/storage-helper';
import { Loader, Title } from 'shared/ui';
import {
  EmptySubscription,
} from 'shared/ui/modals';

export const UpdateExchange: React.FC<UpdateExchangeProps & { exchangeType: keyof typeof exchangeConfigs; }> = ({
  isModalView,
  exchangeAccountUuid,
  currentExchangeTitle,
  closeExchangeModal,
  exchangeType,
}) => {
  const skyrexUserUuid = getSkyrexUuid();
  const dispatch = useDispatch();
  const config = exchangeConfigs[exchangeType];

  const [formData, setFormData] = useState<Record<string, string>>({
    exchangeTitle: currentExchangeTitle,
  });

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [modal, setModal] = useState<any>({
    isOpened: false,
    isError: false,
    title: 'Failed to update',
    description: 'Check API keys, permissions, IP whitelist or contact support',
    button: {
      title: 'Okay',
      action: () => {
        setModal((prev: any) => ({
          ...prev,
          isOpened: false,
          isError: false,
        }));
      },
    },
  });

  const onCloseErrorModal = () => {
    setModal((prev: any) => ({
      ...prev,
      isOpened: false,
    }));
  };

  const onCloseSuccessModal = () => {
    setModal((prev: any) => ({
      ...prev,
      isOpened: false,
    }));
    
    closeExchangeModal?.();
  };
  
  const updateExchangeWithOAuth = () => {
    const randomHash = generateRandomHash(exchangeType === 'okx' ? 16 : 48);
    
    let url: string;
    if (typeof config.oauthUrl === 'function') {
      url = config.oauthUrl({ 
        mode: 'update',
        userId: skyrexUserUuid ?? '',
        exchangeAccountUuid: exchangeAccountUuid ?? '',
      }) + randomHash;
    } else {
      url = `${config.oauthUrl}${randomHash}`;
    }
  
    setExchangeAccountNameBeingUpdated(currentExchangeTitle);
    setExchangeUuidBeingUpdated(exchangeAccountUuid);
    window.open(url, '_self');
  };

  const updateExchangeWithApiKeys = async () => {
    setIsLoading(true);

    try {
      const dataForUpdateOauth = {
        exchangeAccountUuid,
        accountName: currentExchangeTitle,
        exchangeCode: exchangeType,
        ...formData,
      };

      const updateResponse = await updateExchange(dataForUpdateOauth);

      if (!updateResponse.success) {
        setModal((prev: any) => ({
          ...prev,
          isOpened: true,
          isError: true,
          description: updateResponse?.data?.message === 'Cannot update API keys because they belong to other exchange account' 
            ? 'API keys belong to another exchange account' 
            : updateResponse?.data?.message,
        }));
        return;
      }
      
      const exchangeAccounts = await setExchangeData(skyrexUserUuid ?? '');
      dispatch(exchangeActions.setAllExchanges(exchangeAccounts));
      
      setModal((prev: any) => ({
        ...prev,
        isOpened: true,
        isError: false,
        title: 'Exchange account updated successfully',
        description: 'Now you are in one click to start trading bot or create manual trade using your account',
        button: {
          ...prev.button,
          action: () => {
            setModal((prev: any) => ({
              ...prev,
              isOpened: false,
              isError: false,
            }));

            closeExchangeModal();
          },
        },
      }));
    } catch (error) {
      setModal((prev: any) => ({
        ...prev,
        isOpened: true,
        isError: true,
        description: 'Check API keys, permissions, IP whitelist or contact support',
      }));
    } finally {
      setIsLoading(false);
    }
  };

  const handleInputChange = (key: string, value: string) => {
    setFormData(prev => ({
      ...prev, [key]: value, 
    }));
  };

  const infoForm = (
    <Info
      actionBlock={(
        <ActionBlock
          button={{
            label: `Update ${config.name}`,
            action: updateExchangeWithOAuth,
          }}
        />
      )}
    />
  );

  const form = (
    <Form
      type={exchangeType}
      fields={config.fields.map(field => ({
        ...field,
        disabled: field.key === 'exchangeTitle',
        value: formData[field.key] || '',
        onChange: (value: string) => handleInputChange(field.key, value),
      }))}
      actionBlock={(
        <ActionBlock
          button={{
            label: `Update ${config.name}`,
            action: updateExchangeWithApiKeys,
          }}
        />
      )}
    />
  );

  return (
    <>
      <EmptySubscription
        isOpen={modal.isOpened}
        handleClose={modal.isError ? onCloseErrorModal : onCloseSuccessModal}
        modalTitle={modal.title}
        modalDescription={modal.description}
        modalButtonTitle={modal.button.title}
        modalButtonAction={modal.button.action}
        isError={modal.isError}
      />

      <Box position='relative'>
        {isLoading && <Loader isContentOverflow={true} />}

        <Box sx={getWrapperStyles(!!isModalView)}>
          <Box sx={logo}>
            <Title>Update</Title>
            <Box sx={platformLogo}>{config.logo}</Box>
            <Title>exchange</Title>
          </Box>

          <Tabs
            tabs={[{
              label: 'Fast OAuth',
              value: 0,
            }, {
              label: 'API keys',
              value: 1,
            }]}
            styles={{
              width: '100%',
              flex: 1,
              justifyContent: 'center',
            }}
            maxWidth='475px'
            content={[infoForm, form]}
          />
        </Box>
      </Box>
    </>
  );
};
