export const DEFAULT_SYMBOL = {
  symbol: '',
  baseAsset: '',
  quoteAsset: '',
  quoteAssetPrecision: 8,
  baseAssetPrecision: 8,
  priceMin: '0',
  priceMax: '999999999',
  lotMin: '0',
  lotMax: '999999999',
  minNotional: '0',
  maxNotional: '999999999',
} as const;
