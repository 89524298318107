import { Stack } from '@mui/material';
import { Modal } from 'antd';
import { getCurrentBot } from 'entities/bot/model/selectors/get-current-bot/get-current-bot';
import { chooseAccountActions } from 'entities/choose-account';
import { ModalPanel } from 'pages/trading-bots/configurator-v2/views/configure-risk-managment/components/panel/views';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDebouncedCallback } from 'use-debounce';
import { LabelInput, MainButton } from 'shared/ui';
import { IBotNameProps } from '../interfaces';
import { BUTTON, modal, modalChildren, WRAPPER } from '../styles';

export const BotName = (props: IBotNameProps) => {
  const {
    isModalOpened,
    handleNameModalState,
    createBotHandler,
  } = props;

  const bot = useSelector(getCurrentBot);
  const [name, setName] = useState<string>(bot?.name || '');

  const dispatch = useDispatch();
  
  const debouncedValue = useDebouncedCallback((value: string) => {
    dispatch(chooseAccountActions.setName(value));
  }, 300);
  
  const handleClose = () => {
    handleNameModalState(false);
  };

  const onChangeHandler = (value: string) => {
    setName(value);
    debouncedValue(value);
  };

  return (
    <Modal
      open={isModalOpened}
      footer={null}
      style={modal}
      styles={modalChildren}
      closable={true}
      destroyOnClose={true}
      onCancel={handleClose}
      width='100%'
      centered={true}
    >
      <Stack sx={WRAPPER}>
        <ModalPanel />

        <LabelInput
          label='Name your bot'
          value={name}
          placeholder='My perfect bot'
          onChange={onChangeHandler}
        />

        <MainButton
          type='primary'
          styles={BUTTON}
          onClick={createBotHandler}
        >
          Confirm
        </MainButton>
      </Stack>
    </Modal>
  );
};
