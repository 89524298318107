import createAxiosInstance from 'shared/api/axios/axios';
import { HttpMethod } from 'shared/api/axios/types/http-method';

const axios = createAxiosInstance({
  method: HttpMethod.Post,
});  

interface IPublishBotParams {
  botUuid: string;
  minInvestment: string;
  itemName: string;
  description: string;
  author: string;
  copyCommissionPercent?: string;
  isDeleted?: boolean;
}
export const publishBot = async (params: IPublishBotParams) => {
  const updatedBot = await axios.request({
    url: '/bot/updateBot',
    data: {
      ...params,
      allowSharing: params.isDeleted ? 'FALSE' : 'TRUE',
    },
  });

  return updatedBot.data.data.botUuid;
};
