import { Stack } from '@mui/material';
import { Slider } from 'antd';
import { Input, Text } from 'shared/ui';
import { ICustomSliderProps } from '../interfaces';
import { INNER, WRAPPER } from '../styles';

export const CustomSlider = (props: ICustomSliderProps) => {
  const {
    title,
    slider,
    input,
    disabled,
    step,
    error,
  } = props;

  const inputChangeHandler = (value: string) => {
    const result = step && slider.reversed ? -Math.abs(+value) : Math.abs(+value);
    input.onChange(result);
  };

  const getValue = () => {
    if (!input.value) {
      return '';
    }

    if (step && slider.reversed) {
      return `${-Math.abs(input.value)}`;
    }

    return `${input.value}`;
  };

  return (
    <Stack sx={WRAPPER}>
      {title}
      
      <Stack sx={INNER}>
        <Slider
          defaultValue={slider.defaultValue}
          value={Math.abs(slider.value || 0)}
          onChange={slider.onChange}
          reverse={slider.reversed}
          max={slider.max}
          min={slider.min || 1}
          marks={slider.marks}
          disabled={disabled}
          step={step}
        />

        <Input
          value={getValue()}
          onChange={inputChangeHandler}
          maxWidth={47}
          disabled={disabled}
          type={step ? 'number' : 'text'}
          inputStyles={{
            textAlign: 'center',
            padding: '7px 0',
          }}
        />
      </Stack>

      {error && (
        <Text type='danger'>
          {error}
        </Text>
      )}
    </Stack>
  );
};
