import { TableColumnsType } from 'antd';
import dayjs from 'dayjs';
import { dateSorter, numberSorter, stringSorter } from 'shared/helpers/sorters';
import { SortIcon, Text } from 'shared/ui';

const statuses: any = {
  paid: 'success',
  pending: 'warning',
  deleted: 'danger',
};

export const withdrawalsColumns: TableColumnsType = [{
  title: 'Amount',
  dataIndex: 'payoutAmount',
  key: 'payoutAmount',
  width: 193,
  sorter: (a, b) => numberSorter(a.payoutAmount, b.payoutAmount),
  render: (value: string) => (
    <Text>
      {value}
    </Text>
  ),
  sortIcon: (props) => (
    <SortIcon sortOrder={props.sortOrder} />
  ),
  showSorterTooltip: false,
}, {
  title: 'Transaction',
  dataIndex: 'paymentId',
  key: 'paymentId',
  width: 193,
  render: (value: string) => (
    <Text
      styles={{
        display: 'block',
        maxWidth: '100%',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      }}
    >
      {value}
    </Text>
  ),
  showSorterTooltip: false,
}, {
  title: 'Wallet',
  dataIndex: 'paymentMethod',
  key: 'paymentMethod',
  width: 193,
  render: (value: string) => (
    <Text
      styles={{
        display: 'block',
        maxWidth: '100%',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      }}
    >
      {value}
    </Text>
  ),
  showSorterTooltip: false,
}, {
  title: 'Created date',
  dataIndex: 'createdDate',
  key: 'createdDate',
  width: 193,
  sorter: (a, b) => dateSorter(a.createdDate, b.createdDate),
  render: (value: string) => {
    if (!value) {
      return '-';
    }

    return (
      <Text>
        {dayjs(value).format('DD.MM.YYYY, HH:mm:ss')}
      </Text>
    );
  },
  sortIcon: (props) => (
    <SortIcon sortOrder={props.sortOrder} />
  ),
  showSorterTooltip: false,
}, {
  title: 'Paid date',
  dataIndex: 'paymentDate',
  key: 'paymentDate',
  width: 193,
  sorter: (a, b) => dateSorter(a.paymentDate, b.paymentDate),
  render: (value: string) => {
    if (!value) {
      return '-';
    }

    return (
      <Text>
        {dayjs(value).format('DD.MM.YYYY, HH:mm:ss')}
      </Text>
    );
  },
  sortIcon: (props) => (
    <SortIcon sortOrder={props.sortOrder} />
  ),
  showSorterTooltip: false,
}, {
  title: 'Status',
  dataIndex: 'status',
  key: 'status',
  width: 193,
  sorter: (a, b) => stringSorter(a.status, b.status),
  render: (value: string) => (
    <Text
      styles={{
        textTransform: 'capitalize',
      }}
      type={statuses[value]}
    >
      {value}
    </Text>
  ),
  sortIcon: (props) => (
    <SortIcon sortOrder={props.sortOrder} />
  ),
  showSorterTooltip: false,
}];
