import { Stack } from '@mui/material';
import { Steps } from 'antd';
import { getChooseAccountState } from 'entities/choose-account';
import { getChooseSourceState } from 'entities/choose-source';
import { getRiskManagmentAdditionalState } from 'entities/risk-managment-additional';
import { getRiskManagmentAutoState } from 'entities/risk-managment-auto';
import { getRiskManagmentCloseState } from 'entities/risk-managment-close';
import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getSkyrexUuid } from 'shared/helpers/storage-helper';
import { Text } from 'shared/ui';
import { createBot, updateBot } from '../api';
import { BotName } from '../components';
import { ICreateBot } from '../interfaces';
import { STEP, WRAPPER } from '../styles';
import { AlertMessage, ChooseAccount, ChooseSource, ConfigureRiskManagment } from '../views';

export const Configurator = () => {
  const chooseAccountState = useSelector(getChooseAccountState);
  const chooseSourceState = useSelector(getChooseSourceState);
  const additionalEntryOrdersState = useSelector(getRiskManagmentAdditionalState);
  const closeOrdesState = useSelector(getRiskManagmentCloseState);
  const autoState = useSelector(getRiskManagmentAutoState);

  const [isModalOpened, setIsModalOpened] = useState<boolean>(false);
  const [currentStep, setCurrentStep] = useState<number>(0);

  const navigate = useNavigate();
  const {
    id, 
  } = useParams();

  const createBotHandler = async () => {
    const isBaseOrderError = chooseAccountState.market.minNotional > (autoState.values.baseOrderSize || 0);
    if (autoState.segment === 'auto' ? isBaseOrderError : false) {
      toast.error('Order size is below the minimum');
      return;
    }

    const params: ICreateBot = {
      name: chooseAccountState.name as string,
      skyrexUserUuid: getSkyrexUuid() as string,
      exchangeAccountUuid: chooseAccountState.exchange.id,
      botType: 'CUSTOM',
      allowSharing: 'FALSE',
      exchangeCode: chooseAccountState.exchange.code,
      quote: chooseAccountState.market.code,
      minInvestment: '0',
      copyCommissionPercent: 0,
      copyCommissionFlat: 0,
      tradingAmount: +chooseAccountState.tradingAmount,
      maxActiveDeals: +chooseAccountState.activeTradingPairs,
      martingaleVolumeCoefficient: 1,
      maxSafetyOrders: 0,
      priceDeviationPercentSafety: 0,
      martingalePriceStepCoefficient: 0,
      strategy: 'LONG',
      baseOrderType: chooseSourceState.orderType.toUpperCase(),
      baseOrderConditionalTriggerType: 'LAST',
      additionalBaseOrderType: null,
      additionalBaseOrderConditionalTriggerType: 'LAST',
      takeProfitPriceRecalculation: 'FROM_AVERAGE',
      takeProfitType: null,
      takeProfitConditionalTriggerType: 'LAST',
      takeProfitPricePercent: 0,
      takeProfitPriceStepCoefficient: 1,
      takeProfitTargetsQuantity: 0,
      takeProfitVolumeStepCoefficient: 1,
      stopLossPriceRecalculation: 'FROM_AVERAGE',
      stopLossType: null,
      stopLossConditionalTriggerType: 'LAST',
      stopLossPercent: 0,
      alertAdditionalBaseOrderType: null,
      alertCloseType: null,
      signalSource: chooseSourceState.alertSource,
    };

    if (additionalEntryOrdersState.isOpened && additionalEntryOrdersState.segment.value === 'preset') {
      const preset = additionalEntryOrdersState.segment.preset;

      params.additionalBaseOrderType = `CONDITIONAL_${preset.orderType.toUpperCase()}`;
      params.martingaleVolumeCoefficient = preset.ordersSizeScale;
      params.maxSafetyOrders = preset.orders;
      params.priceDeviationPercentSafety = preset.priceChange;
      params.martingalePriceStepCoefficient = preset.ordersPriceChangeScale;
    }

    if (additionalEntryOrdersState.isOpened && additionalEntryOrdersState.segment.value === 'alert') {
      const alert = additionalEntryOrdersState.segment.alert;

      params.alertAdditionalBaseOrderType = `CONDITIONAL_${alert.orderType.toUpperCase()}`;
      params.martingaleVolumeCoefficient = alert.ordersSizeScale;
      params.maxSafetyOrders = alert.orders;
    }

    const isCloseOrdersPreset = closeOrdesState.segment.value === 'preset';
    if (!isCloseOrdersPreset && autoState.segment !== 'auto') {
      params.alertCloseType = closeOrdesState.segment.alert.orderType.toUpperCase();
    }

    if (isCloseOrdersPreset && closeOrdesState.segment.preset.takeProfit.isOpened) {
      const takeProfit = closeOrdesState.segment.preset.takeProfit;

      params.takeProfitType = takeProfit.orderType === 'market' ? `CONDITIONAL_${takeProfit.orderType.toUpperCase()}` : takeProfit.orderType.toUpperCase();
      params.takeProfitPricePercent = takeProfit.priceChange;
      params.takeProfitPriceStepCoefficient = takeProfit.orderPriceChangeScale;
      params.takeProfitTargetsQuantity = takeProfit.orders;
      params.takeProfitVolumeStepCoefficient = takeProfit.orderSizeScale;
    }

    if (isCloseOrdersPreset && closeOrdesState.segment.preset.stopLoss.isOpened) {
      const stopLoss = closeOrdesState.segment.preset.stopLoss;

      params.stopLossType = `CONDITIONAL_${stopLoss.orderType.toUpperCase()}`;
      params.stopLossPercent = stopLoss.priceChange;
    }

    if (autoState.segment === 'auto') {
      params.settingsType = autoState.preset;

      params.martingaleVolumeCoefficient = autoState.values.additionalOrderSizeScale;
      params.maxSafetyOrders = autoState.values.maxAdditionalOrders;
      params.priceDeviationPercentSafety = autoState.values.additionalOrderPriceChange;
      params.martingalePriceStepCoefficient = autoState.values.additionalOrderPriceChangeScale;
      params.takeProfitPricePercent = autoState.values.taketProfitPercent;
      params.stopLossPercent = autoState.values.stopLossPercent;
      params.additionalBaseOrderType = 'CONDITIONAL_MARKET';
      params.alertAdditionalBaseOrderType = 'CONDITIONAL_MARKET';
      params.stopLossType = 'CONDITIONAL_MARKET';
      params.takeProfitType = 'CONDITIONAL_MARKET';
    }

    if (id) {
      params.botUuid = id;
    }
    
    const response = await (id ? updateBot(params) : createBot(params));
    if (!response.success) {
      toast.error(response.data.message);
      return;
    }

    navigate(`/trading-bots/my-bots/bot/${response.data.fields.botUuid}`);
  };
  
  const handleModalDisplay = () => {
    setIsModalOpened((prev) => !prev);
  };

  const changeCurrentStep = useCallback(() => {
    setCurrentStep((prev) => {
      return prev += 1;
    });
  }, []);
  
  const changeDeffineStep = useCallback((step: number) => {
    return () => {
      if (!chooseAccountState.tradingAmount) {
        return;
      }

      setCurrentStep(step);
    };
  }, [chooseAccountState.tradingAmount]);

  const handleBack = useCallback(() => {
    setCurrentStep((prev) => {
      return prev -= 1;
    });
  }, []);

  const getContent = () => {
    switch (currentStep) {
    case 0:
      return (
        <ChooseAccount changeCurrentStep={changeCurrentStep} />
      );
    case 1:
      return (
        <ChooseSource
          changeCurrentStep={changeCurrentStep}
          handleBack={handleBack}
        />
      );
    case 4:
      return (
        <AlertMessage
          createBot={handleModalDisplay}
          handleBack={handleBack}
        />
      );
    default:
      return (
        <ConfigureRiskManagment
          changeCurrentStep={changeCurrentStep}
          handleBack={handleBack}
          currentStep={currentStep}
          createBot={handleModalDisplay}
        />
      );
    }
  };

  const getSteps = useCallback(() => {
    const steps = [{
      title: (
        <Text
          styles={STEP}
          type={currentStep === 0 ? 'success' : undefined}
          onClick={changeDeffineStep(0)}
        >
          Step 1 - Set account, market and amount
        </Text>
      ),
    }, {
      title: (
        <Text
          styles={STEP}
          type={currentStep === 1 ? 'success' : undefined}
          onClick={changeDeffineStep(1)}
        >
          Step 2 - Configure entry order alerts
        </Text>
      ),
    }, {
      title: (
        <Text
          styles={STEP}
          type={currentStep === 2 ? 'success' : undefined}
          onClick={changeDeffineStep(2)}
        >
          Step 3 - Set overlap and close orders
        </Text>
      ),
    }];

    if (autoState.segment === 'auto') {
      return steps;
    }

    if (autoState.segment === 'custom' && chooseSourceState.alertSource === 'strategy') {
      return [...steps, {
        title: (
          <Text
            styles={STEP}
            type={currentStep === 3 ? 'success' : undefined}
            onClick={changeDeffineStep(3)}
          >
            Step 4 - Configure close orders
          </Text>
        ),
      }, {
        title: (
          <Text
            styles={STEP}
            type={currentStep === 4 ? 'success' : undefined}
            onClick={changeDeffineStep(4)}
          >
            Step 5 - Create alert message
          </Text>
        ),
      }];
    }

    return [...steps, {
      title: (
        <Text
          styles={STEP}
          type={currentStep === 3 ? 'success' : undefined}
          onClick={changeDeffineStep(3)}
        >
          Step 4 - Configure close orders
        </Text>
      ),
    }];
  }, [
    autoState.segment,
    currentStep,
    chooseSourceState.alertSource,
  ]);

  return (
    <Stack sx={WRAPPER}>
      <Steps
        progressDot={true}
        current={currentStep}
        size='small'
        items={getSteps()}
        responsive={true}
      />

      {getContent()}

      <BotName
        isModalOpened={isModalOpened}
        handleNameModalState={setIsModalOpened}
        createBotHandler={createBotHandler}
      />
    </Stack>
  );
};
