import { CSSProperties } from 'react';
import { getFontSize } from 'shared/helpers';

export const TEXT: CSSProperties = {
  fontSize: getFontSize(12, 30),
  fontWeight: 400,
  lineHeight: '24px',
  color: '#3A3A3C',
  width: 72,
  textAlign: 'center',
};
