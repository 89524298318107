import { CSSProperties } from 'react';

export const BUTTON: CSSProperties = {
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  fontSize: 12,
  fontWeight: 500,
  lineHeight: 'normal',
  width: '100%',
  textAlign: 'center',
  justifyContent: 'center',
  padding: '4px 8px',
};
