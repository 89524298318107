import { initializeTradingData } from 'entities/new-terminal/model/services/initialize-trading-data';
import { updateExchangeData } from 'entities/new-terminal/model/services/update-trading-data';
import { newTerminalActions } from 'entities/new-terminal/model/slice/new-terminal-slice';
import { getIsDemoMode } from 'entities/user/model/selectors/get-is-demo-mode/get-is-demo-mode';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'app/providers/store-provider/config/store';

export const useTrading = () => {
  const dispatch = useDispatch();
  
  const isDemoMode = useSelector(getIsDemoMode);
  
  const { 
    exchanges,
    selectedExchange,
    financeData,
    snapshots,
    symbolCodes,
    marketOptions,
    selectedMarket,
    tradingPairs,
    selectedTradingPair,
    currentSymbol,
    chartSymbol,
    allExchangeSymbols,
    isLoading,
    error, 
    userWalletBalances,
    userWalletQuoteAssetBalance,
    userWalletBaseAssetBalance,
    dailyChange,
    chosenExchange,
  } = useSelector((state: RootState) => state.newTerminal);

  useEffect(() => {
    dispatch(initializeTradingData(isDemoMode));
  }, [dispatch, isDemoMode]);

  const handleExchangeSelect = async (exchangeUuid: string) => {
    const selectedExchange = exchanges.find(ex => ex.value === exchangeUuid);

    if (selectedExchange) {
      dispatch(newTerminalActions.setSelectedExchange(selectedExchange));
      
      dispatch(updateExchangeData({
        uuid: selectedExchange.value,
        exchangeCode: selectedExchange.exchangeCode,
        previousMarket: selectedMarket,
        selectedExchange,
        selectedTradingPair,
      }));
    }
  };
  
  const handleMarketSelect = async (market: any) => {
    //TODO: переписать маппинг торговых пар, чтобы идентифицировать по value
    function getCurrency(symbol: string) {
      return symbol.split('-')[1];
    }
    
    const currency = getCurrency(market);
    
    const selectedMarket = marketOptions.find((option: any) => option.label === currency);
    
    dispatch(newTerminalActions.updateMarket({
      market: selectedMarket,
      allExchangeSymbols,
      userWallet: userWalletBalances,
    }));
  };
  
  
  const handleTradingPairSelect = (tradingPairValue: string) => {
    //TODO: переписать маппинг торговых пар, чтобы идентифицировать по value
    const selectedPair = tradingPairs.find(
      pair => pair.value === tradingPairValue,
    );
    
    if (selectedPair) {
      const symbol = allExchangeSymbols.find(
        el => el.symbol === selectedPair.formattedPair,
      );

      dispatch(newTerminalActions.setTradingPair({
        tradingPair: selectedPair,
        currentSymbol: symbol || null,
        userWalletBalance: userWalletBalances,
      }));
    }
  };

  return {
    exchanges,
    selectedExchange,
    financeData,
    snapshots,
    symbolCodes,
    marketOptions,
    selectedMarket,
    tradingPairs,
    dailyChange,
    selectedTradingPair,
    currentSymbol,
    chartSymbol,
    isLoading,
    error,
    handleExchangeSelect,
    handleMarketSelect,
    userWalletBalances,
    userWalletQuoteAssetBalance,
    userWalletBaseAssetBalance,
    chosenExchange,
    handleTradingPairSelect,
  };
};
