import { SxProps, Theme } from '@mui/material';

export const LIST = (isLoading: boolean) => {
  const styles: SxProps<Theme> = {
    display: 'grid',
    gridTemplateColumns: {
      xs: '1fr',
      sm: 'repeat(auto-fill, minmax(318px, 1fr))',
    },
    gap: '30px 20px',
    height: '100dvh',
    maxHeight: (387 * 2) + 100,
    overflowX: 'hidden',
    padding: '10px',
    position: 'relative',
  };

  if (isLoading) {
    styles.overflow = 'hidden';
  }

  return styles;
};
