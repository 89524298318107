import { fetchSymbols } from 'entities/exchange-symbols/model/services/fetch-symbols';
import { useCallback } from 'react';
import { formatSymbolPrice, calculatePrice } from 'shared/helpers/symbol-formatter';
import { useAppDispatch, useAppSelector } from 'app/providers/store-provider/config/store';

// Объект для отслеживания запущенных запросов на уровне приложения
const pendingRequests: Record<string, Promise<any>> = {};

export const useSymbolFormatter = (exchange: string) => {
  const dispatch = useAppDispatch();

  const symbols = useAppSelector(state => state.symbols.data[exchange] || []);
  const loading = useAppSelector(state => state.symbols.loading[exchange] || false);
  const error = useAppSelector(state => state.symbols.error[exchange]);

  const loadSymbols = useCallback(() => {
    // Если запрос уже выполняется, возвращаем существующий промис
    if (exchange in pendingRequests) {
      return pendingRequests[exchange];
    }

    // Если данные уже есть, не делаем запрос
    if (symbols.length > 0) {
      return Promise.resolve();
    }

    // Создаём новый запрос и сохраняем его промис
    const request = dispatch(fetchSymbols(exchange))
      .finally(() => {
        // Очищаем промис после завершения запроса
        delete pendingRequests[exchange];
      });

    pendingRequests[exchange] = request;
    return request;
  }, [dispatch, exchange, symbols.length]);

  const formatPrice = useCallback((
    symbol: string,
    value: number,
  ) => formatSymbolPrice(symbols, symbol, value), [symbols]);

  const formatTradePrice = useCallback((
    baseSymbol: string,
    quoteSymbol: string,
    executedQuote: number,
    executedBase: number,
  ) => {
    const symbol = `${baseSymbol}${quoteSymbol}`;
    return calculatePrice(executedQuote, executedBase, symbols, symbol);
  }, [symbols]);
  
  return {
    symbols,
    loading,
    error,
    formatPrice,
    formatTradePrice,
    loadSymbols,
  };
};
