import { EXCHANGE_NAMES } from 'shared/consts/available-exchanges';
import { fetchBinanceBars, fetchBybitBars, fetchOkxBars, fetchGateBars, fetchCryptocomBars, fetchHtxBars, fetchBitmartBars, fetchKucoinBars } from '../api/get-exchange-bars';

interface GetBarsProps {
    symbol: any;
    fromDate: any;
    toDate: any;
    chartInterval: any;
    limit: any;
    exchange: any;
    instId?: any;
    gateSymbol?: any;
    cryptoCompareSymbol: string;
}

export async function getBars(dataForFetch: GetBarsProps) {
  const {
    exchange,
  } = dataForFetch;
  
  let ohlcv;
  
  switch (exchange.toLowerCase()) {
  case EXCHANGE_NAMES.BINANCE:
    ohlcv = await fetchBinanceBars(dataForFetch);
    break;
  case EXCHANGE_NAMES.BYBIT:
    ohlcv = await fetchBybitBars(dataForFetch);
    break;
  case EXCHANGE_NAMES.OKX:
    ohlcv = await fetchOkxBars(dataForFetch);
    break;
  case EXCHANGE_NAMES.GATEIO:
    ohlcv = await fetchGateBars(dataForFetch);
    break;
  case EXCHANGE_NAMES.CRYPTO_COM:
    ohlcv = await fetchCryptocomBars(dataForFetch);
    break;
  case EXCHANGE_NAMES.BITMART:
    ohlcv = await fetchBitmartBars(dataForFetch);
    break;
  case EXCHANGE_NAMES.HTX:
    ohlcv = await fetchHtxBars(dataForFetch);
    break;
  case EXCHANGE_NAMES.KUCOIN:
    ohlcv = await fetchKucoinBars(dataForFetch);
    break;
  default:
    throw new Error(`Exchange ${exchange} is not supported.`);
  }
  
  return ohlcv;
}
  
