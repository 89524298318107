import { SxProps, Theme } from '@mui/material';

export const SEGMENT_WRAPPER = (maxWidth?: number) => {
  const styles: SxProps<Theme> = {
    flexDirection: 'row',
    alignItems: 'center',
    gap: '4px',
    flex: 1,
    maxWidth: maxWidth || 240,
  };

  return styles;
};
