import { CSSProperties } from 'react';

export const BUTTON: CSSProperties = {
  height: 'auto',
  padding: '12px',
  width: '100%',
  flex: 1,
  fontSize: 14,
  fontWeight: 500,
  lineHeight: 'normal',
};
