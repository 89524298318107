import { 
  fetchBinanceExchangeInfo, 
  fetchBybitExchangeInfo,
  fetchOkxExchangeInfo,
  fetchGateIOExchangeInfo,
  fetchCryptoComExchangeInfo,
  fetchBitmartExchangeInfo,
  fetchHtxExchangeInfo,
  fetchKucoinExchangeInfo,
} from 'shared/helpers/exchange-symbols-request-config';

export async function getExchangeSymbols(exchangeName: string) {
  switch (exchangeName.toLowerCase()) {
  case 'binance':
    return await fetchBinanceExchangeInfo();
  case 'bybit':
    return await fetchBybitExchangeInfo();
  case 'okx':
    return await fetchOkxExchangeInfo();
  case 'gateio':
    return await fetchGateIOExchangeInfo();
  case 'crypto-com':
    return await fetchCryptoComExchangeInfo();
  case 'demo':
    return await fetchBinanceExchangeInfo();
  case 'bitmart':
    return await fetchBitmartExchangeInfo();
  case 'htx':
    return await fetchHtxExchangeInfo();
  case 'kucoin':
    return await fetchKucoinExchangeInfo();
  default:
    throw new Error(`Exchange ${exchangeName} is not supported.`);
  }
}
