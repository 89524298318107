// import { Bot } from 'pages/trading-bots/marketplace/types/bots.types';
import { enrichBotsWithStatistic } from 'pages/trading-bots/marketplace/api/enrich-bots-with-statistics';
import createAxiosInstance from 'shared/api/axios/axios';
import { HttpMethod } from 'shared/api/axios/types/http-method';
import { getSkyrexUuid } from 'shared/helpers/storage-helper';

const axios = createAxiosInstance({
  method: HttpMethod.Post,
});  

export const getUserPublicBots = async () => {
  const allPublicBots = await axios.request({
    url: '/bot/getByFilterBot',
    data: {
      skyrexUserUuids: [getSkyrexUuid()],
      botType: 'CUSTOM',
      statuses: [
        'on',
        'off',
      ],
    },
  });
    
  const botsEnrichedWithStatistics = await enrichBotsWithStatistic(allPublicBots.data.data.bots);
  const sortedBots = botsEnrichedWithStatistics.sort((a, b) => b.activeCopyBots - a.activeCopyBots);
  
  return sortedBots;
};
