import React from 'react';
import { ProgressMarkProps } from '../active-trade/types/active-trade.types';
import {
  getMarkStyles,
  getLineStyles,
  getLabelValueStyles,
} from './progress-mark.styles';
import {
  getLabelStyles,
  getValueStyles,
} from './utils';

const ProgressMark: React.FC<ProgressMarkProps> = React.memo(({
  label,
  value,
  isExchangePrice = false,
  distance,
  visible = true,
  isEntryPrice = false,
  isHighestPrice = false,
  isLowestPrice = false,
  highestPrice,
  lowestPrice,
}) => {
  const styleParams = {
    isExchangePrice,
    distance,
    visible,
    isEntryPrice,
    label,
    isHighestPrice,
    isLowestPrice,
    highestPrice,
    lowestPrice,
    value,
  };

  return (
    <div style={getMarkStyles(styleParams)}>
      <div style={getLineStyles(styleParams)} />
      <div style={getLabelValueStyles(styleParams)}>
        <span style={getLabelStyles(styleParams)}>
          {isExchangePrice ? `${label} %` : label}
        </span>
        {visible && <span style={getValueStyles()}>{value}</span>}
      </div>
    </div>
  );
});

ProgressMark.displayName = 'ProgressMark';

export default ProgressMark;
