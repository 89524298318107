import { SxProps, Theme } from '@mui/material';

export const BALANCE: SxProps<Theme> = {
  flexDirection: 'row',
  gap: '4px',
  alignItems: 'center',
  '&>span': {
    fontSize: 13,
    fontWeight: 400,
    lineHeight: 'normal',
    color: '#3A3A3C',
    '&:last-of-type': {
      color: '#9E9E9E',
    },
  },
};
