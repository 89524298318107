import { SxProps, Theme } from '@mui/material';

export const STATISTIC_BLOCK: SxProps<Theme> = {
  padding: '34px 24px',
  borderRadius: '16px',
  backgroundColor: '#F8FBF9',
  flexDirection: {
    xs: 'column',
    lg: 'row',
  },
  alignItems: {
    xs: 'initial',
    lg: 'center',
  },
  gap: {
    xs: '8px',
    sm: '25px',
  },
};
