import { BinanceLogo, BitMartLogo, ByBitLogo, CryptoIcon, GateLogo, HtxLogo, KucoinIcon, OkxLogo } from '../icons';

export const platfroms = [{
  icon: BinanceLogo,
  title: 'binance',
}, {
  icon: OkxLogo,
  title: 'okx',
}, {
  icon: ByBitLogo,
  title: 'bybit',
}, {
  icon: GateLogo,
  title: 'gateio',
}, {
  icon: CryptoIcon,
  title: 'crypto',
}, {
  icon: BitMartLogo,
  title: 'bitmart',
}, {
  icon: HtxLogo,
  title: 'htx',
}, {
  icon: KucoinIcon,
  title: 'kucoin',
}];
