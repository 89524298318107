import { createAsyncThunk } from '@reduxjs/toolkit';
import { setShortExchangesData } from 'entities/exchange/helpers/set-short-exchanges-data';
import { WalletData } from 'entities/terminal/model/types/terminal-schema';
import { calculateMarketOptions, getFilteredTradingPairs, setDefaultTradingPairAndSymbol } from 'pages/manual-trading/trading-terminal/helpers/trading-data-symbols-utils';
import { getUniqueSymbolCodes } from 'pages/manual-trading/trading-terminal/helpers/trading-data-symbols-utils';
import { getTerminalSnapshotsData } from 'pages/trading-terminal-page/order-panel/ExchangeForm/helpers/get-snapshots-terminal-page';
import { getExchangeSymbols } from 'pages/trading-terminal-page/trading-chart/helpers/get-symbols';
import { getExchanges } from 'widgets/exchanges/api/get-exchanges';
import { getDemoAccount } from 'shared/helpers';
import { getSkyrexUuid } from 'shared/helpers/storage-helper';

const EMPTY_BALANCE = {
  free: '0',
};

export const initializeTradingData = createAsyncThunk(
  'trading/initializeData', 
  async (isDemoMode: boolean, params) => {
    const {
      rejectWithValue, 
    } = params;

    try {
      const skyrexUserUuid = getSkyrexUuid();
      
      // TODO: поменять название метода, 
      //оптимизировать с остальными похожими методами в платформе
      const exchangesResponse = await setShortExchangesData(skyrexUserUuid ?? '');

      const filteredAccounts = getDemoAccount(exchangesResponse, isDemoMode);

      const allExchangeAccountUuids = filteredAccounts.map((exchange: any) => exchange.exchangeAccountUuid);
      
      // Получаем данные балансов для всех бирж
      const allExchangesFinanceData = await getExchanges(allExchangeAccountUuids);
      
      const balancesMap = allExchangesFinanceData.data.accounts.reduce((acc: any, account: any) => {
        acc[account.exchangeAccountUuid] = account.balances;
        return acc;
      }, {});

      const sortedAccounts = filteredAccounts.sort((a: any, b: any) => {
        const aBalance = balancesMap[a.exchangeAccountUuid]?.find((el: any) => el.symbol === 'USDT')?.free || '0';
        const bBalance = balancesMap[b.exchangeAccountUuid]?.find((el: any) => el.symbol === 'USDT')?.free || '0';
        return Number(bBalance) - Number(aBalance); // сортировка по убыванию
      });
      
      const transformedExchanges = sortedAccounts.map((exchange: any) => ({
        label: exchange.accountName,
        value: exchange.exchangeAccountUuid,
        exchangeCode: exchange.exchangeCode,
        balances: balancesMap[exchange.exchangeAccountUuid] || [],
      }));
  
      const defaultExchange = transformedExchanges[0];
  
      if (!defaultExchange) {
        throw new Error('No exchanges available');
      }
  
      //@TODO переписать на утилиту с кешированием
      const exchangeSymbols = await getExchangeSymbols(defaultExchange.exchangeCode);
      const uniqueSymbolCodes = getUniqueSymbolCodes(exchangeSymbols);
      
      // TODO: переименовать метод, оптимизировать с остальными похожими методами в платформе
      const financeData = await getExchanges([defaultExchange.value]);
        
      const chosenExchange = financeData?.data?.accounts[0];
      
      const userWalletBalances = chosenExchange.balances;
      
      const marketOptions = calculateMarketOptions(
        chosenExchange.balances,
        uniqueSymbolCodes,
      );
  
      const defaultMarket = 
      // TODO: add type
          marketOptions.find((el: any) => el.label === 'USDT') || 
          marketOptions[0];
  
      const tradingPairs = getFilteredTradingPairs(
        exchangeSymbols,
        defaultMarket,
        chosenExchange.balances,
      );
      
      const {
        defaultTradingPair, currentSymbol, 
      } = setDefaultTradingPairAndSymbol(
        tradingPairs,
        exchangeSymbols,
      );
    
      
      //@TODO если баланс 0 то сеттится в undefined - решить централизовано - подставлять обхект с free: '0' если нет баланса
      const userWalletQuoteAssetBalance = userWalletBalances.find((el: WalletData) => el.symbol === currentSymbol?.quoteAsset) || EMPTY_BALANCE;
      const userWalletBaseAssetBalance = userWalletBalances.find((el: WalletData) => el.symbol === currentSymbol?.baseAsset) || EMPTY_BALANCE;
      
      //TODO зачем 3 параметра? оптимизировать до 2х
      const dailyChange = await getTerminalSnapshotsData(chosenExchange, skyrexUserUuid ?? '', chosenExchange.exchangeAccountUuid);

      return {
        exchanges: transformedExchanges,
        selectedExchange: defaultExchange,
        financeData,
        dailyChange,
        symbolCodes: uniqueSymbolCodes,
        marketOptions,
        selectedMarket: defaultMarket,
        tradingPairs,
        chartSymbol: currentSymbol,
        selectedTradingPair: defaultTradingPair,
        currentSymbol,
        userWalletBalances,
        allExchangeSymbols: exchangeSymbols,
        userWalletQuoteAssetBalance,
        userWalletBaseAssetBalance,
        chosenExchange,
      };
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.message);
    }
  },
);
