import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useQuery } from 'shared/hooks';
import { Loader } from 'shared/ui';

function middleOAuthCryptoComComponent() {
  return function OauthComponent() {
    const navigate = useNavigate();
    const location = useLocation();
    const params = useQuery();

    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
      const segments = location.pathname.split('/');

      const state = params?.get('state');
      const code = params?.get('code');

      const method = state?.split('-')[0];

      navigate(`/oauth/${method}/${segments.pop()}?code=${code}`);
      setLoading(false);
    }, [location, params, navigate]);

    if (loading) {
      return (
        <Loader isContentOverflow={true} />
      );
    }

    return null;
  };
}

export const MiddleOauthKucoin = middleOAuthCryptoComComponent();
