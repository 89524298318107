import { useEffect } from 'react';
import { Fragment } from 'react/jsx-runtime';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { IPageWrapper } from '../interfaces';

export const PageWrapper = (props: IPageWrapper) => {
  const {
    title,
    desciption,
    children,
  } = props;

  useEffect(() => {
    document.title = title;
    
    if (desciption) {
      document.querySelector('meta[name=\'description\']')?.setAttribute('content', desciption);
    }
  }, [title, desciption]);

  return (
    <HelmetProvider>
      <Fragment>
        <Helmet>
          <title>{title}</title>

          <meta
            name='description'
            content={desciption || ''}
          />
        </Helmet>

        {children}
      </Fragment>
    </HelmetProvider>
  );
};
