import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { WalletData } from 'entities/terminal/model/types/terminal-schema';
import { getFilteredTradingPairs } from 'pages/manual-trading/trading-terminal/helpers/trading-data-symbols-utils';
import { addFundsThunk } from '../services/add-funds';
import { addNewTrade } from '../services/add-new-trade';
import { editTradeUpdate } from '../services/edit-trade-update';
import { initializeTradingData } from '../services/initialize-trading-data';
import { reduceFundsThunk } from '../services/reduce-funds';
import { setActiveTrades } from '../services/set-active-trades';
import { updateExchangesBalances } from '../services/update-exchange-balances';
import { updateTrade } from '../services/update-trade';
import { updateExchangeData } from '../services/update-trading-data';
import { ChosenExchangeSymbolDetails, NewTerminalSchema, SmartTrade } from '../types/new-terminal-schema';

//@ts-ignore
const initialState: NewTerminalSchema = {
  isLoading: false,
  shouldScrollToPanel: false,
  activeTrades: [] as SmartTrade[],
  editingTrade: null,
  error: '',
  //@TODO использовать как основной источник правды
  chosenExchangeSymbolDetails: {
    quoteAsset: 'USDT',
    baseAsset: 'BTC',
    quoteAssetPrecision: 8,
    baseAssetPrecision: 8,
    minNotional: '0',
    maxNotional: '0',
    lotMin: '0',
    lotMax: '0',
    symbol: '',
  } as ChosenExchangeSymbolDetails,
  smartTrade: {} as SmartTrade,
  limitLastPrice: 0,
  chartLastPrice: 0,
  userWalletQuoteAssetBalance: {
    free: '0',
    locked: '0',
  } as WalletData,
  userWalletBaseAssetBalance: {
    free: '0',
    locked: '0',
  } as WalletData,
  exchanges: [],
  userWalletBalances: [],
  chartSymbol: {
    //@TODO зависимость округлений chart зависит от chartSymbol
    quoteAsset: 'USDT',
    baseAsset: 'BTC',
    quoteAssetPrecision: 8,
    baseAssetPrecision: 8,
    minNotional: '0',
    maxNotional: '0',
    lotMin: '0',
    lotMax: '999999999',
    priceMin: '0',
    priceMax: '999999999',
    symbol: '',
  },
  selectedExchange: {},
  financeData: {},
  snapshots: [],
  currentSymbol: {
    quoteAsset: 'USDT',
    baseAsset: 'BTC',
    quoteAssetPrecision: 8,
    baseAssetPrecision: 8,
    minNotional: '0',
    maxNotional: '0',
    lotMin: '0',
    lotMax: '0',
    symbol: '',
  },
  symbolCodes: [],
  marketOptions: [],
  tradingPairs: [],
  selectedMarket: {},
  selectedTradingPair: {},
  allExchangeSymbols: [],
};

export const newTerminalSlice = createSlice({
  name: 'newTerminal',
  initialState,
  reducers: {
    scrollToPanel(state) {
      state.shouldScrollToPanel = true;
    },
    resetScroll(state) {
      state.shouldScrollToPanel = false;
    },
    setSelectedExchange: (state, action) => {
      state.selectedExchange = action.payload;
    },
    setChartLastPrice: (state, action: PayloadAction<number>) => {
      state.chartLastPrice = action.payload;
    },
    setCurrentSymbol: (state, action: PayloadAction<any>) => {
      state.currentSymbol = action.payload;
    },
    setTradingPair: (state, action: PayloadAction<{ 
      tradingPair: any; 
      currentSymbol: any; 
      userWalletBalance: any;
    }>) => {
      const newBaseAssetBalance = action.payload.userWalletBalance.find((el: any) => el.symbol === action.payload.currentSymbol.baseAsset);
      
      state.userWalletBaseAssetBalance = newBaseAssetBalance;
      state.selectedTradingPair = action.payload.tradingPair;
      state.chartSymbol = action.payload.currentSymbol;
      state.chosenExchangeSymbolDetails = action.payload.currentSymbol;
    },
    setEditingTrade: (state, action: PayloadAction<any>) => {
      state.editingTrade = action.payload;
    },
    resetEditingTrade: (state) => {
      state.editingTrade = null;
    },
    updateMarket: (state, action: PayloadAction<{
      market: any;
      allExchangeSymbols: any[];
      userWallet: any[];
    }>) => {
      const {
        market, allExchangeSymbols, userWallet, 
      } = action.payload;

      // Получаем новые торговые пары
      const tradingPairs = getFilteredTradingPairs(
        allExchangeSymbols,
        market,
        userWallet,
      );

      const defaultTradingPair = 
        tradingPairs.find((pair: any) => pair.formattedPair === 'BTCUSDT') || 
        tradingPairs[0];

      const currentSymbol = defaultTradingPair 
        ? allExchangeSymbols.find(el => el.symbol === defaultTradingPair.formattedPair)
        : null;
        
      const newBaseAssetBalance = currentSymbol 
        ? userWallet.find((el: any) => el.symbol === currentSymbol.baseAsset) || {
          free: '0',
        }
        : null;
        
      const newQuoteAssetBalance = currentSymbol 
        ? userWallet.find((el: any) => el.symbol === currentSymbol.quoteAsset) || {
          free: '0',
        }
        : null;

      state.tradingPairs = tradingPairs;
      state.selectedTradingPair = defaultTradingPair;
      state.currentSymbol = currentSymbol;
      state.chartSymbol = currentSymbol;
      state.selectedMarket = market;
      state.chosenExchangeSymbolDetails = currentSymbol;
      state.userWalletBaseAssetBalance = newBaseAssetBalance;
      state.userWalletQuoteAssetBalance = newQuoteAssetBalance;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(setActiveTrades.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(setActiveTrades.fulfilled, (state, action) => {
        state.isLoading = false;
        state.activeTrades = action.payload;
      })
      .addCase(setActiveTrades.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload ?? 'Unknown error';
      })
    
      .addCase(addFundsThunk.pending, (state) => {
        state.isLoading = true;
        state.error = '';
      })
      .addCase(addFundsThunk.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(addFundsThunk.rejected, (state) => {
        state.isLoading = false;
        state.error = 'Add funds error';
      })
      
      .addCase(reduceFundsThunk.pending, (state) => {
        state.isLoading = true;
        state.error = '';
      })
      .addCase(reduceFundsThunk.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(reduceFundsThunk.rejected, (state) => {
        state.isLoading = false;
        state.error = 'Reduce funds error';
      })
      
      .addCase(updateExchangesBalances.fulfilled, (state, action) => {
        // Обновляем балансы в transformedExchanges
        state.exchanges = state.exchanges.map(exchange => ({
          ...exchange,
          balances: action.payload[exchange.value] || [],
        }));
      })
       
      
      .addCase(editTradeUpdate.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(editTradeUpdate.fulfilled, (state, action) => {
        state.isLoading = false;
        state.activeTrades = state.activeTrades
          .map(trade => 
            trade.smartTradeUuid === action.payload.smartTradeUuid 
              ? action.payload 
              : trade,
          );
      })
      .addCase(editTradeUpdate.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload ?? 'Unknown error';
      })
      
      .addCase(updateTrade.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateTrade.fulfilled, (state, action) => {
        state.isLoading = false;
        state.activeTrades = state.activeTrades
          .map(trade => 
            trade.smartTradeUuid === action.payload.smartTradeUuid 
              ? action.payload 
              : trade,
          );
      })
      .addCase(updateTrade.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload ?? 'Unknown error';
      })
      
      .addCase(addNewTrade.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addNewTrade.fulfilled, (state, action) => {
        state.activeTrades.unshift(action.payload);
        state.isLoading = false;
      })
      .addCase(addNewTrade.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload ?? 'Oops error when creating trade';
      })
    
      .addCase(initializeTradingData.pending, (state) => {
        state.isLoading = true;
        state.error = '';
      })
      //@ts-ignore
      .addCase(initializeTradingData.fulfilled, (state, action) => {
        return {
          ...state,
          ...action.payload,
          isLoading: false,
          error: '',
        };
      })
      .addCase(initializeTradingData.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload as string;
      })
    
      .addCase(updateExchangeData.pending, (state) => {
        state.isLoading = true;
        state.error = '';
      })
      .addCase(updateExchangeData.fulfilled, (state, action) => {
        return {
          ...state,
          ...action.payload,
          isLoading: false,
          error: '',
        };
      })
      .addCase(updateExchangeData.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload as string;
      });
  },
});

export const {
  actions: newTerminalActions,
  reducer: newTerminalReducer,
} = newTerminalSlice;

