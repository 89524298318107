import { SxProps, Theme } from '@mui/material';

export const INNER: SxProps<Theme> = {
  gap: '40px',
  maxWidth: 187,
  textAlign: 'center',
  '&>span': {
    textAlign: 'center',
    fontSize: 12,
    fontWeight: 400,
    lineHeight: 'normal',
  },
};
