import { Typography } from 'antd';
import { IProps } from '../interfaces';

export const Text = (props: IProps) => {
  const {
    styles,
    children,
    onClick,
    type,
    className,
  } = props;

  return (
    <Typography.Text
      style={styles}
      onClick={onClick}
      type={type}
      className={className}
    >
      {children}
    </Typography.Text>
  );
};
