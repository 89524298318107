import { CloseOutlined } from '@ant-design/icons';
import { Box, Hidden } from '@mui/material';
import { Popup } from 'antd-mobile';
import { menuItems } from 'widgets/header/consts';
import { IPopup } from 'widgets/header/interfaces';
import { Logo } from 'shared/icons';
import { renderMenu } from '../helpers';
import { closeIcon, logo, popup } from '../styles';

export const MobilePopup = (props: IPopup) => {
  const {
    isOpened,
    openHandler,
  } = props;

  const closePopupHandler = () => {
    openHandler(false);
  };

  return (
    <Hidden smUp>
      <Popup
        visible={isOpened}
        position='top'
        bodyStyle={popup}
        onMaskClick={closePopupHandler}
        onClose={closePopupHandler}
      >
        <Box
          display='flex'
          flexDirection='column'
          justifyContent='space-between'
          flex='1 1 auto'
          gap={8}
        >
          <Box
            display='flex'
            flexDirection='column'
            gap={7}
          >
            <Box
              display='flex'
              alignItems='center'
              justifyContent='space-between'
              gap={9}
            >
              <CloseOutlined
                style={closeIcon}
                onClick={closePopupHandler}
              />

              <div style={logo}>
                {Logo}
              </div>

              <div />
            </Box>

            <Box
              display='flex'
              flexDirection='column'
              gap={0.5}
            >
              {renderMenu({
                menuItems,
                closePopupHandler,
              })}
            </Box>
          </Box>
        </Box>
      </Popup>
    </Hidden>
  );
};
