import { Box, Stack } from '@mui/material';
import { Divider } from 'antd';
import dayjs from 'dayjs';
import { useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { Copy } from 'widgets';
import { socials } from 'widgets/header/consts';
import { ENV } from 'shared/config/environment';
import { exportToCsv } from 'shared/helpers';
import { ExportGreen } from 'shared/icons';
import { LabelInput, Loader, MainButton, RangePicker, Table, Text, Title, WithDrawRequest } from 'shared/ui';
import { createShareCode, getReferalMetrics, getWithdrawals } from '../api';
import { Card, Income, Material } from '../components';
import { paymentColumns, telegramLink, withdrawalsColumns, xcomLink } from '../consts';
import {
  ACTIONS,
  BLOCK,
  BLOCK_ACTIONS,
  BLOCK_TITLE,
  BLOCK_WRAPPER,
  CARD_LIST,
  CREATE_LINK,
  EXPORT_BUTTON,
  LABEL_INPUT,
  MATERIALS,
  SHARE_LINK,
  SOCIAL_LINK,
  STATISTIC_BLOCK,
  TITLE_WRAPPER,
  WRAPPER,
} from '../styles';

export const InviteFriends = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [paymentDates, setPaymentDates] = useState<[dayjs.Dayjs | null, dayjs.Dayjs | null] | null>([null, null]);
  const [withdrawalsDates, setWithdrawalsDates] = useState<[dayjs.Dayjs | null, dayjs.Dayjs | null] | null>([null, null]);
  const [withdrawals, setWithdrawals] = useState<any[]>([]);
  const [metrics, setMetrics] = useState<any>(null);
  const [referalCode, setReferalCode] = useState<string>('');
  const [isModalOpened, setIsModalOpened] = useState<boolean>(false);

  const handleOpenModal = () => {
    setIsModalOpened((prev) => !prev);
  };

  const changeInputHandler = (value: string) => {
    setReferalCode(value);
  };

  const createShareCodeHandler = async () => {
    if (!referalCode) {
      toast.error('Code is required');
      return;
    }

    const data = await createShareCode(referalCode);
    if (!data?.user) {
      toast.error(data.message);
      return;
    }

    setMetrics(data.user);
  };

  const getReferalMetricsHandler = async () => {
    const metrics = await getReferalMetrics(paymentDates);
    setMetrics(metrics);

    setIsLoading(false);
  };

  useEffect(() => {
    getReferalMetricsHandler()  ;
  }, [paymentDates]);

  const getWithDrawalsHandler = async () => {
    const data = await getWithdrawals(withdrawalsDates);
    if (!data) {
      return;
    }

    setWithdrawals(data);
  };
  useEffect(() => {
    getWithDrawalsHandler();
  }, [withdrawalsDates]);

  const openSocialLink = (social: string) => {
    return () => {
      window.open(social, '_blank', 'width=600,height=400');
    };
  };

  const exportCSV = (items: any[], name: string) => {
    return () => exportToCsv(items, name);
  };

  const exportPaymentsCSV = (items: any[]) => {
    return () => {
      const data = {
        'Share Level 1': metrics.referralCommission,
        'Share Level 2': metrics.subReferralCommission,
        'Cliks': metrics.referralClicks,
        'Registrations Level 1': metrics.registrations,
        'Registrations Level 2': metrics.subRegistrations,
        'Payments': metrics.payments,
      };

      exportToCsv([data, ...items], 'paments');
    };
  };

  const getShareLinkContent = () => {
    if (!metrics?.referralCode) {
      return (
        <Stack sx={CREATE_LINK}>
          <LabelInput
            label={(
              <Text styles={LABEL_INPUT}>
                Create your referral code
              </Text>
            )}
            value={referalCode}
            placeholder='Enter referral code you wish to display in your link'
            onChange={changeInputHandler}
          />

          <MainButton
            type='primary'
            styles={{
              width: '100%',
              maxWidth: 220,
            }}
            onClick={createShareCodeHandler}
          >
            Create
          </MainButton>
        </Stack>
      );
    }

    return (
      <Stack sx={SHARE_LINK}>
        <LabelInput
          label={(
            <Text styles={LABEL_INPUT}>
              Share your link:
            </Text>
          )}
          value={shareLink}
          onChange={() => {}}
          suffix={(
            <Stack sx={ACTIONS}>
              <Copy
                value={shareLink}
                onlyCopy={true}
              />
            
              <Text
                styles={SOCIAL_LINK}
                onClick={openSocialLink(xcomLink(shareLink))}
              >
                {socials[2].icon}
              </Text>

              <Text
                styles={SOCIAL_LINK}
                onClick={openSocialLink(telegramLink(shareLink))}
              >
                {socials[3].icon}
              </Text>
            </Stack>
          )}
          disabled={true}
        />
      </Stack>
    );
  };

  const shareLink = useMemo(() => {
    return `${ENV.APP_CLIENT_URL}/register?ref=${metrics?.referralCode || 'code'}`;
  }, [metrics]);

  return (
    <Stack sx={WRAPPER}>
      {isLoading && (
        <Loader isContentOverflow={true} />
      )}

      {getShareLinkContent()}

      <Stack sx={BLOCK_WRAPPER}>
        <Stack sx={TITLE_WRAPPER}>
          <Title styles={BLOCK_TITLE}>
            Metrics
          </Title>

          <Stack sx={BLOCK_ACTIONS}>
            <RangePicker
              value={paymentDates!}
              handleSetNewDate={(dates) => setPaymentDates(dates)}
              maxWidth={240}
            />

            <MainButton
              type='primary'
              ghost={true}
              icon={ExportGreen}
              iconPosition='start'
              styles={EXPORT_BUTTON}
              onClick={exportPaymentsCSV(metrics?.table)}
              size='middle'
            >
              Export
            </MainButton>
          </Stack>
        </Stack>

        <Stack sx={STATISTIC_BLOCK}>
          <Stack sx={CARD_LIST}>
            <Card
              title='Share'
              value={[
                `${metrics?.referralCommission || 0}%`,
                `${metrics?.subReferralCommission || 0}%`,
              ]}
            />
            
            <Card
              title='Clicks'
              value={[metrics?.referralClicks || 0]}
            />

            <Divider
              type='vertical'
              style={{
                margin: 0,
                height: 162,
              }}
            />

            <Card
              title='Registrations'
              value={[
                metrics?.registrations || 0,
                metrics?.subRegistrations || 0,
              ]}
            />

            <Card
              title='Payments'
              value={[metrics?.payments || 0]}
            />
          </Stack>
          
          <Income
            total={metrics?.totalRevenue || 0}
            withDrawn={metrics?.withdrawn || 0}
            available={metrics?.availableForWithdrawal || 0}
            openModal={handleOpenModal}
          />
        </Stack>
      </Stack>

      <Stack sx={BLOCK_WRAPPER}>
        <Title styles={BLOCK_TITLE}>
          Payments
        </Title>

        <Table
          columns={paymentColumns}
          items={metrics?.table || []}
        />
      </Stack>

      <Stack sx={BLOCK_WRAPPER}>
        <Stack sx={TITLE_WRAPPER}>
          <Title styles={BLOCK_TITLE}>
            Withdrawals
          </Title>

          <Stack sx={BLOCK_ACTIONS}>
            <RangePicker
              value={withdrawalsDates!}
              handleSetNewDate={(dates) => setWithdrawalsDates(dates)}
              maxWidth={240}
            />

            <MainButton
              type='primary'
              ghost={true}
              icon={ExportGreen}
              iconPosition='start'
              styles={EXPORT_BUTTON}
              onClick={exportCSV(withdrawals, 'withdrawals')}
              size='middle'
            >
              Export
            </MainButton>
          </Stack>
        </Stack>

        <Table
          columns={withdrawalsColumns}
          items={withdrawals}
        />
      </Stack>

      <Stack sx={BLOCK_WRAPPER}>
        <Title styles={BLOCK_TITLE}>
          Branding materials
        </Title>

        <Stack sx={BLOCK}>
          <Text styles={BLOCK_TITLE}>
            Skyrexio logos 
          </Text>

          <Box sx={MATERIALS}>
            <Material
              source='/images/invite-friends/1.svg'
              title='Download logo'
              type='logo'
            />
            
            <Material
              source='/images/invite-friends/2.svg'
              title='Download logo'
              type='logo'
            />

            <Material
              source='/images/invite-friends/3.svg'
              title='Download logo'
              type='logo'
            />
          </Box>
        </Stack>
      </Stack>

      <Stack sx={BLOCK}>
        <Text styles={BLOCK_TITLE}>
          Skyrexio banners
        </Text>

        <Stack sx={MATERIALS}>
          <Material
            source='/images/invite-friends/1.png'
            title='Download banner'
            type='banner'
          />
            
          <Material
            source='/images/invite-friends/2.png'
            title='Download banner'
            type='banner'
          />

          <Material
            source='/images/invite-friends/3.png'
            title='Download banner'
            type='banner'
          />
        </Stack>
      </Stack>

      <WithDrawRequest
        isOpen={isModalOpened}
        handleClose={handleOpenModal}
        available={metrics?.availableForWithdrawal || 0}
      />
    </Stack>
  );
};

