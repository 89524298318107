import { SxProps, Theme } from '@mui/material';

export const TITLE_WRAPPER: SxProps<Theme> = {
  flexDirection: {
    xs: 'column',
    md: 'row',
  },
  gap: '12px',
  alignItems: {
    xs: 'initial',
    sm: 'center',
  },
  justifyContent: 'space-between',
};
