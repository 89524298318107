import { Stack } from '@mui/material';
import { Divider } from 'antd';
import { Copy } from 'widgets';
import { Text, Title } from 'shared/ui';
import { IBlockProps, IRowContent, ITableContent } from '../interfaces';
import { ROW_COTENT, ROW_WRAPPER, TABLE_CONTENT, TABLE_HEADER, TABLE_ROW, TABLE_WRAPPER, TITLE, WRAPPER } from '../styles';

export const Block = (props: IBlockProps) => {
  const getContent = () => {
    switch (props.layout) {
    case 'row':
      const rowContent = props.content as IRowContent[];

      return rowContent.map((item) => {
        if (!item) {
          return (
            <Divider
              style={{
                margin: 0,
              }}
            />
          );
        }

        return (
          <Stack sx={ROW_WRAPPER}>
            <Stack
              key={item.title}
              sx={ROW_COTENT}
            >
              <Title>
                {item.title}
              </Title>
  
              <Text>
                {item.value}
              </Text>
            </Stack>

            {props.error && (
              <Text type='danger'>
                {props.error}
              </Text>
            )}
          </Stack>
        );
      });
    case 'table':
      const tableContent = props.content as ITableContent;

      return (
        <Stack sx={TABLE_WRAPPER}>
          {props.error && (
            <Text type='danger'>
              {props.error}
            </Text>
          )}

          {tableContent.prefixTitle && (
            <Text>
              {tableContent.prefixTitle}
            </Text>
          )}
          
          <Stack sx={TABLE_HEADER}>
            {tableContent.headers.map((header) => (
              <Title key={header.key}>
                {header.label}
              </Title>
            ))}
          </Stack>

          <Stack sx={TABLE_CONTENT}>
            {tableContent.items.map((item) => (
              <Stack
                key={item[0].value}
                sx={TABLE_ROW}
              >
                {item.map((value) => (
                  <Text>
                    {value.value}
                      
                    {value.copy && (
                      <Copy
                        onlyCopy={true}
                        value={value.copy}
                      />
                    )}
                  </Text>
                ))}
              </Stack>
            ))}
          </Stack>
        </Stack>
      );
    default:
      return null;
    }
  };

  return (
    <Stack sx={WRAPPER}>
      {props.title && (
        <Title styles={TITLE}>
          {props.title}
        </Title>
      )}

      {getContent()}
    </Stack>
  );
};
