import { Stack } from '@mui/material';
import { IPlayerProps } from '../interfaces';
import { WRAPPER } from '../styles';

export const Player = (props: IPlayerProps) => {
  const {
    url,
  } = props;

  return (
    <Stack sx={WRAPPER}>
      <video
        src={url}
        controls={true}
        autoPlay={true}
        muted={true}
        loop={true}
      />
    </Stack>
  );
};
