import { CSSProperties } from 'react';

export const SOCIAL_LINK: CSSProperties = {
  border: '1px solid #F2F2F2',
  borderRadius: 2,
  cursor: 'pointer',
  width: 25,
  height: 25,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};
