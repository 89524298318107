import { SxProps, Theme } from '@mui/material';

export const inner: SxProps<Theme> = {
  display: 'grid',
  gridTemplateColumns: {
    xs: '1fr',
    sm: 'repeat(auto-fit, minmax(300px, 1fr))',
  },
  gap: {
    xs: '5px',
    sm: '15px',
    md: '30px',
  },
  // backgroundColor: '#F8FBF9',
  // padding: '17px 14px',
  // borderRadius: '16px',
};
